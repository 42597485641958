import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import { Link as RouterLink, useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  formControl: {
    margin: 0,
    minWidth: 120
  }
})

const ContentSelector = ({ label, linkTemplate, actContentTag, contentTags }) => {
  const classes = useStyles()
  const contentKeys = Object.keys(contentTags)
  const history = useHistory()

  if ( contentKeys.length === 0 ) return null

  if ( contentKeys.indexOf(actContentTag) === -1 ) {
    history.replace(`${linkTemplate.replace(/{{PLACEHOLDER}}/g, contentKeys[0])}`)
    return null
  }

  return (<FormControl className={classes.formControl}>
    <InputLabel shrink htmlFor="resolutionfield">
      {label}
    </InputLabel>
    <Select
      value={actContentTag}
      onChange={(e) => {}}
      input={<Input name="contentTag" id="contentTagField"/>}
      name="contentTag"
      style={{ textAlign: 'left' }}
    >
      {contentKeys.map(tag =>
        <MenuItem key={tag} value={tag} dense>
          <Link component={RouterLink}
                color={'textPrimary'}
                to={`${linkTemplate.replace(/{{PLACEHOLDER}}/g, tag)}`}
                style={{ fontWeight: (tag === actContentTag ? 500 : 400), flexGrow: 1 }}>
            {contentTags[tag]}
          </Link>
        </MenuItem>)
      }
    </Select>
  </FormControl>)
}

ContentSelector.propTypes = {
  label: PropTypes.string,
  linkTemplate: PropTypes.string.isRequired,
  actContentTag: PropTypes.string.isRequired,
  contentTags: PropTypes.object.isRequired
}

ContentSelector.defaultProps = {
  label: 'Applikation'
}
export default React.memo(ContentSelector)
