import React from 'react'
import PropTypes from 'prop-types'

import Highcharts from 'highcharts'

import applyExporting from 'highcharts/modules/exporting'
import applyOffline from 'highcharts/modules/offline-exporting'
import applySankey from 'highcharts/modules/sankey'

import {
  Chart,
  HighchartsChart,
  HighchartsProvider,
  SankeySeries,
  Title,
  Tooltip,
  XAxis,
  YAxis
} from 'react-jsx-highcharts'

applyExporting(Highcharts)
applyOffline(Highcharts)
applySankey(Highcharts)

const SankeyChart = ({ series, seriesTitle, title, height, inverted, onClick }) => {
  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart>
        <Chart inverted={inverted} height={height}/>

        <Title>{title}</Title>

        <Tooltip/>

        <XAxis type="category"/>

        <YAxis min={0}>
          <SankeySeries
            name={seriesTitle}
            minLinkWidth={2}
            data={series}
            keys={['from', 'to', 'weight']}
            onClick={(pointerEvent) => {
              if ( onClick ) onClick(pointerEvent)
            }}/>
        </YAxis>
      </HighchartsChart>
    </HighchartsProvider>
  )
}

SankeyChart.propTypes = {
  title: PropTypes.string.isRequired,
  seriesTitle: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  inverted: PropTypes.bool.isRequired,
  onClick: PropTypes.func
}

SankeyChart.defaultProps = {
  height: 400,
  inverted: false
}

export default React.memo(SankeyChart)
