import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import getString, { target } from '../../config/strings'

import { showAlert } from 'eqmod-react-alert'
import WaitForLoad from '../library/pageComponents/WaitForLoad'
import FilterField from '../library/pageComponents/FilterField'
import TableEnhanced from '../library/tableComponents/TableEnhanced'
import DnsEntryDialog from '../dialogs/DnsEntryDialog'
import enhanceEditButton from '../library/tableComponents/EditButton'
import enhanceDeleteButton from '../library/tableComponents/DeleteButton'

import { dnsEntryService } from '../../services/dataService'
import { refreshAuth, deferredReload, addSubscription } from './helpers/pageHelper'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

const formatDate = (string) => {
  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L') : ''
}

const headCells = [
  { id: '0', disablePadding: true, canBeSorted: false, label: '\u00A0' },
  { id: 'name', canBeSorted: true, label: getString('DNS_HEADLINE_NAME') },
  { id: 'start_time', canBeSorted: true, label: getString('DNS_HEADLINE_START_TIME') },
  { id: '3', disablePadding: true, canBeSorted: false, label: '\u00A0' }
]

const bodyCells = [
  { id: '0', align: 'center', disablePadding: true, type: 'render' },
  { id: 'name', type: 'data', value: (record) => record.name.split(/\./)[0] },
  { id: 'start_time', type: 'data', value: (record) => formatDate(record.start_time) },
  { id: '3', align: 'center', disablePadding: true, type: 'render' }
]

const labelSelectors = {
  zeroFiltered: 'DNS_ZERO_FILTERED',
  zero: 'DNS_ZERO',
  deleteHeader: 'DNS_DELETE_HEADER',
  deleteText: 'DNS_DELETE_TEXT'
}

class DnsEntryPage extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      records: [],
      dialogOpen: false,
      filter: '',
      page: 0,
      rowsPerPage: 10,
      count: 0,
      isLoading: false,
      order: { column: 'name', direction: 'asc' }
    }

    this.head = React.createRef()
    this.deferredTimeout = null
    this.subscription = null
    this.isCancelled = false

    bodyCells[0].value = enhanceEditButton((index) => {this.openDialog(index)})
    bodyCells[3].value = enhanceDeleteButton((index) => {this.deleteRecord(index)}, labelSelectors)
  }

  componentDidMount () {
    const { page, rowsPerPage, order } = this.state
    this.reload(page, rowsPerPage, order.column, order.direction)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      deferredReload(this)
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async reload (page, rowsPerPage, sortColumn, orderDirection) {
    const { filter } = this.state
    this.setState({ isLoading: true })

    addSubscription(this)

    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) {
        this.setState({ loading: false })
        return
      }

      if (this.isCancelled) return

      let count = await dnsEntryService.countAll(newAuth, { filter: filter })
      if (this.isCancelled) return

      let start = page * rowsPerPage
      if (start > count) {
        start = 0
        page = 0
      }

      if (this.isCancelled) return

      let data = []
      if (count > 0) {
        data = await dnsEntryService.getAll(newAuth, {
          offset: start,
          max: rowsPerPage,
          filter: filter,
          sort: sortColumn,
          order: orderDirection
        })
        if (this.isCancelled) return
      }

      this.setState({
        records: data,
        page: page,
        rowsPerPage: rowsPerPage,
        order: { column: sortColumn, direction: orderDirection },
        count: count,
        isLoading: false
      })

    } catch (error) {
      this.subscription = null
      if (this.isCancelled) return

      this.setState({ isLoading: false })
      console.error('ERROR', 'DnsEntryPage.reload', error)
      if (!error.message.startsWith('isCancelled'))
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  openDialog (index) {
    // TODO initMethod for empty record
    const records = this.state.records

    let record = (index !== -1 && records[index])
      ? Object.assign({}, records[index])
      : { name: '', start_time: new Date() }

    this.setState({ dialogOpen: true, actRecord: record, actIndex: index })
  }

  closeDialog = () => {
    this.setState({ dialogOpen: false })
  }

  async saveDialog (record) {
    const { page, rowsPerPage, order } = this.state

    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return false

      if (this.state.actIndex === -1) {
        await dnsEntryService.save(newAuth, record)
      } else {
        await dnsEntryService.update(newAuth, record)
      }

      await this.reload(page, rowsPerPage, order.column, order.direction)
      this.setState({ dialogOpen: false })
      return true
    } catch (error) {
      console.error('ERROR', 'save dns entry', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return false
    }
  }

  async deleteRecord (index) {
    const { page, rowsPerPage, order, records } = this.state
    const record = (records[index]) ? Object.assign({}, records[index]) : null

    if (record) {
      this.setState({ loading: true })
      try {
        const newAuth = await refreshAuth(this.props)
        if (newAuth === null) {
          this.setState({ loading: false })
          return
        }

        await dnsEntryService.remove(newAuth, record.id)
        await this.reload(page, rowsPerPage, order.column, order.direction)
      } catch (error) {
        this.setState({ loading: false })
        console.error('ERROR', 'delete dns entry', error)
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      }
    }
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  renderHeader () {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={6} justifyContent='center' alignItems='center'>
          <div>
            <Button variant="contained" color="primary" onClick={() => {this.openDialog(-1)}}>
              <AddIcon/>
              {getString('DNS_ADD_BUTTON')}
            </Button>
          </div>
        </Grid>

        <Grid item container xs={12} sm={6} justifyContent='center' alignItems='center' ref={this.head}>
          <FilterField value={this.state.filter} handleFilterChange={this.handleFilterChange}/>
        </Grid>
      </React.Fragment>
    )
  }

  renderBody () {
    return (
      <Grid item xs={12}>
        <TableEnhanced
          reloadFunc={(page, rowsPerPage, sortColumn, orderDirection) => {this.reload(page, rowsPerPage, sortColumn, orderDirection)}}
          isFiltered={this.state.filter !== ''}
          records={this.state.records}
          count={this.state.count}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          sortColumn={this.state.order.column}
          orderDirection={this.state.order.direction}
          headCells={headCells}
          bodyCells={bodyCells}
          labelSelectors={labelSelectors}/>
        {this.state.dialogOpen &&
        <DnsEntryDialog title={getString(this.state.actIndex === -1 ? 'DNS_ADD_BUTTON' : 'DNS_EDIT_HEADER')}
                        action={getString(this.state.actIndex === -1 ? 'DNS_SAVE_BUTTON' : 'DNS_UPDATE_BUTTON')}
                        closeFunc={this.closeDialog}
                        saveFunc={(record) => {return this.saveDialog(record)}}
                        record={this.state.actRecord}/>}
      </Grid>
    )
  }

  render () {
    return (
      <Grid container spacing={3} style={{ width: '100%', margin: 0, marginTop: 12, paddingBottom: 16 }}>
        {this.renderHeader()}

        {this.state.isLoading &&
        <WaitForLoad top={this.head.current ? this.head.current.getBoundingClientRect().bottom : 0}/>}
        {this.renderBody()}
      </Grid>
    )
  }
}

DnsEntryPage.propTypes = {
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  tabActions: PropTypes.object
}

export default DnsEntryPage
