import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

import getString, { target } from '../../config/strings'

import { showAlert } from 'eqmod-react-alert'
import WaitForLoad from '../library/pageComponents/WaitForLoad'
import FilterField from '../library/pageComponents/FilterField'
import TableEnhanced from '../library/tableComponents/TableEnhanced'
import BookingDialog from '../dialogs/BookingDialog'
import { EditButton } from '../library/tableComponents/EditButton'
import enhanceDeleteButton from '../library/tableComponents/DeleteButton'
import { bookingService } from '../../services/dataService'
import { refreshAuth, deferredReload, addSubscription } from './helpers/pageHelper'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import SvgIcon from '@material-ui/core/SvgIcon'
import IconButton from '@material-ui/core/IconButton'
import ExcelIcon from '../icons/ExcelIcon'
import zipcelx from 'zipcelx'

const QrIcon = React.memo(props => {
  return (<SvgIcon {...props}>
    <path fill="currentColor"
          d="M3,11H5V13H3V11M11,5H13V9H11V5M9,11H13V15H11V13H9V11M15,11H17V13H19V11H21V13H19V15H21V19H19V21H17V19H13V21H11V17H15V15H17V13H15V11M19,19V15H17V19H19M15,3H21V9H15V3M17,5V7H19V5H17M3,3H9V9H3V3M5,5V7H7V5H5M3,15H9V21H3V15M5,17V19H7V17H5Z"/>
  </SvgIcon>)
})

const formatDateTime = (string) => {
  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L LT') : ''
}

const formatDate = (string) => {
  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L') : ''
}

const enhanceEditButton = (editFunc) => {
  return React.memo((props) => {
    return props.record.onetimeToken
      ? <EditButton {...Object.assign({}, props, { editFunc: editFunc, icon: QrIcon })} />
      : <EditButton {...Object.assign({}, props, { editFunc: editFunc, icon: AssignmentTurnedInIcon })} />
  })
}

const headCells = [
  { id: '0', disablePadding: true, canBeSorted: false, label: '\u00A0' },
  { id: 'user', canBeSorted: true, label: getString('BOOKING_HEADLINE_USER') },
  { id: 'examinationDate', canBeSorted: false, label: getString('BOOKING_HEADLINE_COURSE') },
  { id: 'acceptedDate', canBeSorted: true, label: getString('BOOKING_HEADLINE_ACCEPTED') },
  { id: '4', disablePadding: true, canBeSorted: false, label: '\u00A0' }
]

const bodyCells = [
  { id: '0', align: 'center', disablePadding: true, type: 'render' },
  { id: 'user', type: 'data', value: record => record._service._join(record, ['email', 'firstname', 'lastname']) },
  {
    id: 'examinationDate',
    type: 'data',
    value: record => record.courseName + ' ' + formatDate(record['examinationDate'])
  },
  {
    id: 'acceptedDate',
    type: 'data',
    value: (record) => formatDateTime(record['acceptedDate'])
  },
  { id: '4', align: 'center', disablePadding: true, type: 'render' }
]

const labelSelectors = {
  zeroFiltered: 'BOOKINGS_ZERO_FILTERED',
  zero: 'BOOKINGS_ZERO',
  deleteHeader: 'BOOKINGS_DELETE_HEADER',
  deleteText: 'BOOKINGS_DELETE_TEXT'
}

/*
  erfassen wenig/mehr

  show falls bereits accepeted -> renew Token Button
  accept wenn noch nicht accepeted, erlaube Anpassungen, save Button, accept Button , nach Antwort im show mode mit QR  und Code

  löschen falls sich jemand versehentlich oder falsch angemeldet hat
 */

class BookingsPage extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      records: [],
      actRecord: null,
      filter: '',
      page: 0,
      rowsPerPage: 10,
      count: 0,
      isLoading: false,
      order: { column: 'dateCreated', direction: 'desc' }
    }

    this.head = React.createRef()
    this.deferredTimeout = null
    this.subscription = null
    this.isCancelled = false

    bodyCells[0].value = enhanceEditButton((index) => {this.openDialog(index)})
    bodyCells[4].value = enhanceDeleteButton((index) => {this.deleteRecord(index)}, labelSelectors)
  }

  componentDidMount () {
    const { page, rowsPerPage, order } = this.state
    this.reload(page, rowsPerPage, order.column, order.direction)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      deferredReload(this)
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async reload (page, rowsPerPage, sortColumn, orderDirection) {
    const { filter } = this.state
    this.setState({ isLoading: true })

    addSubscription(this)

    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) {
        this.setState({ loading: false })
        return
      }

      if (this.isCancelled) return

      let count = await bookingService.countAll(newAuth, { filter: filter })
      if (this.isCancelled) return

      let start = page * rowsPerPage
      if (start > count) {
        start = 0
        page = 0
      }

      if (this.isCancelled) return

      let data = []
      if (count > 0) {
        data = await bookingService.getAll(newAuth, {
          offset: start,
          max: rowsPerPage,
          filter: filter,
          sort: sortColumn,
          order: orderDirection
        })
        if (this.isCancelled) return
      }

      this.setState({
        records: data,
        page: page,
        rowsPerPage: rowsPerPage,
        order: { column: sortColumn, direction: orderDirection },
        count: count,
        isLoading: false
      })

    } catch (error) {
      this.subscription = null
      if (this.isCancelled) return

      this.setState({ isLoading: false })
      console.error('ERROR', 'BookingsPage.reload', error)
      if (!error.message.startsWith('isCancelled'))
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  async openDialog (index) {
    // TODO initMethod for empty record
    const records = this.state.records

    let emptyRecord = {
      email: '',
      firstname: '',
      lastname: '',
      courseName: null,
      examinationDate: null,
      comment: '',
      company: '',
      companyAddress: '',
      reservations: '',
      contactPerson: '',
      acceptedDate: null,
      onetimeToken: null
    }

    if (index !== -1 && records[index]) {
      // copy record
      let record = Object.assign({}, records[index])
      // ensure all fields are initialized
      Object.keys(emptyRecord).forEach(fieldName => {
        if (record[fieldName] === null) record[fieldName] = emptyRecord[fieldName]
      })
      this.setState({ actRecord: record })
    } else {
      // init with empty Record
      this.setState({ actRecord: emptyRecord })
    }
  }

  closeDialog = () => {
    this.setState({ actRecord: null })
  }

  async saveDialog (record) {
    const { page, rowsPerPage, order } = this.state

    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return null

      delete record.examination
      let data = null

      if (!record.id) {
        data = await bookingService.save(newAuth, record)
      } else {
        data = await bookingService.accept(newAuth, record)
      }

      this.reload(page, rowsPerPage, order.column, order.direction)
      return data

    } catch (error) {
      console.error('ERROR', 'BookingsPage.saveDialog save booking', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return null
    }
  }

  async deleteRecord (index) {
    const { page, rowsPerPage, order, records } = this.state
    const record = (records[index]) ? Object.assign({}, records[index]) : null

    if (record) {
      this.setState({ loading: true })
      try {
        const newAuth = await refreshAuth(this.props)
        if (newAuth === null) {
          this.setState({ loading: false })
          return
        }

        await bookingService.remove(newAuth, record.id)
        await this.reload(page, rowsPerPage, order.column, order.direction)
      } catch (error) {
        this.setState({ loading: false })
        console.error('ERROR', 'BookingsPage.deleteRecord', error)
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      }
    }
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  handleDownload = async event => {
    try {
      const newAuth = await refreshAuth(this.props)

      // if auth changed, abort, because component is rendered again
      if (newAuth === null) {
        return
      }

      const { filter } = this.state
      let count = await bookingService.countAll(newAuth, { filter: filter })

      let data = []
      if (count > 0) {
        data = await bookingService.getAll(newAuth, {
          offset: 0,
          max: 100,
          filter: filter,
          sort: 'dateCreated',
          order: 'desc'
        })
      }

      let headLine = [
        'Email',
        'Vorname',
        'Nachname',
        'Kursname',
        'Prüfungsdatum',
        'Kommentar',
        'Firma',
        'Adresse',
        'Reservierung',
        'Kontakt',
        'Akzeptiert',
        'Freischaltcode'
      ]

      let config = {
        filename: 'Bookings-' + new Date().toJSON().substring(0, 10),
        sheet: {
          data: []
        }
      }

      // headline
      config.sheet.data.push(headLine.map(header => {
        return { value: header, type: 'string' }
      }))

      const translation = [
        ['email', 'string'],
        ['firstname', 'string'],
        ['lastname', 'string'],
        ['courseName', 'string'],
        ['examinationDate', 'date'],
        ['comment', 'string'],
        ['company', 'string'],
        ['companyAddress', 'string'],
        ['reservations', 'string'],
        ['contactPerson', 'string'],
        ['acceptedDate', 'dateTime'],
        ['onetimeToken', 'string'],
      ]

      // body
      data.forEach(line => {
        config.sheet.data.push(translation.map((value, index) => {
          let [fieldName, type] = value
          let cellValue = line[fieldName]
          if (type === 'date') {
            cellValue = formatDate(cellValue)
          } else if (type === 'dateTime') {
            cellValue = formatDateTime(cellValue)
          }
          return { value: cellValue, type: type }
        }))
      })

      zipcelx(config)

    } catch (error) {
      console.error('ERROR', 'BookingsPage.handleDownload', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }

  }

  renderHeader () {
    const { filter } = this.state

    return (
      <React.Fragment>
        <Grid item container xs={12} sm={6} justifyContent='center' alignItems='center'>
          <div>
            <Button variant="contained" color="primary" onClick={() => {this.openDialog(-1)}}>
              <AssignmentTurnedInIcon style={{ marginRight: '0.5em' }}/>
              {getString('BOOKING_ADD_BUTTON')}
            </Button>
          </div>
        </Grid>

        <Grid item container xs={12} sm={6} justifyContent='center' alignItems='center' ref={this.head}>
          <FilterField value={filter} handleFilterChange={this.handleFilterChange}/>
        </Grid>
      </React.Fragment>
    )
  }

  renderBody () {
    const { actRecord } = this.state
    const dialogOpen = actRecord !== null

    return (
      <React.Fragment>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <IconButton onClick={this.handleDownload}>
            <ExcelIcon style={{ color: '#1f7144' }}/>
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <TableEnhanced records={this.state.records}
                         reloadFunc={(page, rowsPerPage, sortColumn, orderDirection) => {this.reload(page, rowsPerPage, sortColumn, orderDirection)}}
                         page={this.state.page}
                         rowsPerPage={this.state.rowsPerPage}
                         sortColumn={this.state.order.column}
                         orderDirection={this.state.order.direction}
                         count={this.state.count}
                         isFiltered={this.state.filter !== ''}
                         headCells={headCells}
                         bodyCells={bodyCells}
                         labelSelectors={labelSelectors}/>
          {dialogOpen &&
          <BookingDialog
            title={getString('BOOKING_ADD_BUTTON')}
            action={getString('BOOKING_ACCEPT_BUTTON')}
            closeFunc={this.closeDialog}
            saveFunc={(record) => {return this.saveDialog(record)}}
            record={actRecord}
          />}
        </Grid>
      </React.Fragment>
    )
  }

  render () {
    const { isLoading } = this.state
    const top = this.head.current ? this.head.current.getBoundingClientRect().bottom : 0
    return (
      <Grid container spacing={3} style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        {this.renderHeader()}

        {isLoading && <WaitForLoad top={top}/>}
        {this.renderBody()}
      </Grid>
    )
  }
}

BookingsPage.propTypes = {
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  tabActions: PropTypes.object
}

export default BookingsPage
