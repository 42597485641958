import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Slider from '@material-ui/core/Slider'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Draggable from 'react-draggable'

import getString from '../../config/strings'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch'
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px',
    cursor: 'move'
  },
  footLine: {
    padding: '8px 24px',
    justifyContent: 'flex-end'
  }
}))

function PaperComponent (props) {
  return (
    <Draggable handle="#form-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const SankeyOptionsDialog = (props) => {
  const classes = useStyles()
  const { closeFunc, options, onChange, maxWeight } = props
  const [heightError, setHeightError] = useState('')
  const [height, setHeight] = useState(options.diagramHeight)

  const marks = [{ value: 0, label: '0' }, { value: maxWeight, label: maxWeight.toString() }]

  const changeSlider = (event, newValue) => {
    onChange(Object.assign({}, options, { range: newValue }))
  }

  const changeLogarithmic = (event) => {
    onChange(Object.assign({}, options, { scaleLogarithmic: event.target.checked }))
  }

  const changeVertical = (event) => {
    onChange(Object.assign({}, options, { vertical: event.target.checked }))
  }

  const changeHeight = (event) => {
    let value = event.target.value

    if (typeof value === 'undefined' || value === null || value === '') {
      setHeightError(getString('SANKEY_DIAGRAM_HEIGHT_ERROR_UNDEFINED'))
      setHeight(value)
      return
    }

    if (typeof value === 'number') {
      value = value.toString()
    }

    value = value.replace(/^\s+/, '')

    if (value.match(/^[1-9]\d*/)) {
      const intValue = Number.parseInt(value)
      if (intValue < 100 || intValue > 5000) {
        setHeightError(getString('SANKEY_DIAGRAM_HEIGHT_ERROR_INVALID'))
        setHeight(value)
      } else {
        setHeight(value)
        setHeightError('')
        onChange(Object.assign({}, options, { diagramHeight: intValue }))
      }
    } else {
      setHeightError(getString('SANKEY_DIAGRAM_HEIGHT_ERROR_INVALID'))
      setHeight(value)
    }
  }

  const abortFunc = () => {setTimeout(() => {closeFunc()}, 0)}

  return (
    <Dialog open={true}
            onClose={abortFunc}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
            PaperComponent={PaperComponent}
            disableEnforceFocus={true}>

      <form>
        <DialogTitle id="form-dialog-title" className={classes.headLine}>
          {getString('SANKEY_DIALOG_TITLE')}
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers={true}>
          <Typography id="clicks" gutterBottom>{getString('SANKEY_DIALOG_LABEL_RANGE')}</Typography>
          <Slider
            min={0}
            max={maxWeight}
            value={options.range}
            aria-labelledby="clicks"
            valueLabelDisplay="on"
            marks={marks}
            onChange={changeSlider}
            style={{ marginTop: 45 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={options.scaleLogarithmic}
                onChange={changeLogarithmic}
                name="scaleLogarithmic"
                color="primary"
              />
            }
            label={getString('SANKEY_DIALOG_LABEL_SCALE_LOGARITHMIC')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={options.vertical}
                onChange={changeVertical}
                name="vertical"
                color="primary"
              />
            }
            label={getString('SANKEY_DIALOG_LABEL_SHOW_VERTICAL')}
          />
          <TextField
            id="height"
            value={height}
            type="number"
            onChange={changeHeight}
            error={heightError !== ''}
            label={getString('SANKEY_DIALOG_LABEL_DIAGRAM_HEIGHT')}
            helperText={heightError}
            fullWidth={true}
          />
        </DialogContent>

        <DialogActions className={classes.footLine}>
          <Button onClick={abortFunc} color="secondary" variant="text" size="small" className="inputMargin">
            {getString('SANKEY_OPTIONS_CLOSE')}
          </Button>
        </DialogActions>
      </form>

    </Dialog>)
}

SankeyOptionsDialog.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  maxWeight: PropTypes.number.isRequired
}

export default memo(SankeyOptionsDialog)