import React, { useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { generateQRCodeInCanvas, QR_CONTENT_TYPES } from 'qr-code-lib'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center'
  },
  qrCodeWrapper: {},
  formControl: {
    width: '100%',
    textAlign: 'left'
  }
}))

const TYPE_DESCRIPTIONS = {}
TYPE_DESCRIPTIONS[QR_CONTENT_TYPES.SYS_MESSAGE] = 'SysMessage (verschlüsselt)'
TYPE_DESCRIPTIONS[QR_CONTENT_TYPES.SYS_MESSAGE_PLAIN] = 'SysMessage (unverschlüsselt)'
TYPE_DESCRIPTIONS[QR_CONTENT_TYPES.REFERRER] = 'Referrer ID'
TYPE_DESCRIPTIONS[QR_CONTENT_TYPES.LINK] = 'Content-Link'
TYPE_DESCRIPTIONS[QR_CONTENT_TYPES.PLAINLINK] = 'Link'

function getMissingElementErrorString (type) {
  switch (type) {
    case QR_CONTENT_TYPES.PLAINLINK:
      return 'Bitte geben Sie eine komplette Url ein, um einen QR-Code zu generieren.'

    case QR_CONTENT_TYPES.LINK:
      return 'Bitte geben Sie einen Pfad ein, um einen QR-Code zu generieren.'

    case QR_CONTENT_TYPES.REFERRER:
      return 'Bitte geben Sie einen Referrer ein, um einen QR-Code zu generieren.'

    case QR_CONTENT_TYPES.SYS_MESSAGE:
    case QR_CONTENT_TYPES.SYS_MESSAGE_PLAIN:
    default:
      return 'Bitte geben Sie eine SysMessage im JSON-Format ein, um einen QR-Code zu generieren'
  }
}

const QRCode = ({ id, content, title, type, logoConfig, filename }) => {
  const error = ''
  const loading = false
  const canvasRef = useRef(null)
  const aRef = useRef(null)
  const classes = useStyles()
  const emptyString = ''

  useEffect(() => {
    if (content.trim().length > 0) {
      const generateQRCode = () => {
        generateQRCodeInCanvas('generic', content, type, 'qrcanvas', { errorCorrectionLevel: 'H' })
          .then((code) => {
            if (logoConfig && logoConfig.url) {
              const canvas = document.getElementById('qrcanvas')
              const ctx = canvas.getContext('2d')
              const image = new Image()
              image.src = logoConfig.url
              image.onload = () => {
                const destinationWidth = canvas.width * logoConfig.widthPercent
                const offsetX = (canvas.width - destinationWidth) / 2.0
                ctx.drawImage(image, offsetX, offsetX, destinationWidth, destinationWidth)
              }
            }
            console.log(code)
          })
          .catch((e) => {
            console.error('error while generating qr code', e)
          })
          .then(() => {
          })
      }
      generateQRCode()
    } else {

      console.log(getMissingElementErrorString(type))
    }
  }, [content, type, logoConfig])

  const downloadQrCode = () => {
    if (aRef.current && canvasRef.current) aRef.current.href = canvasRef.current.toDataURL('image/png')
  }

  return (
    <div className={classes.wrapper}>
      {title && (<h1>{title} <span style={{ fontSize: '75%' }}>({id})</span></h1>)}
      {loading && (<div>Loading QR Code</div>)}
      {error && (<p>{error}</p>)}
      {content && content.trim().length && (
        <div>
          <div className={classes.qrCodeWrapper} style={error ? { visibility: 'hidden' } : {}}>
            <a
              ref={aRef}
              href={emptyString}
              download={`${filename}.png`}
              title={`${title}.png`}
              onClick={downloadQrCode}
            >
              <canvas id="qrcanvas" ref={canvasRef}/>
            </a>

          </div>
          <div>
            <IconButton color="primary" aria-label="download" onClick={() => { if (aRef.current) aRef.current.click()}}>
              <GetAppIcon/>
            </IconButton>
          </div>
        </div>
      )}

    </div>
  )
}

QRCode.propTypes = {
  content: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  logoConfig: PropTypes.object
}

export default memo(QRCode)
