import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'

import { validResolutionsForApi } from '../config/resolutions'
import getString from '../config/strings'

const useStyles = makeStyles({
    formControl: {
        margin: 0,
        minWidth: 120
    }
})

const ChartResolutionSelector = ({ onChange, actResolution }) => {
    const classes = useStyles()
    const actResolutionName = actResolution.name

    return (<FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="resolutionfield">
            {getString('LABEL_TIME_RESOLUTION')}
        </InputLabel>
        <Select
            value={actResolutionName}
            onChange={onChange}
            input={<Input name="resolution" id="resolutionfield"/>}
            name="resolution"
            style={{ textAlign: 'left' }}
        >
            {validResolutionsForApi.map(resolution =>
                <MenuItem key={resolution.name} value={resolution.name} dense
                          style={{ fontWeight: (resolution.name === actResolutionName ? 500 : 400) }}>
                    {getString(resolution.optionName)}
                </MenuItem>)
            }
        </Select>
    </FormControl>)
}

ChartResolutionSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    actResolution: PropTypes.object.isRequired
}

export default React.memo(ChartResolutionSelector)
