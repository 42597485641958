import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import ApiService from '../../services/APIService'
import DataTable from '../library/tableComponents/DataTable'
import HighChart from '../charts/HighChart'
import ResolutionSelector from '../ResolutionSelector'
import { getDefaultResolution, validateResolutionFromProps, validResolutionsForApi } from '../../config/resolutions'
import { validateSubDomainFromProps } from '../utils/helperFunctions'
import ContentSelector from '../ContentSelector'
import { refreshAuth } from './helpers/pageHelper'
import { Legend } from 'react-jsx-highcharts'

const styles = (theme) => ({
    middleBox: {
        textAlign: 'right'
    },
    '@media (min-width: 600px)': {
        middleBox: {
            textAlign: 'center'
        }
    }
})

const plotOptions = {
    column: {
        dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y}',
            y: 20
        }
    },
    series: {
        turboThreshold: 10000,
        events: {
            legendItemClick: function (e) {
                // if self is visible,
                const name = this.name
                if (this.visible) {
                    // invert visibility of others
                    this.chart.series.forEach((series) => {
                        if (series.name !== name) {
                            if (series.visible) series.hide()
                            else series.show()
                        }
                    })
                } else {
                    // show myself, hide the others
                    this.chart.series.forEach((series) => {
                        if (series.name === name) series.show()
                        else series.hide()
                    })
                }
                return false
            }
        }
    }
}

class AgenciesPage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            clientData: [],
            policyData: [],
            headLine: [],
            dataLines: [],
            fetchDate: '',
            subDomain: validateSubDomainFromProps(props),
            resolution: validateResolutionFromProps(props)
        }
    }

    componentDidMount() {
        this.updateStats()
    }

    static getDerivedStateFromProps(props, state) {
        let resolution = validateResolutionFromProps(props)
        if (resolution.name === 'yearly') resolution = getDefaultResolution()

        const subDomain = validateSubDomainFromProps(props)

        if (resolution.name !== state.resolution.name || subDomain !== state.subDomain) {
            return { resolution: resolution, subDomain: subDomain, isLoading: true }
        }

        return null
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const { resolution, subDomain } = this.state
        if (prevState.resolution.name !== resolution.name || prevState.subDomain !== subDomain) {
            this.updateStats()
        }

        const { tabActions } = this.props
        if (tabActions) {
            setTimeout(() => {
                tabActions.updateIndicator()
            }, 0)
        }
    }

    async updateStats() {
        try {
            const newAuth = await refreshAuth(this.props)
            if (newAuth === null) return

            this.setState({ isLoading: true })

            const { resolution, subDomain } = this.state
            const data = await ApiService.getAgencyStats(newAuth, resolution.apiParam, subDomain)

            let headLine = [
                getString('DATE'),
                getString('HEAD_NEW_POLICIES'),
                getString('HEAD_AVAILABLE_POLICIES'),
                getString('HEAD_NEW_CLIENTS'),
                getString('HEAD_AVAILABLE_CLIENTS')
            ]
            const series = { ac: [], nc: [], ap: [], np: [] }
            const dataLines = []
            for (const record of data.lines) {
                dataLines.push([record.ld, record.np, record.ap, record.nc, record.ac])
                series.ac.push([record.ld, record.ac])
                series.nc.push([record.ld, record.nc])
                series.ap.push([record.ld, record.ap])
                series.np.push([record.ld, record.np])
            }

            const clientData = [
                { name: getString('HEAD_AVAILABLE_CLIENTS'), key: 'ac', data: series.ac },
                { name: getString('HEAD_NEW_CLIENTS'), key: 'nc', data: series.nc }
            ]
            const policyData = [
                { name: getString('HEAD_AVAILABLE_POLICIES'), key: 'ap', data: series.ap },
                { name: getString('HEAD_NEW_POLICIES'), key: 'np', data: series.np }
            ]

            this.setState({
                all: 0,
                clientData: clientData,
                policyData: policyData,
                headLine: headLine,
                dataLines: dataLines,
                fetchDate: new Date(data.fetchDate).toLocaleString(),
                isLoading: false
            })
        } catch (error) {
            this.setState({ isLoading: false })
            showAlert(getString(error.message), getString('ERROR_HEADLINE'))
        }
    }

    renderHeader() {
        const { resolution, fetchDate, subDomain } = this.state
        const { classes, hostNames } = this.props
        const resolutions = validResolutionsForApi.filter(
            (record) => record.name !== 'yearly' && record.name !== 'quarterly'
        )

        return (
            <Grid container alignItems={'center'} style={{ padding: 24 }}>
                <Grid item sm={4} xs={6}>
                    {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
                </Grid>
                <Grid item sm={4} xs={6} className={classes.middleBox}>
                    <ContentSelector
                        linkTemplate={`/agencies/{{PLACEHOLDER}}/${resolution.name}`}
                        label={getString('SELECTOR_LABEL_AGENCY')}
                        actContentTag={subDomain}
                        contentTags={hostNames}
                    />
                </Grid>
                <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
                    <ResolutionSelector
                        linkTemplate={`/agencies/${subDomain}/{{PLACEHOLDER}}`}
                        actResolution={resolution}
                        validResolutionList={resolutions}
                    />
                </Grid>
            </Grid>
        )
    }

    render() {
        if (this.state.isLoading) {
            return <WaitForLoad />
        } else {
            const { clientData, policyData, headLine, dataLines, fetchDate } = this.state
            const hasClients = clientData.length && clientData[0].data && clientData[0].data.length
            const hasPolicies = policyData.length && policyData[0].data && policyData[0].data.length
            const filenamePrefix = 'agency_stats'

            return (
                <Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
                    {this.renderHeader()}

                    {hasPolicies && (
                        <Grid item xs={12}>
                            <h3 style={{ margin: '32px 16px 16px 16px' }}>{getString('HEAD_LINE_POLICIES')}</h3>
                            <div style={{ margin: 16 }}>
                                <HighChart
                                    title={''}
                                    xAxisTitle={null}
                                    yAxisTitle={null}
                                    series={policyData}
                                    legend={<Legend verticalAlign="top" adjustChartSize={false} maxHeight={120} />}
                                    plotOptions={plotOptions}
                                />
                            </div>
                        </Grid>
                    )}

                    {hasClients && (
                        <Grid item xs={12}>
                            <h3 style={{ margin: '32px 16px 16px 16px' }}>{getString('HEAD_LINE_CLIENTS')}</h3>
                            <div style={{ margin: 16 }}>
                                <HighChart
                                    title={''}
                                    xAxisTitle={null}
                                    yAxisTitle={null}
                                    series={clientData}
                                    legend={<Legend verticalAlign="top" adjustChartSize={false} maxHeight={120} />}
                                    plotOptions={plotOptions}
                                />
                            </div>
                        </Grid>
                    )}

                    <DataTable
                        headLine={headLine}
                        dataLines={dataLines}
                        fetchDate={fetchDate}
                        excelTitle={getString('MENU_AGENCIES')}
                        filenamePrefix={filenamePrefix}
                    />
                </Grid>
            )
        }
    }
}

AgenciesPage.propTypes = {
    classes: PropTypes.object.isRequired,
    auth: PropTypes.object,
    refreshAuth: PropTypes.func.isRequired,
    hostNames: PropTypes.object.isRequired,
    tabActions: PropTypes.object
}

export default withRouter(withStyles(styles)(AgenciesPage))
