import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

import getString, { target } from '../../config/strings'

import { showAlert } from 'eqmod-react-alert'
import WaitForLoad from '../library/pageComponents/WaitForLoad'
import FilterField from '../library/pageComponents/FilterField'
import TableEnhanced from '../library/tableComponents/TableEnhanced'
import RegistrationRequestDialog from '../dialogs/RegistrationRequestDialog'

import { EditButton } from '../library/tableComponents/EditButton'
import enhanceDeleteButton from '../library/tableComponents/DeleteButton'
import { registrationRequestService } from '../../services/dataService'
import { refreshAuth, deferredReload, addSubscription } from './helpers/pageHelper'
import { PERMISSION_FIELDS } from '../dialogs/RegistrationRequestDialog'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import SvgIcon from '@material-ui/core/SvgIcon'
import ExcelIcon from '../icons/ExcelIcon'
import zipcelx from 'zipcelx'

const QrIcon = React.memo(props => {
  return (<SvgIcon {...props}>
    <path fill="currentColor"
          d="M3,11H5V13H3V11M11,5H13V9H11V5M9,11H13V15H11V13H9V11M15,11H17V13H19V11H21V13H19V15H21V19H19V21H17V19H13V21H11V17H15V15H17V13H15V11M19,19V15H17V19H19M15,3H21V9H15V3M17,5V7H19V5H17M3,3H9V9H3V3M5,5V7H7V5H5M3,15H9V21H3V15M5,17V19H7V17H5Z" />
  </SvgIcon>)
})

const formatDateTime = (string) => {
  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L LT') : ''
}

const formatDate = (string) => {
  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L') : ''
}

const enhanceEditButton = (editFunc) => {
  return React.memo((props) => {
    return props.record.onetimeToken
      ? <EditButton {...Object.assign({}, props, { editFunc: editFunc, icon: QrIcon })} />
      : <EditButton {...Object.assign({}, props, { editFunc: editFunc, icon: AssignmentTurnedInIcon })} />
  })
}

const getState = (record) => {
  if (record.allowed) {
    return getString('REGISTRATION_REQUEST_MAIL_ALLOWED')
  }
  if (record.allowDomain) {
    return getString('REGISTRATION_REQUEST_DOMAIN_ALLOWED')
  }
  if (record.denied) {
    return getString('REGISTRATION_REQUEST_MAIL_DENIED')
  }
  if (record.denyDomain) {
    return getString('REGISTRATION_REQUEST_DOMAIN_DENIED')
  }
  return ''
}

const headCells = [
  { id: '0', disablePadding: true, canBeSorted: false, label: '\u00A0' },
  { id: 'email', canBeSorted: true, label: getString('REGISTRATION_REQUEST_LABEL_EMAIL') },
  { id: 'companyName', canBeSorted: true, label: getString('REGISTRATION_REQUEST_LABEL_COMPANY') },
  { id: 'lastname', canBeSorted: true, label: getString('REGISTRATION_REQUEST_HEADLINE_CONTACT') },
  { id: 'state', canBeSorted: false, label: getString('REGISTRATION_REQUEST_HEADLINE_STATE') },
  { id: 'dateCreated', canBeSorted: true, label: getString('REGISTRATION_REQUEST_HEADLINE_DATE_CREATED') },
  { id: '6', disablePadding: true, canBeSorted: false, label: '\u00A0' }
]

const bodyCells = [
  { id: '0', align: 'center', disablePadding: true, type: 'render' },
  { id: 'email', type: 'data', value: 'email' },
  { id: 'companyName', type: 'data', value: 'companyName' },
  { id: 'lastname', type: 'data', value: record => record._service._join(record, ['lastname', 'firstname', 'phone'], ', ') },
  { id: 'state', type: 'data', value: getState },
  { id: 'dateCreated', type: 'data', value: (record) => formatDateTime(record['dateCreated']) },
  { id: '6', align: 'center', disablePadding: true, type: 'render' }
]

const labelSelectors = {
  zeroFiltered: 'REGISTRATION_REQUEST_ZERO_FILTERED',
  zero: 'REGISTRATION_REQUEST_ZERO',
  deleteHeader: 'REGISTRATION_REQUEST_DELETE_HEADER',
  deleteText: 'REGISTRATION_REQUEST_DELETE_TEXT'
}

/*
  erfassen wenig/mehr

  show falls bereits accepeted -> renew Token Button
  accept wenn noch nicht accepeted, erlaube Anpassungen, save Button, accept Button , nach Antwort im show mode mit QR  und Code

  löschen falls sich jemand versehentlich oder falsch angemeldet hat
 */

class RegistrationRequestsPage extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      records: [],
      actRecord: null,
      filter: '',
      page: 0,
      rowsPerPage: 10,
      count: 0,
      isLoading: false,
      order: { column: 'dateCreated', direction: 'desc' }
    }

    this.head = React.createRef()
    this.deferredTimeout = null
    this.subscription = null
    this.isCancelled = false

    bodyCells[0].value = enhanceEditButton((index) => {this.openDialog(index)})
    bodyCells[6].value = enhanceDeleteButton((index) => {this.deleteRecord(index)}, labelSelectors)
  }

  async reload () {
    const { page, rowsPerPage, order } = this.state
    this.load(page, rowsPerPage, order.column, order.direction)
  }

  componentDidMount () {
    this.reload()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      deferredReload(this)
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async load (page, rowsPerPage, sortColumn, orderDirection) {
    const { filter } = this.state
    this.setState({ isLoading: true })

    addSubscription(this)

    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) {
        this.setState({ loading: false })
        return
      }

      if (this.isCancelled) return

      let count = await registrationRequestService.countAll(newAuth, { filter: filter })
      if (this.isCancelled) return

      let start = page * rowsPerPage
      if (start > count) {
        start = 0
        page = 0
      }

      if (this.isCancelled) return

      let data = []
      if (count > 0) {
        data = await registrationRequestService.getAll(newAuth, {
          offset: start,
          max: rowsPerPage,
          filter: filter,
          sort: sortColumn,
          order: orderDirection
        })
        if (this.isCancelled) return
      }

      this.setState({
        records: data,
        page: page,
        rowsPerPage: rowsPerPage,
        order: { column: sortColumn, direction: orderDirection },
        count: count,
        isLoading: false
      })

    } catch (error) {
      this.subscription = null
      if (this.isCancelled) return

      this.setState({ isLoading: false })
      console.error('ERROR', 'RegistrationRequestsPage.reload', error)
      if (!error.message.startsWith('isCancelled'))
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  addRegistrationRequest = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ actRecord: registrationRequestService.getEmptyRecordImmediately() })
  }

  async openDialog (index) {
    // TODO initMethod for empty record
    const records = this.state.records

    let emptyRecord = registrationRequestService.getEmptyRecordImmediately()

    if (index !== -1 && records[index]) {
      // copy record
      let record = Object.assign({}, records[index])
      // ensure all fields are initialized
      Object.keys(emptyRecord).forEach(fieldName => {
        if (record[fieldName] === null) record[fieldName] = emptyRecord[fieldName]
      })
      this.setState({ actRecord: record })
    } else {
      // init with empty Record
      this.setState({ actRecord: emptyRecord })
    }
  }

  closeDialog = () => {
    this.setState({ actRecord: null })
  }

  async saveDialog (record) {
    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return null

      for (const fieldName of Object.keys(PERMISSION_FIELDS)) {
        console.log(fieldName)
        if (record.permission !== fieldName) {
          console.log('to null')
          record[fieldName] = null
        } else {
          console.log('to new Date')
          if (!record[fieldName]) record[fieldName] = new Date()
          console.log(record[fieldName])
        }
      }

      let data = null

      if (!record.id) {
        data = await registrationRequestService.save(newAuth, record)
      } else {
        data = await registrationRequestService.update(newAuth, record)
      }

      this.reload()
      this.setState({ actRecord: null })
      return data

    } catch (error) {
      console.error('ERROR', 'RegistrationRequestsPage.saveDialog save permissions', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return null
    }
  }

  async deleteRecord (index) {
    const { records } = this.state
    const record = (records[index]) ? Object.assign({}, records[index]) : null

    if (record) {
      this.setState({ loading: true })
      try {
        const newAuth = await refreshAuth(this.props)
        if (newAuth === null) {
          this.setState({ loading: false })
          return
        }

        await registrationRequestService.remove(newAuth, record.id)
        await this.reload()
      } catch (error) {
        this.setState({ loading: false })
        console.error('ERROR', 'RegistrationRequestsPage.deleteRecord', error)
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      }
    }
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  handleRequestsDownload = async event => {
    try {
      const newAuth = await refreshAuth(this.props)

      // if auth changed, abort, because component is rendered again
      if (newAuth === null) {
        return
      }

      const { filter } = this.state
      let count = await registrationRequestService.countAll(newAuth, { filter: filter })

      let data = []
      if (count > 0) {
        data = await registrationRequestService.getAll(newAuth, {
          offset: 0,
          max: 100,
          filter: this.state.filter,
          sort: 'dateCreated',
          order: 'desc'
        })
      }

      let headLine = [
        'Email',
        'Vorname',
        'Nachname',
        'Unternehmen',
        'Telefon',
        'Zustand',
        'Angefragt am'
      ]

      let config = {
        filename: 'Anfragen-' + new Date().toJSON().substring(0, 10),
        sheet: {
          data: []
        }
      }

      // headline
      config.sheet.data.push(headLine.map(header => {
        return { value: header, type: 'string' }
      }))

      const translation = [
        ['email', 'string'],
        ['firstname', 'string'],
        ['lastname', 'string'],
        ['companyName', 'string'],
        ['phone', 'string'],
        ['state', 'string'],
        ['dateCreated', 'dateTime']
      ]

      // body
      data.forEach(line => {
        config.sheet.data.push(translation.map((value, index) => {
          let [fieldName, type] = value
          let cellValue = (fieldName === 'state') ? getState(line) : line[fieldName]
          if (type === 'date') {
            cellValue = formatDate(cellValue)
          } else if (type === 'dateTime') {
            cellValue = formatDateTime(cellValue)
          }
          return { value: cellValue, type: type }
        }))
      })

      zipcelx(config)

    } catch (error) {
      console.error('ERROR', 'RegistrationRequestsPage.handleRequestsDownload', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }

  }

  handleUsersDownload = async event => {
    try {
      const newAuth = await refreshAuth(this.props)

      // if auth changed, abort, because component is rendered again
      if (newAuth === null) {
        return
      }


      let data = await registrationRequestService.registrations(newAuth)

      let headLine = [
        'Email',
        'Angemeldet am'
      ]

      let config = {
        filename: 'Angemeldete-Nutzer-' + new Date().toJSON().substring(0, 10),
        sheet: {
          data: []
        }
      }

      // headline
      config.sheet.data.push(headLine.map(header => {
        return { value: header, type: 'string' }
      }))

      const translation = [
        ['email', 'string'],
        ['dateCreated', 'dateTime']
      ]

      // body
      data.forEach(line => {
        config.sheet.data.push(translation.map((value, index) => {
          let [fieldName, type] = value
          let cellValue = (fieldName === 'state') ? getState(line) : line[fieldName]
          if (type === 'date') {
            cellValue = formatDate(cellValue)
          } else if (type === 'dateTime') {
            cellValue = formatDateTime(cellValue)
          }
          return { value: cellValue, type: type }
        }))
      })

      zipcelx(config)

    } catch (error) {
      console.error('ERROR', 'RegistrationRequestsPage.handleUsersDownload', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }

  }

  renderHeader () {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={6} justifyContent='center' alignItems='center'>
          <div>
            <Button variant="contained" color="primary" onClick={this.addRegistrationRequest}
                    startIcon={<AssignmentTurnedInIcon />}>
              {getString('REGISTRATION_REQUEST_ADD_BUTTON')}
            </Button>
          </div>
        </Grid>

        <Grid item container xs={12} sm={6} justifyContent='center' alignItems='center' ref={this.head}>
          <FilterField value={this.state.filter} handleFilterChange={this.handleFilterChange} />
        </Grid>
      </React.Fragment>
    )
  }

  renderBody () {
    const { actRecord } = this.state
    const dialogOpen = actRecord !== null

    return (
      <React.Fragment>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button startIcon={<ExcelIcon style={{ color: '#1f7144' }} />} onClick={this.handleRequestsDownload} variant='contained'>
            {getString('REGISTRATION_REQUEST_DOWNLOAD_REQUESTS')}
          </Button>
          <Button startIcon={<ExcelIcon style={{ color: '#1f7144' }} />} onClick={this.handleUsersDownload} variant='contained' style={{marginLeft: 16}}>
            {getString('REGISTRATION_REQUEST_DOWNLOAD_USERS')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <TableEnhanced records={this.state.records}
                         reloadFunc={(newPage, newRowsPerPage, newSortColumn, newOrderDirection) => {this.load(newPage, newRowsPerPage, newSortColumn, newOrderDirection)}}
                         page={this.state.page}
                         rowsPerPage={this.state.rowsPerPage}
                         sortColumn={this.state.order.column}
                         orderDirection={this.state.order.direction}
                         count={this.state.count}
                         isFiltered={this.state.filter !== ''}
                         headCells={headCells}
                         bodyCells={bodyCells}
                         labelSelectors={labelSelectors} />
          {dialogOpen &&
          <RegistrationRequestDialog
            title={getString('REGISTRATION_REQUEST_ADD_BUTTON')}
            action={getString(actRecord.id ? 'REGISTRATION_REQUEST_SAVE_BUTTON' : 'REGISTRATION_REQUEST_CREATE_BUTTON')}
            closeFunc={this.closeDialog}
            saveFunc={(record) => {return this.saveDialog(record)}}
            record={actRecord}
          />}
        </Grid>
      </React.Fragment>
    )
  }

  render () {
    const top = this.head.current ? this.head.current.getBoundingClientRect().bottom : 0

    return (
      <Grid container spacing={3} style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        {this.renderHeader()}

        {this.state.isLoading && <WaitForLoad top={top} />}
        {this.renderBody()}
      </Grid>
    )
  }
}

RegistrationRequestsPage.propTypes = {
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  tabActions: PropTypes.object
}

export default RegistrationRequestsPage
