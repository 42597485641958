import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link, Route, Switch, matchPath } from 'react-router-dom'

import equal from 'fast-deep-equal'

import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import queryString from 'query-string'

import getString, { target } from './config/strings'
import { AUTH_CONFIG } from './config/config'

import Alert from 'eqmod-react-alert'
import { Login, ResetPasswordDialog, validateAuth } from 'eqmod-react-login'
import AppHeader from './components/library/AppHeader'
import EnhancedCssBaseLine from './components/library/EnhancedCssBaseLine'

import InstallationsPage from './components/pages/InstallationsPage'
import DownloadsPage from './components/pages/DownloadsPage'
import InstagramPage from './components/pages/InstagramPage'
import VisitsPage from './components/pages/VisitsPage'
import ErvVisitsPage from './components/pages/ErvVisitsPage'
import RegistrationsPage from './components/pages/RegistrationsPage'
import UsagesPage from './components/pages/UsagesPage'
import SankeyPage from './components/pages/SankeyPage'
import VotingsPage from './components/pages/VotingsPage'
import VideosPage from './components/pages/VideosPage'
import PushSendPage from './components/pages/PushSendPage'
import BookingsPage from './components/pages/BookingsPage'
import RegistrationRequestsPage from './components/pages/RegistrationRequestsPage'
import DnsEntryPage from './components/pages/DnsEntryPage'
import GwsPage from './components/pages/GwsPage'
import JobsPage from './components/pages/JobsPage'
import TemplatesPage from './components/pages/TemplatesPage'
import CampaignsPage from './components/pages/CampaignsPage'
import UserPage from './components/pages/UserPage'
import ActivationCodesPage from './components/pages/ActivationCodesPage'
import AgenciesPage from './components/pages/AgenciesPage'
import AirDoctorPage from './components/pages/AirDoctorPage'

import { AuthContext } from './components/library/pageComponents/AuthContext'

import { validResolutions } from './config/resolutions'
import {
    agenciesFromAuth,
    contentTagsFromAuth,
    noPlatformsFromAuth,
    hostNamesFromClients
} from './components/utils/helperFunctions'
import APIService from './services/APIService'

const theme = JSON.parse(process.env.REACT_APP_THEME)

const styles = (theme) => ({
    tabSelected: {
        backgroundColor: '#f5f5f5'
    }
})

const componentConfig = {
    installations: {
        nameIndex: 'MENU_STOREINSTALLATIONS',
        path: '/installations',
        component: InstallationsPage
    },
    ervvisits: {
        path: `/ervvisits/:resolution(${validResolutions.join('|')})?`,
        to: '/ervvisits/monthly',
        nameIndex: 'MENU_ERVVISITS',
        component: ErvVisitsPage
    },
    sendMail: {
        nameIndex: 'MENU_MAILINGS',
        subMenu: [
            {
                nameIndex: 'MENU_MAIL_CAMPAIGNS',
                path: '/mails/campaigns',
                component: CampaignsPage
            },
            {
                nameIndex: 'MENU_MAIL_TEMPLATES',
                path: '/mails/templates',
                component: TemplatesPage
            },
            { nameIndex: 'MENU_MAIL_JOBS', path: '/mails/jobs', component: JobsPage }
        ]
    },
    dnsentries: {
        nameIndex: 'MENU_DNSENTRIES',
        path: '/dnsentries',
        component: DnsEntryPage
    },
    activationCodes: {
        nameIndex: 'MENU_ACTIVATION_CODES',
        path: '/activationCodes',
        component: ActivationCodesPage
    },
    registrationRequests: {
        nameIndex: 'MENU_REGISTRATION_REQUESTS',
        path: '/registrationRequests',
        component: RegistrationRequestsPage
    },
    bookings: {
        nameIndex: 'MENU_BOOKINGS',
        path: '/bookings',
        component: BookingsPage
    },
    authuser: { nameIndex: 'MENU_USER', path: '/user', component: UserPage },
    sendpush: { nameIndex: 'MENU_PUSH', path: '/push', component: PushSendPage },
    instahandles: {
        nameIndex: 'MENU_INSTAGRAM_HANDLES',
        path: `/instagramHandles`,
        component: InstagramPage
    },
    downloads: {
        nameIndex: 'MENU_INSTALLATIONS',
        path: `/:resolution(${validResolutions.join('|')})?`,
        to: '/monthly',
        component: DownloadsPage
    },
    votings: {
        nameIndex: 'MENU_VOTINGS',
        path: '/votings/{CONTENTTAGPATH}',
        to: '/votings/{CONTENTTAG}',
        component: VotingsPage
    },
    gws: {
        nameIndex: 'MENU_GWSREGISTRATIONS',
        path: '/gwsRegistrations',
        component: GwsPage
    },
    register: {
        nameIndex: 'MENU_REGISTRATIONS',
        path: '/registrations/monthly',
        component: RegistrationsPage
    },
    visits: {
        nameIndex: 'MENU_VISITS',
        path: `/visits/:subDomain/:resolution(${validResolutions.join('|')})?`,
        to: '/visits/__all/monthly',
        component: VisitsPage
    },
    usages: {
        nameIndex: 'MENU_USAGES',
        to: '/usages/__all/monthly/{CONTENTTAG}',
        path: `/usages/:subDomain/:resolution(${validResolutions.join('|')})?/{CONTENTTAGPATH}`,
        component: UsagesPage
    },
    sankey: {
        nameIndex: 'MENU_SANKEY',
        to: '/sankey/__all/{CONTENTTAG}',
        path: `/sankey/:subDomain/{CONTENTTAGPATH}`,
        component: SankeyPage
    },
    agency: {
        nameIndex: 'MENU_AGENCIES',
        path: `/agencies/:subDomain/:resolution(${validResolutions.filter((res) => res !== 'yearly').join('|')})?`,
        to: '/agencies/__all/monthly',
        component: AgenciesPage
    },
    airdoctor: {
        nameIndex: 'MENU_AIR_DOCTOR',
        path: `/airDoctor/:resolution(${validResolutions.filter((res) => res !== 'yearly').join('|')})?`,
        to: '/airDoctor/monthly',
        component: AirDoctorPage
    },
    videos: {
        nameIndex: 'MENU_VIDEOS',
        to: '/videos/__all/{CONTENTTAG}',
        path: `/videos/:subDomain/{CONTENTTAGPATH}`,
        component: VideosPage
    }
}

const sleep = (ms) => {
    return new Promise(function (resolve) {
        setTimeout(resolve, ms)
    })
}

class App extends PureComponent {
    constructor(props, context) {
        super(props, context)

        this.muiTheme = createTheme(theme)

        let queryParams = queryString.parse(window.location.search)

        // set language
        let lang = queryParams.lang || App.browserLocale()

        if (lang && target.supportedLanguages.indexOf(lang) === -1 && typeof lang === 'string' && lang.length >= 2) {
            lang = lang.substring(0, 2).toLowerCase()
        }
        if (!lang || target.supportedLanguages.indexOf(lang) === -1) {
            lang = target.choosenLanguage || target.supportedLanguages[0]
        }

        this.state = {
            auth: null,
            tabActions: null,
            openMenus: {},
            tab: -1,
            lang: lang,
            hostNames: { __all: 'Alle' },
            agencies: {},
            authContextValue: { refreshAuth: this.refreshAuth, user: null }
        }

        this.applyLang(lang)
        this.menuReferences = {}
        this.refreshAuthIsRunning = false
    }

    static browserLocale() {
        let lang

        if (navigator.languages && navigator.languages.length) {
            // latest versions of Chrome and Firefox set this correctly
            lang = navigator.languages[0]
        } else if (navigator.userLanguage) {
            // IE only
            lang = navigator.userLanguage
        } else {
            // latest versions of Chrome, Firefox, and Safari set this correctly
            lang = navigator.language
        }

        return lang
    }

    applyLang(lang) {
        if (lang === target.choosenLanguage) return

        if (!lang || target.supportedLanguages.indexOf(lang) === -1) {
            lang = target.choosenLanguage || null
        }

        if (lang && target.supportedLanguages.indexOf(lang) >= 0) {
            target.choosenLanguage = lang
        }
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        const { lang, auth } = this.state
        if (lang !== prevState.lang) {
            this.applyLang(lang)
        }
        if (
            auth !== prevState.auth ||
            (auth && prevState.auth && auth.expires_at.toJSON() !== prevState.auth.expires_at.toJSON())
        ) {
            if (auth) {
                const contentTags = Object.keys(contentTagsFromAuth(auth))
                const self = this
                APIService.getClients(auth, contentTags).then(function (clients) {
                    self.setState({ hostNames: hostNamesFromClients(clients, auth) })
                })
                self.setState({ agencies: agenciesFromAuth(auth) })
            }
        }
    }

    refreshAuth = async () => {
        while (this.refreshAuthIsRunning) {
            await sleep(10)
        }

        this.refreshAuthIsRunning = true

        const { auth, authContextValue } = this.state
        let newAuth
        try {
            newAuth = await validateAuth(auth)
        } catch (error) {
            console.error('ERROR', 'App.refreshAuth', error)
            newAuth = null
        }

        if (newAuth !== null && (auth === null || newAuth['expires_at'].toJSON() !== auth['expires_at'].toJSON())) {
            this.setLogin(newAuth)
        }

        if (newAuth === null) {
            this.setLogin(null)
        }

        const newUser = newAuth && newAuth.user ? newAuth.user : null
        const oldUser = authContextValue ? authContextValue.user : null

        if (!equal(newUser, oldUser)) {
            this.setState({
                authContextValue: {
                    refreshAuth: this.refreshAuth,
                    user: newUser
                }
            })
        }

        this.refreshAuthIsRunning = false
        return newAuth
    }

    static collectSubmenu(subMenuEntries, parentId) {
        const menuLines = []

        for (let i = 0; i < subMenuEntries.length; i++) {
            const { nameIndex, path, component } = subMenuEntries[i]
            if (nameIndex && component && path) {
                menuLines.push({
                    value: i,
                    label: getString(nameIndex),
                    path: path,
                    to: path,
                    component: component,
                    parentId: parentId,
                    hidden: false
                })
            }
        }

        return menuLines
    }

    getTabConfig(auth) {
        const tabs = []
        if (auth === null) return tabs

        const contentTags = Object.keys(contentTagsFromAuth(auth))
        const contentTag = contentTags.length > 0 ? contentTags[0] : null

        const allowedTabs =
            auth.user && auth.user.additionalData && auth.user.additionalData.allowedTabs
                ? auth.user.additionalData.allowedTabs
                : ['visits', 'usages']
        const { hostNames } = this.state
        const selectors = []

        for (let i = 0; i < allowedTabs.length; i++) {
            const tabName = allowedTabs[i]
            if (componentConfig[tabName]) {
                const { nameIndex, to, path, component, subMenu } = componentConfig[tabName]
                const translatedPath =
                    tabName === 'usages' && Object.keys(hostNames).length
                        ? path.replace(/:subDomain/, `:subDomain(${Object.keys(hostNames).join('|')})`)
                        : path

                if (subMenu) {
                    let selector = nameIndex.toLowerCase().replace(/\W/g, '')
                    if (!this.menuReferences[selector]) this.menuReferences[selector] = React.createRef()
                    selectors.push(selector)
                    tabs.push({
                        value: i,
                        label: getString(nameIndex),
                        subMenu: App.collectSubmenu(subMenu, i),
                        hidden: false,
                        selector: selector
                    })
                } else {
                    let translatedTo = to || translatedPath
                    translatedTo = translatedTo.replace(/{CONTENTTAG}/g, contentTag ? contentTag : '')
                    tabs.push({
                        value: i,
                        label:
                            nameIndex === 'MENU_VISITS'
                                ? getString(
                                      contentTags.length > 1 || noPlatformsFromAuth(auth)
                                          ? 'MENU_VISITS'
                                          : 'MENU_PLATFORMS'
                                  )
                                : getString(nameIndex),
                        to: translatedTo,
                        hidden: false,
                        path: translatedPath.replace(/{CONTENTTAGPATH}/g, `:contentTag(${contentTags.join('|')})?`),
                        component: component
                    })
                }
            }
        }

        const { openMenus } = this.state
        const stateSelectors = Object.keys(openMenus)
        const missingSelectors = selectors.filter((x) => !stateSelectors.includes(x))
        if (missingSelectors.length) {
            let newOpenMenus = Object.assign({}, openMenus)
            missingSelectors.forEach((key) => {
                newOpenMenus[key] = false
            })
            this.setState({ openMenus: newOpenMenus })
        }

        return tabs
    }

    setLogin = (auth) => {
        const { lang: oldLang } = this.state

        if (auth !== null) {
            // console.log(JSON.stringify(auth))
            const tabs = this.getTabConfig(auth)
            const flatTabs = App.flatMenuEntries(tabs)
            let tab = -1
            let found = false

            for (let tabDescription of flatTabs) {
                if (tabDescription.path) {
                    const match = matchPath(this.props.location.pathname, {
                        path: tabDescription.path,
                        exact: true
                    })
                    console.log('INFO App.setLogin tabDescription ', tabDescription.path, 'match', match)
                    if (match !== null) {
                        if (window.location.hash.substring(1) !== this.props.location.pathname) {
                            window.location = '#' + this.props.location.pathname
                        }
                        found = true
                        tab = tabDescription.hasOwnProperty('parentId') ? tabDescription.parentId : tabDescription.value
                    }
                }
            }

            if (!found) {
                tab = 0
                let newPath = '/'
                if (tabs[tab].to) {
                    newPath = tabs[tab].to
                } else if (tabs[tab].subMenu) {
                    if (tabs[tab].subMenu[0].to) newPath = tabs[tab].subMenu[0].to
                }
                if (window.location.hash.substring(1) !== newPath) {
                    window.location = '#' + newPath
                }
            }

            let preferredLang =
                auth.user && auth.user.additionalData && auth.user.additionalData.lang
                    ? auth.user.additionalData.lang
                    : oldLang
            if (!preferredLang && target.supportedLanguages.indexOf(preferredLang) === -1) {
                preferredLang = oldLang
            }

            this.setState({ auth: auth, tab: tab, lang: preferredLang })
        } else {
            this.setState({ auth: auth, tab: -1, lang: oldLang })
        }
    }

    setTab = (newTab) => {
        const { auth, tab } = this.state
        const tabs = this.getTabConfig(auth)
        if (tabs.length > 0) {
            if (newTab < 0 || newTab >= tabs.length) newTab = 0
            if (tab !== newTab) {
                this.setState({ tab: newTab })
            }
        }
    }

    logoutUser = () => {
        this.setState({ auth: null, tab: -1 })
    }

    setTabDeferred(tabNumber) {
        let self = this
        window.setTimeout(function () {
            self.setState({ tab: tabNumber })
        }, 10)
    }

    setTabActions = (tabActions) => {
        this.setState({ tabActions: tabActions })
    }

    handleMenuClose(selector, event) {
        if (this.menuReferences[selector].current && this.menuReferences[selector].current.contains(event.target)) {
            return
        }

        const { openMenus } = this.state
        const newOpenMenus = Object.assign({}, openMenus)
        newOpenMenus[selector] = false
        this.setState({ openMenus: newOpenMenus })
    }

    renderTabs = () => {
        const { classes } = this.props
        const { auth, tab } = this.state
        if (auth === null) return null

        const tabs = this.getTabConfig(auth)
        const self = this

        const tabRenderer = (tabItem) => {
            if (tabItem.subMenu) {
                return (
                    <Tab
                        value={tabItem.value}
                        key={tabItem.value}
                        label={tabItem.label}
                        ref={self.menuReferences[tabItem.selector]}
                        aria-owns={self.state.openMenus[tabItem.selector] ? tabItem.selector + '-grow' : undefined}
                        aria-haspopup="true"
                        onClick={() => {
                            self.setState((state, props) => {
                                let openMenus = Object.assign({}, state.openMenus)
                                openMenus[tabItem.selector] = !openMenus[tabItem.selector]
                                Object.keys(openMenus).forEach((key) => {
                                    if (key !== tabItem.selector) openMenus[key] = false
                                })
                                return {
                                    openMenus: openMenus
                                }
                            })
                        }}
                        classes={{ selected: classes.tabSelected }}
                    />
                )
            } else {
                return (
                    <Tab
                        value={tabItem.value}
                        key={tabItem.value}
                        label={tabItem.label}
                        component={Link}
                        to={tabItem.to}
                        classes={{ selected: classes.tabSelected }}
                    />
                )
            }
        }

        let poppers = []

        tabs.filter((tab) => {
            return !tab.hidden
        }).forEach((tabItem) => {
            if (tabItem.subMenu) {
                poppers.push(
                    <Popper
                        key={tabItem.value}
                        open={self.state.openMenus[tabItem.selector]}
                        anchorEl={self.menuReferences[tabItem.selector].current}
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                                }}
                            >
                                <Paper id={tabItem.selector + '-grow'}>
                                    <ClickAwayListener
                                        onClickAway={(event) => {
                                            self.handleMenuClose(tabItem.selector, event)
                                        }}
                                    >
                                        <MenuList style={{ minWidth: 160 }}>
                                            {tabItem.subMenu
                                                .filter((tab) => {
                                                    return !tab.hidden
                                                })
                                                .map((subItem) => {
                                                    return (
                                                        <MenuItem
                                                            onClick={(event) => {
                                                                self.handleMenuClose(tabItem.selector, event)
                                                            }}
                                                            key={subItem.value}
                                                            component={Link}
                                                            to={subItem.to}
                                                        >
                                                            {subItem.label}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                )
            }
        })

        if (poppers.length) {
            return (
                <React.Fragment>
                    <Tabs
                        value={tab === -1 ? 0 : tab}
                        action={this.setTabActions}
                        onChange={(event, value) => this.setTab(value)}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="secondary"
                        textColor="secondary"
                        className="topNavigation"
                    >
                        {tabs
                            .filter((tab) => {
                                return !tab.hidden
                            })
                            .map(tabRenderer)}
                    </Tabs>
                    {poppers}
                </React.Fragment>
            )
        } else {
            return (
                <Tabs
                    value={tab === -1 ? 0 : tab}
                    action={this.setTabActions}
                    onChange={(event, value) => this.setTab(value)}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="secondary"
                    textColor="secondary"
                    className="topNavigation"
                >
                    {tabs
                        .filter((tab) => {
                            return !tab.hidden
                        })
                        .map(tabRenderer)}
                </Tabs>
            )
        }
    }

    static flatMenuEntries(tabs) {
        let result = []
        tabs.forEach((tabEntry) => {
            if (tabEntry.to && tabEntry.component) result.push(tabEntry)
            if (tabEntry.subMenu) result.push(...App.flatMenuEntries(tabEntry.subMenu))
        })
        return result
    }

    render() {
        const self = this
        const { auth, tabActions, hostNames, agencies, authContextValue } = this.state
        const { location } = this.props
        const defaultProps = {
            auth: auth,
            refreshAuth: this.refreshAuth,
            tabActions: tabActions,
            hostNames: hostNames
        }
        const isReset = location && location.pathname ? location.pathname.startsWith('/resetPassword') : false
        const tabs = this.getTabConfig(auth)
        const flatTabs = App.flatMenuEntries(tabs)

        return (
            <MuiThemeProvider theme={this.muiTheme}>
                <EnhancedCssBaseLine />

                <div className="App">
                    <AuthContext.Provider value={authContextValue}>
                        <AppHeader
                            auth={auth}
                            logout={() => {
                                this.logoutUser()
                            }}
                        />

                        {this.renderTabs()}
                        {!auth && !isReset && <Login authConfig={AUTH_CONFIG} setLogin={this.setLogin} t={getString} />}

                        {(auth || isReset) && (
                            <Switch>
                                {flatTabs.map((tabEntry) => (
                                    <Route
                                        exact
                                        key={tabEntry.value}
                                        path={tabEntry.path}
                                        render={() => {
                                            if (tabEntry.hasOwnProperty('parentId'))
                                                self.setTabDeferred(tabEntry.parentId)
                                            return tabEntry.path.indexOf('agencies') === 1
                                                ? React.createElement(
                                                      tabEntry.component,
                                                      {
                                                          ...defaultProps,
                                                          hostNames: agencies
                                                      },
                                                      null
                                                  )
                                                : React.createElement(tabEntry.component, defaultProps, null)
                                        }}
                                    />
                                ))}

                                <Route
                                    path="/resetPassword/:resetToken/:signature"
                                    render={(props) => (
                                        <ResetPasswordDialog
                                            authConfig={AUTH_CONFIG}
                                            history={props.history}
                                            resetToken={props.match.params.resetToken}
                                            signature={props.match.params.signature}
                                            setLogin={this.setLogin}
                                            t={getString}
                                        />
                                    )}
                                />
                            </Switch>
                        )}

                        <footer />
                        <Alert />
                    </AuthContext.Provider>
                </div>
            </MuiThemeProvider>
        )
    }
}

App.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(App))
