import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'

import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'

import getString from '../../config/strings'

const useStyles = makeStyles(theme => ({
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  },
  formControl: {
    margin: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    paddingBottom: 16
  }
}))

const ActivationCodeDeactivateDialog = ({ record, closeFunc, saveFunc, deleteFunc, title, action }) => {
  const classes = useStyles()

  const now = new Date()
  const invalidTypes = {
    delete: (record?.usages ?? 0) > 0,
    disableActivation: (record?.firstActivateUntil && new Date(record?.firstActivateUntil) < now),
    disableUsage: (record?.valid_until && new Date(record?.valid_until) < now)
  }
  const initialType = Object.keys(invalidTypes).find(key => !invalidTypes[key]) ?? ''

  const [isSubmitting, setSubmitting] = useState(false)
  const [type, setType] = useState(initialType)

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    setSubmitting(true)
    try {
      switch (type) {
        case 'delete':
          await deleteFunc(record)
          break

        case 'disableActivation':
          record.firstActivateUntil = new Date()
          await saveFunc(record)
          break

        case 'disableUsage':
          record.valid_until = new Date()
          record.revoked = true
          await saveFunc(record)
          break
      }
    } catch (e) {
      console.log('ERROR','ActivationCodeDeactivateDialog.handleSubmit', e)
    }

    setSubmitting(false)
    closeFunc()
  }

  const deactivateNode = <span dangerouslySetInnerHTML={{__html: getString('ACTIVATION_CODE_DEACTIVATE', [record._getString()])}} />
  const disableNode = <span dangerouslySetInnerHTML={{__html: getString('ACTIVATION_CODE_DISABLE', [record._getString()])}} />
  const deleteNode = <span dangerouslySetInnerHTML={{__html: getString('ACTIVATION_CODE_DELETE', [record._getString()])}} />

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <DialogBackDrop open={isSubmitting}/>

      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title" className={classes.headLine}>{title}</DialogTitle>
        <DialogContent className={classes.content} dividers={true}>
          <FormControl component="fieldset">
            <FormLabel style={{marginBottom: '1em'}} component="legend">{getString('DEACTIVATE_TYPE_LABEL')}</FormLabel>
            <RadioGroup aria-label="type" name="type" value={type} onChange={handleChange}>
              <FormControlLabel style={{marginBottom: '1em'}} value="disableActivation" control={<Radio color='primary' />}  label={deactivateNode} disabled={invalidTypes.disableActivation}/>
              <FormControlLabel style={{marginBottom: '1em'}} value="disableUsage" control={<Radio color='primary' />}  label={disableNode} disabled={invalidTypes.disableUsage}/>
              <FormControlLabel value="delete" control={<Radio color='primary' />}  label={deleteNode} disabled={invalidTypes.delete}/>
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions className={classes.footLine}>
          <CancelButton onClick={closeFunc}/>
          <SubmitButton title={action} disabled={isSubmitting || type === ''}/>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ActivationCodeDeactivateDialog.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  saveFunc: PropTypes.func.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired
}

export default memo(ActivationCodeDeactivateDialog)