import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

export function renderInputComponent (inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

export function renderSuggestion (suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, query } = suggestionProps
  const isHighlighted = highlightedIndex === index

  let regExp = new RegExp(query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi')
  const highlighted = suggestion._getString().replace(regExp, '<b>$&</b>')

  return (
    <MenuItem
      {...itemProps}
      key={suggestion._getString()}
      selected={isHighlighted}
      component="div"><span dangerouslySetInnerHTML={{ __html: highlighted }} /></MenuItem>
  )
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
}
