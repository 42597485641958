import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Legend } from 'react-jsx-highcharts'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import ApiService from '../../services/APIService'
import DrillDownChart from '../charts/DrillDownChart'
import DataTable from '../library/tableComponents/DataTable'
import { sortDate, contentTagsFromAuth, seriesToTable } from '../utils/helperFunctions'
import { refreshAuth } from './helpers/pageHelper'

class InstallationsPage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            seriesBase: {},
            all: 0,
            headLine: [],
            dataLines: [],
            fetchDate: '',
            resolution: 'monthly'
        }
    }

    componentDidMount() {
        this.updateStats()
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const { tabActions } = this.props
        if (tabActions) {
            setTimeout(() => {
                tabActions.updateIndicator()
            }, 0)
        }
    }

    filterCountries(sortedCountries, auth) {
        if (auth && auth.user && auth.user.additionalData && auth.user.additionalData.countries) {
            let allowedCountries = auth.user.additionalData.countries
            if (!Array.isArray(allowedCountries)) allowedCountries = [allowedCountries]
            let upperAllowedCountries = allowedCountries.map((country) => country.toString().toUpperCase())
            return sortedCountries.filter(
                (country) => upperAllowedCountries.indexOf(country.toString().toLocaleUpperCase()) >= 0
            )
        } else {
            return sortedCountries
        }
    }

    async updateStats() {
        try {
            const newAuth = await refreshAuth(this.props)
            if (newAuth === null) return

            this.setState({ isLoading: true })

            const contentTags = contentTagsFromAuth(newAuth)
            const data = await ApiService.getInstallations(newAuth, Object.keys(contentTags)[0])
            data.countries.other = data.countries.OTHER
            delete data.countries.OTHER

            // filter for countries in user
            const sortedCountries = this.filterCountries(Object.keys(data.countries).sort(), newAuth)

            const hierarchy = {}
            sortedCountries.forEach(function (country) {
                if (data.countries.hasOwnProperty(country)) {
                    let values = data.countries[country]
                    Object.keys(values)
                        .sort()
                        .forEach(function (date) {
                            if (values.hasOwnProperty(date)) {
                                const category = date.substring(0, 7)
                                if (category < '2018-06') return
                                const value = Number(values[date]) || 0
                                if (!hierarchy[category]) hierarchy[category] = {}
                                hierarchy[category][country] = value
                            }
                        })
                }
            })

            let sum = 0
            Object.keys(data.all).forEach(function (key) {
                if (data.all.hasOwnProperty(key)) sum += data.all[key]
            })

            const seriesBase = {
                data: hierarchy,
                description: {
                    0: { title: '', drillUpText: '', order: Object.keys(hierarchy).sort(sortDate), colorByPoint: true },
                    1: {
                        title: 'Period: {{PLACEHOLDER}}',
                        drillUpText: 'periods',
                        order: sortedCountries,
                        level: 'country',
                        type: 'column'
                    }
                }
            }

            let headLine = [getString('DATE'), ...sortedCountries, getString('TOTAL')]
            let dataLines = seriesToTable(seriesBase, true)
            this.setState({
                all: sum,
                seriesBase: seriesBase,
                headLine: headLine,
                dataLines: dataLines,
                fetchDate: new Date(data.timestamp).toLocaleString(),
                isLoading: false
            })
        } catch (error) {
            this.setState({ isLoading: false })
            showAlert(getString(error.message), getString('ERROR_HEADLINE'))
        }
    }

    renderChartDescription() {
        const { fetchDate, all } = this.state
        return (
            <Grid
                container
                style={{ padding: 24, rowGap: 16 }}
                justifyContent="space-between"
                alignContent="space-between"
            >
                <Grid item xs={6} style={{ textAlign: 'left', paddingRight: 8 }}>
                    {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', paddingLeft: 8 }}>
                    {getString('INSTALLATIONS_SINCE')}: <b>{all}</b>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                    Collected from the AppStore statistics for iOS and from the PlayStore statistics for Android.
                </Grid>
            </Grid>
        )
    }

    render() {
        if (this.state.isLoading) {
            return <WaitForLoad />
        } else {
            const { seriesBase, headLine, dataLines, fetchDate } = this.state
            const hasSeries = seriesBase && seriesBase.data && Object.keys(seriesBase.data).length > 0
            const filenamePrefix = 'installations'

            return (
                <Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
                    {this.renderChartDescription()}

                    {hasSeries && (
                        <Grid item xs={12}>
                            <DrillDownChart title="" seriesBase={seriesBase} legend={<Legend verticalAlign="top" />} />
                        </Grid>
                    )}

                    <DataTable
                        headLine={headLine}
                        dataLines={dataLines}
                        fetchDate={fetchDate}
                        excelTitle={getString('MENU_STOREINSTALLATIONS')}
                        filenamePrefix={filenamePrefix}
                    />
                </Grid>
            )
        }
    }
}

InstallationsPage.propTypes = {
    auth: PropTypes.object,
    refreshAuth: PropTypes.func.isRequired,
    tabActions: PropTypes.object
}

export default InstallationsPage
