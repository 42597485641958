import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Downshift from 'downshift'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { renderInputComponent, renderSuggestion } from './subComponents'

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    margin: theme.spacing(1)
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  }
})

const DEFAULT_SUGGESTIONS = 5

const itemToString = (item) => {
  return (item === null ? '' : (typeof item === 'string' ? item : item._getString()))
}

class SingleSuggest extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      inputString: itemToString(this.props.value),
      suggestions: [],
      value: this.props.value
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( this.state.value === null || prevState.value === null ) {
      this.props.onChange(this.state.value)
      return
    }
    if (this.state.value.id !== prevState.value.id) {
      this.props.onChange(this.state.value)
    }
  }

  getSuggestions (value, { showEmpty = false } = {}) {
    const inputValue = value.replace(/^\s+|\s+$/, '')
    const inputLength = inputValue.length
    let count = 0

    let regExp = new RegExp(inputValue.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i')
    let maxSuggestions = this.props.maxSuggestions || DEFAULT_SUGGESTIONS
    if (maxSuggestions <= 0) maxSuggestions = DEFAULT_SUGGESTIONS

    if (inputLength === 0 && !showEmpty) {
      return []
    } else {
      return this.props.records.filter(suggestion => {
        const keep =
          count < maxSuggestions && suggestion._getString().match(regExp)

        if (keep) {
          count += 1
        }

        return keep
      })
    }
  }

  render () {
    const self = this
    const classes = this.props.classes

    function handleChange (item) {
      self.setState({ value: item })
    }

    return (
      <Downshift
        id={this.props.id}
        onChange={handleChange}
        itemToString={itemToString}
        initialInputValue={this.state.inputString} >
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => {

          const { onBlur, onFocus, onChange, ...inputProps } = getInputProps({
            placeholder: self.props.placeholder,
            onChange: event => {
              if (event.target.value === '') {
                clearSelection()
              }
              self.setState({ inputString: event.target.value })
            },
          })

          return (
            <div className={classes.container}>
              {renderInputComponent({
                fullWidth: true,
                classes,
                error: self.props.error,
                helperText: self.props.helperText,
                label: self.props.label,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { onBlur, onFocus, onChange },
                disabled: self.props.disabled,
                inputProps,
              })}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {self.getSuggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem,
                        query: inputValue
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )
        }}
      </Downshift>)
  }
}

SingleSuggest.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  records: PropTypes.array.isRequired,
  maxSuggestions: PropTypes.number,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool
}

export default withStyles(styles)(SingleSuggest)


