import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'

const SubmitButton = ({ disabled, title }) => {
  console.log('INFO', 'SubmitButton.render')
  return (
    <Button
      type='submit'
      variant='contained'
      color='primary'
      size='small'
      disabled={disabled}
      className='inputMargin'
    >
      {title}
    </Button>
  )
}

SubmitButton.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default memo(SubmitButton)
