import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import SyncIcon from '@material-ui/icons/Sync'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  }
}))

export const SyncButton = (props) => {
  const { reloadFunc, ...restProps } = props
  const classes = useStyles()

  return (<IconButton color="secondary"
                      aria-label="edit"
                      className={classes.button}
                      size='small'
                      onClick={() => {reloadFunc()}}
                      {...restProps}>
    <SyncIcon />
  </IconButton>)
}

SyncButton.propTypes = {
  reloadFunc: PropTypes.func.isRequired,
}

const enhanceSyncButton = (reloadFunc) => {
  return React.memo((props) => {
    return <SyncButton {...Object.assign({}, props, {reloadFunc: reloadFunc})} />;
  })
}

export default enhanceSyncButton