import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'

import getString from '../../config/strings'

import 'moment/locale/de'
import 'moment/locale/en-gb'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  },
  primaryColor: {
    color: theme.palette.primary.main
  }
})

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

function timeout (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const PERMISSION_FIELDS = {
  allowed: 'REGISTRATION_REQUEST_LABEL_ALLOW',
  allowDomain: 'REGISTRATION_REQUEST_LABEL_ALLOW_DOMAIN',
  denied: 'REGISTRATION_REQUEST_LABEL_DENY',
  denyDomain: 'REGISTRATION_REQUEST_LABEL_DENY_DOMAIN'
}

function getPermissionFromDates (record) {
  record.permission = ''

  for (const fieldName of Object.keys(PERMISSION_FIELDS)) {
    if (record[fieldName]) {
      record.permission = fieldName
      break
    }
  }

  for (const fieldName of Object.keys(PERMISSION_FIELDS)) {
    if (record.permission !== fieldName) {
      record[fieldName] = null
    }
  }
}

class RegistrationRequestDialog extends PureComponent {

  constructor (props) {
    super(props)

    const record = props.record

    this.state = {
      actRecord: Object.assign({}, record),
      isSubmitting: false,
      errors: {
        email: '',
        companyName: '',
        firstname: '',
        lastname: '',
        phone: '',
        permission: '',
        additionalData: ''
      }
    }
    getPermissionFromDates(this.state.actRecord)
  }

  checkField (fieldName, value, error) {
    switch (fieldName) {
      case 'email':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_BOOKING_EMAILEMPTY')
        } else if (!value.match(/^[A-Za-zäöüßÄÖÜ0-9!#$%&'*+/=?^_`{|}~-]+(\.[A-Za-zäöüßÄÖÜ0-9!#$%&'*+/=?^_`{|}~-]+)*@([A-Za-zäöüßÄÖÜ0-9][A-Za-zäöüßÄÖÜ0-9-]*\.)+[A-Za-zäöüßÄÖÜ0-9]{2,}$/)) {
          error = getString('ERROR_BOOKING_EMAILWRONG')
        }
        break

      case 'firstname':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value !== '') {
          if (!value.match(/^[A-Za-zÄÖÜäöüß]\S+(\s[A-Za-zÄÖÜäöüß]\S+)*$/)) {
            error = getString('ERROR_BOOKING_INVALIDFIRSTNAME')
          }
        }
        break

      case 'lastname':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value !== '') {
          if (!value.match(/^[A-Za-zÄÖÜäöüß]\S+(\s[A-Za-zÄÖÜäöüß]\S+)*$/)) {
            error = getString('ERROR_BOOKING_INVALIDLASTNAME')
          }
        }
        break

      case 'companyName':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_REGISTRATION_REQUEST_COMPANY_EMPTY')
        }
        break

      case 'permission':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_REGISTRATION_REQUEST_PERMISSION_EMPTY')
        }
        break

      case 'additionalData':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value !== '') {
          try {
            JSON.parse(value)
            error = ''
          } catch (parseException) {
            error = getString('ERROR_REGISTRATION_REQUEST_INVALID_JSON')
          }
        } else {
          error = ''
        }
        break

      default:
    }

    return { sanitizedValue: value, error: error }
  }

  changeField (fieldName, value) {
    const { actRecord, errors } = this.state
    const newRecord = Object.assign({}, actRecord)
    const newErrors = Object.assign({}, errors)
    const changeSet = {}

    const checkResult = this.checkField(fieldName, value, '')
    if (newRecord[fieldName] !== checkResult.sanitizedValue) {
      newRecord[fieldName] = checkResult.sanitizedValue
      changeSet.actRecord = newRecord
    }
    if (newErrors[fieldName] !== checkResult.error) {
      newErrors[fieldName] = checkResult.error
      changeSet.errors = newErrors
    }

    if (!checkResult.error && checkResult.otherFields) {
      changeSet.actRecord = Object.assign(newRecord, checkResult.otherFields)
    }

    if (Object.keys(changeSet).length > 0) this.setState(changeSet)
  }

  handleSave = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { action } = this.props
    const shouldSend = (action !== event.nativeEvent.submitter.innerText.toUpperCase())
    const { isSubmitting } = this.state
    if (!isSubmitting) {
      this.setState({ isSubmitting: true }, () => {this.internalHandleSave(shouldSend)})
    }
  }

  checkAll () {
    const { actRecord, errors } = this.state
    const newErrors = Object.assign({}, errors)
    const newRecord = Object.assign({}, actRecord)
    let hasErrors = false
    const fieldNames = Object.keys(newErrors)
    for (const fieldName of fieldNames) {
      let fieldValue = newRecord[fieldName]
      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.replace(/(^\s+|\s+$)/g, '')
        newRecord[fieldName] = fieldValue
      }
      let checkResult = this.checkField(fieldName, newRecord[fieldName], '')
      newErrors[fieldName] = checkResult.error
      if (checkResult.error !== '') hasErrors = true
    }
    return [hasErrors, newErrors, newRecord]
  }

  async internalHandleSave (shouldSend) {
    await timeout(200)

    // check all checkable fields again
    const [hasErrors, newErrors, newRecord] = this.checkAll()

    if (!hasErrors) {
      const { saveFunc } = this.props
      let sendResult = await saveFunc(Object.assign({ shouldSend: shouldSend }, newRecord))
      if (!sendResult) {
        this.setState({ isSubmitting: false })
      } else {
        getPermissionFromDates(sendResult)
        this.setState({ actRecord: sendResult, isSubmitting: false })
      }
    } else {
      console.log('ERROR', 'RegistrationRequestDialog.internalHandleSave', newErrors)
      this.setState({ errors: newErrors, isSubmitting: false })
    }
  }

  render () {
    const { classes, action, title, closeFunc } = this.props
    const { isSubmitting, actRecord, errors } = this.state

    const emailArray = [actRecord.email]
    if (actRecord.email && actRecord.email.indexOf('@') >= 0) {
      emailArray.push(actRecord.email.split('@').pop())
    } else {
      emailArray.push('')
    }
    const disableAllows = this.checkField('email', actRecord['email'], '').error !== ''
    const [hasErrors, ,] = this.checkAll()
    const showInform = actRecord.id && !hasErrors && (actRecord.permission === 'allowed' || actRecord.permission === 'allowDomain')

    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogBackDrop open={isSubmitting}/>

        <form onSubmit={this.handleSave}>
          <DialogTitle id="form-dialog-title" className={classes.headLine}>{title}</DialogTitle>

          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignContent: 'stretch',
              paddingBottom: 16
            }}
            dividers={true}>

            <TextField
              id="email"
              label={getString('REGISTRATION_REQUEST_LABEL_EMAIL')}
              required={true}
              autoFocus={true}
              type="text"
              error={errors.email !== ''}
              helperText={errors.email}
              onChange={(event) => {this.changeField('email', event.target.value)}}
              value={actRecord.email}
              className={classes.formControl}
              style={{ flex: '1 0 45%' }}
            />

            <TextField
              id="companyName"
              label={getString('REGISTRATION_REQUEST_LABEL_COMPANY')}
              required={true}
              type="text"
              error={errors.companyName !== ''}
              helperText={errors.companyName}
              onChange={(event) => {this.changeField('companyName', event.target.value)}}
              value={actRecord.companyName}
              className={classes.formControl}
              style={{ flex: '1 0 45%' }}
            />

            <TextField
              id="firstname"
              label={getString('REGISTRATION_REQUEST_LABEL_FIRSTNAME')}
              required={false}
              type="text"
              error={errors.firstname !== ''}
              helperText={errors.firstname}
              onChange={(event) => {this.changeField('firstname', event.target.value)}}
              value={actRecord.firstname}
              className={classes.formControl}
              style={{ flex: '1 0 30%' }}
            />

            <TextField
              id="lastname"
              label={getString('REGISTRATION_REQUEST_LABEL_LASTNAME')}
              required={false}
              type="text"
              error={errors.lastname !== ''}
              helperText={errors.lastname}
              onChange={(event) => {this.changeField('lastname', event.target.value)}}
              value={actRecord.lastname}
              className={classes.formControl}
              style={{ flex: '1 0 30%' }}
            />

            <TextField
              id="phone"
              label={getString('REGISTRATION_REQUEST_LABEL_PHONE')}
              required={false}
              type="text"
              error={errors.phone !== ''}
              helperText={errors.phone}
              onChange={(event) => {this.changeField('phone', event.target.value)}}
              value={actRecord.phone}
              className={classes.formControl}
              style={{ flex: '1 0 30%' }}
            />

            <FormControl component="fieldset" style={{ margin: 8, flex: '1 0 98%' }}>
              <RadioGroup aria-label="permission" name="permission" value={actRecord.permission} row
                          onChange={(event) => {this.changeField('permission', event.target.value)}}>
                {Object.keys(PERMISSION_FIELDS).map(fieldName => <FormControlLabel key={fieldName}
                                                                                   value={fieldName}
                                                                                   disabled={disableAllows}
                                                                                   control={fieldName.startsWith('allow') ?
                                                                                     <GreenRadio/> : <Radio
                                                                                       color="primary"/>}
                                                                                   style={{ flex: '1 0 45%' }}
                                                                                   label={<span
                                                                                     className={disableAllows ? {} : classes.primaryColor}
                                                                                     style={fieldName.startsWith('allow') && !disableAllows ? { color: green[600] } : {}}
                                                                                     dangerouslySetInnerHTML={{ __html: getString(PERMISSION_FIELDS[fieldName], emailArray) }}/>}/>)}
              </RadioGroup>
            </FormControl>

            <TextField
              id="additionalData"
              label={getString('REGISTRATION_REQUEST_ADDITIONAL_DATA')}
              required={false}
              multiline={true}
              row={3}
              minRows={3}
              type="text"
              error={errors.additionalData !== ''}
              helperText={errors.additionalData}
              onChange={(event) => {this.changeField('additionalData', event.target.value)}}
              value={actRecord.additionalData}
              className={classes.formControl}
              style={{ flex: '1 0 98%' }}
            />
          </DialogContent>

          <DialogActions className={classes.footLine}>
            <CancelButton onClick={closeFunc} />
            <SubmitButton title={action} disabled={hasErrors} />
            {showInform && <SubmitButton title={'... und informiere Nutzer'} disabled={false} />}
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

RegistrationRequestDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  saveFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired,
}

export default withStyles(styles)(RegistrationRequestDialog)
