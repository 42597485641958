import React from 'react'
import PropTypes from 'prop-types'

import Highcharts from 'highcharts'

import applyExporting from 'highcharts/modules/exporting'
import applyOffline from 'highcharts/modules/offline-exporting'

import {
    Chart,
    ColumnSeries,
    HighchartsChart,
    HighchartsProvider,
    Title,
    Tooltip,
    XAxis,
    YAxis
} from 'react-jsx-highcharts'

applyExporting(Highcharts)
applyOffline(Highcharts)

const HighChart = ({ series, plotOptions, title, legend, xAxisTitle, yAxisTitle, yAxisType }) => {
    let categories = []
    const data = []

    series.forEach((column, index) => {
        if (index === 0)
            categories = column.data.map((pair) => {
                return pair[0]
            })
        data.push({
            id: column.id,
            name: column.name,
            data: column.data.map((pair) => {
                return pair[1]
            })
        })
    })

    if (!['linear', 'logarithmic'].includes(yAxisType)) yAxisType = 'linear'
    const yAxisProps = { min: yAxisType === 'linear' ? 0 : 1, type: yAxisType }

    return (
        <HighchartsProvider Highcharts={Highcharts}>
            <HighchartsChart plotOptions={plotOptions}>
                <Chart zoomType="x" />

                <Title>{title}</Title>

                {legend}

                <Tooltip />

                <XAxis categories={categories}>
                    <XAxis.Title>{xAxisTitle}</XAxis.Title>
                </XAxis>

                <YAxis {...yAxisProps}>
                    <YAxis.Title>{yAxisTitle}</YAxis.Title>
                    {data.map(function (column) {
                        return <ColumnSeries id={column.id} key={column.id} name={column.name} data={column.data} />
                    })}
                </YAxis>
            </HighchartsChart>
        </HighchartsProvider>
    )
}

HighChart.propTypes = {
    title: PropTypes.string.isRequired,
    series: PropTypes.array.isRequired,
    plotOptions: PropTypes.object.isRequired,
    legend: PropTypes.object,
    xAxisTitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    yAxisType: PropTypes.oneOf(['linear', 'logarithmic'])
}

HighChart.defaultProps = {
    yAxisType: 'linear'
}

export default React.memo(HighChart)
