import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ListAltIcon from '@material-ui/icons/ListAlt'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import zipcelx from 'zipcelx'

import getString from '../../../config/strings'

function DataTableFooter ({ footLine, alignments }) {
  if (!footLine || footLine.length === 0) return null

  return (<TableFooter>
    <TableRow key="total-row">
      {footLine.map((value, index) => (<TableCell component="th" align={alignments[index]} key={index}>
        {value}
      </TableCell>))}
    </TableRow>
  </TableFooter>)
}

class DataTable extends PureComponent {

  constructor (props) {
    super(props)

    const standardAlignments = props.headLine.slice(0)
    standardAlignments[0] = 'left'
    standardAlignments.fill('right', 1)

    this.state = {
      alignments: props.alignments ? props.alignments : standardAlignments,
      dataLines: props.dataLines.slice(0, props.chunks)
    }

    this.unmount = false
    this.timer = null
  }

  recursive = () => {
    this.timer = setTimeout(() => {
      if (this.unmount) return

      const { chunks, dataLines } = this.props
      const hasMore = this.state.dataLines.length + chunks < dataLines.length

      if (this.state.dataLines.length < dataLines.length) {
        this.setState((prev, props) => ({
          dataLines: props.dataLines.slice(0, prev.dataLines.length + chunks)
        }))
      }

      if (hasMore) {
        this.recursive()
      } else {
        this.timer = null
        console.log('INFO', 'DataTable.recursive', 'all lines rendered', dataLines.length)
      }
    }, 0)
  }

  componentWillUnmount () {
    if (this.timer !== null) {
      clearTimeout(this.timer)
      this.timer = null
    }
    this.unmount = true
  }

  componentDidMount () {
    this.recursive()
  }

  _internalHandleDownload (headLine, dataLines) {
    const { footLine, fetchDate, excelTitle, filenamePrefix } = this.props

    const config = {
      filename: filenamePrefix + '-' + new Date().toJSON().substring(0, 10),
      sheet: { data: [] }
    }

    config.sheet.data.push([{ value: excelTitle, type: 'string' }])

    config.sheet.data.push([])

    config.sheet.data.push([
      { value: getString('DATA_COLLECTION_DATE'), type: 'string' },
      { value: fetchDate, type: 'string' }
    ])

    config.sheet.data.push([])

    // headline
    config.sheet.data.push(headLine.map((header) => {
      return { value: header, type: 'string' }
    }))

    // body
    console.log('INFO', 'DataTable.handleDownload', 'start push lines', dataLines.length)
    dataLines.forEach((line) => {
      config.sheet.data.push(line.map((value) => {
        return {
          value: value, type: typeof value === 'number' ? 'number' : 'string'
        }
      }))
    })

    // footer
    if (footLine && footLine.length) {
      config.sheet.data.push(footLine.map((footer) => {
        return {
          value: footer, type: typeof footer === 'number' ? 'number' : 'string'
        }
      }))
    }

    console.log('INFO', 'DataTable.handleDownload', 'start send zip')
    zipcelx(config)
  }

  handleRawDownload = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { rawHeadLine, rawDataLines } = this.props
    this._internalHandleDownload(rawHeadLine, rawDataLines)
  }

  handleDownload = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { headLine, dataLines } = this.props
    this._internalHandleDownload(headLine, dataLines)
  }

  render () {
    const { headLine, footLine, renderTable, icon, rawDataLines } = this.props
    const { alignments, dataLines } = this.state
    const hasRawData = rawDataLines && rawDataLines.length

    return (<React.Fragment>
      <Grid item xs={12} style={{ margin: 16, textAlign: 'right' }}>
        <IconButton onClick={this.handleDownload}>{icon}</IconButton>
        {hasRawData &&
          <IconButton onClick={this.handleRawDownload}>
            <ListAltIcon style={{ color: '#666666' }} titleAccess="Raw Data Download"/>
          </IconButton>
        }
      </Grid>

      {renderTable && (<Grid item xs={12}>
        <Table cellSpacing={0} size="small">
          <TableHead>
            <TableRow>
              {headLine.map((header, index) => (
                <TableCell component="th" key={index} align={alignments[index]}>
                  {header}
                </TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLines.map((line, lineNumber) => (
              <TableRow key={lineNumber} scope="row">
                {line.map((value, columnNumber) => (
                  <TableCell
                    component={columnNumber === 0 ? 'th' : 'td'}
                    align={alignments[columnNumber]}
                    key={columnNumber}
                  >
                    {value}
                  </TableCell>))}
              </TableRow>))}
          </TableBody>
          <DataTableFooter footLine={footLine} alignments={alignments}/>
        </Table>
      </Grid>)}
    </React.Fragment>)
  }
}

DataTable.propTypes = {
  headLine: PropTypes.array.isRequired,
  dataLines: PropTypes.array.isRequired,
  rawHeadLine: PropTypes.array,
  rawDataLines: PropTypes.array,
  footLine: PropTypes.array,
  alignments: PropTypes.array,
  fetchDate: PropTypes.string.isRequired,
  excelTitle: PropTypes.string.isRequired,
  filenamePrefix: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  chunks: PropTypes.number,
  renderTable: PropTypes.bool
}

DataTable.defaultProps = {
  renderTable: true,
  icon: <DownloadIcon style={{ color: '#666666' }}/>,
  chunks: 50
}

export default DataTable
