import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

const useStyles = makeStyles(theme => ({
    firstLabel: {
        marginRight: theme.spacing(2)
    },
    radioGroup: {
        alignItems: 'center'
    }
}))

const PercentageAbsoluteSelector = ({showMode, handleModeChange}) => {
    const classes = useStyles()

    return ( <FormControl>
        <RadioGroup aria-label="anzeige" row name="showMode" value={showMode}
                    onChange={handleModeChange} className={classes.radioGroup}>
            <FormLabel className={classes.firstLabel}>Anzeige</FormLabel>
            <FormControlLabel value="absolute" control={<Radio color={'primary'}/>} label="Absolute"/>
            <FormControlLabel value="percentage" control={<Radio color={'primary'}/>} label="Prozentual"/>
        </RadioGroup>
    </FormControl>)
}

PercentageAbsoluteSelector.propTypes = {
    showMode: PropTypes.string.isRequired,
    handleModeChange: PropTypes.func.isRequired
}

export default React.memo(PercentageAbsoluteSelector)
