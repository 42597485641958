import React from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TablePagination from '@material-ui/core/TablePagination'

import TableEnhancedHead from './TableEnhancedHead'
import TableEnhancedBody from './TableEnhancedBody'
import TablePaginationActions from './TablePaginationActions'

import getString from '../../../config/strings'

function TableEnhanced (props) {
  const {
    page,
    rowsPerPage,
    count,
    sortColumn,
    orderDirection,
    reloadFunc,
    records,
    isFiltered,
    headCells,
    bodyCells,
    labelSelectors,
    selected,
    isSelected,
    handleCheckBoxClick
  } = props

  if (count === 0) {
    if (isFiltered) {
      return (<p>{getString(labelSelectors.zeroFiltered)}</p>)
    } else {
      return (<p>{getString(labelSelectors.zero)}</p>)
    }
  }

  const paginationOptions = [10, 25, 50, 100].filter(rowsPerPage => rowsPerPage < count)

  const showCheckBox = (typeof selected !== 'undefined' && selected !== null) && typeof isSelected !== 'undefined' && typeof handleCheckBoxClick !== 'undefined'

  return (
    <React.Fragment>
      <Table className="policies-table" cellSpacing="0" align="center" size='small'>

        <TableEnhancedHead headCells={headCells}
                           onRequestSort={(newColumn, newDirection) => reloadFunc(page, rowsPerPage, newColumn, newDirection)}
                           order={orderDirection}
                           orderBy={sortColumn}
                           showCheckBox={showCheckBox}/>

        <TableEnhancedBody records={records} bodyCells={bodyCells} selected={selected} isSelected={isSelected}
                           handleCheckBoxClick={handleCheckBoxClick}/>

      </Table>

      <TablePagination
        component={'div'}
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={paginationOptions}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={(event, newPage) => {reloadFunc(newPage, rowsPerPage, sortColumn, orderDirection)}}
        onRowsPerPageChange={(event) => {reloadFunc(page, Number.parseInt(event.target.value), sortColumn, orderDirection)}}
        ActionsComponent={TablePaginationActions}
      />
    </React.Fragment>
  )
}

TableEnhanced.propTypes = {
  reloadFunc: PropTypes.func.isRequired,

  isFiltered: PropTypes.bool,
  records: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,

  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortColumn: PropTypes.string,
  orderDirection: PropTypes.string,

  headCells: PropTypes.array.isRequired,
  bodyCells: PropTypes.array.isRequired,
  labelSelectors: PropTypes.object.isRequired,

  selected: PropTypes.array,
  isSelected: PropTypes.func,
  handleCheckBoxClick: PropTypes.func
}

export default React.memo(TableEnhanced)
