import axios from 'axios'
import getString from '../config/strings'
import { API_HOST, START_DATE } from '../config/config'
import { configGenerator } from 'eqmod-js-axiosservice'
import { sourceSelectorFromAuth } from '../components/utils/helperFunctions'

class APIService {
    static async _doRequest(baseurl, config) {
        return new Promise(function (resolve, reject) {
            axios
                .get(baseurl, config)
                .then(function (result) {
                    if (result.status === 200) {
                        resolve(result.data)
                    } else {
                        console.log(result)
                        reject(new Error(getString('ERROR_HTTP_COMMUNICATION')))
                    }
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    }

    static async getSankey(auth, tag) {
        const baseurl = `${API_HOST}/rest/v1/sankey`
        const config = configGenerator({ token: auth.access_token, json: true, basic: false, timeout: 15000 })
        config.params = { tag: tag }

        return await APIService._doRequest(baseurl, config)
    }

    static async getClients(auth, tags) {
        const baseurl = `${API_HOST}/rest/v1/map/clients`
        const config = configGenerator({ token: auth.access_token, json: true, basic: false, timeout: 60000 })
        config.params = { tags: JSON.stringify(tags) }

        if (!APIService.clientsCache[config.params.tags]) {
            if (!API_HOST) {
                APIService.clientsCache[config.params.tags] = {
                    clientInfo: {},
                    userInfo: {},
                    fetchDate: new Date().toJSON()
                }
            } else {
                APIService.clientsCache[config.params.tags] = await APIService._doRequest(baseurl, config)
            }
            if (sourceSelectorFromAuth(auth)) {
                Object.keys(APIService.clientsCache[config.params.tags].clientInfo).forEach((clientId) => {
                    let clientInfo = APIService.clientsCache[config.params.tags].clientInfo[clientId]
                    if (!clientInfo.hostName) clientInfo.hostName = 'unknown'
                })
            }
        }

        return APIService.clientsCache[config.params.tags]
    }

    // TODO allow renew
    static async getTiles(auth, tag, renew = false) {
        const baseurl = `${API_HOST}/rest/v1/map/tiles`
        const config = configGenerator({ token: auth.access_token, json: true, basic: false, timeout: 35000 })
        config.params = { tag: tag }

        if (!APIService.tilesCache[tag] || renew) {
            APIService.tilesCache[tag] = await APIService._doRequest(baseurl + '?refresh=' + renew, config)
        }

        return APIService.tilesCache[tag]
    }

    static async getVotings(auth, tag) {
        const baseurl = `${API_HOST}/rest/v1/votings`
        const config = configGenerator({ token: auth.access_token })
        config.params = { tag: tag }

        return APIService._doRequest(baseurl, config)
    }

    static async getVideos(auth, tag) {
        const baseurl = `${API_HOST}/rest/v1/videos`
        const config = configGenerator({ token: auth.access_token })
        config.params = { tag: tag }

        return APIService._doRequest(baseurl, config)
    }

    static async getVisits(auth, resolution = 'month', tags, startDate, endDate) {
        startDate = (startDate || START_DATE).toJSON().replace(/\.000Z$/, 'Z')
        endDate = (endDate || new Date()).toJSON().replace(/\.000Z$/, 'Z')

        const baseurl = `${API_HOST}/rest/v1/visits/${resolution}`
        const config = configGenerator({ token: auth.access_token, json: true, basic: false, timeout: 90000 })
        config.params = { startDate, endDate, tags: JSON.stringify(tags) }

        return APIService._doRequest(baseurl, config)
    }

    static async getDownloads(auth, resolution = 'month', startDate, endDate) {
        startDate = (startDate || START_DATE).toJSON().replace(/\.000Z$/, 'Z')
        endDate = (endDate || new Date()).toJSON().replace(/\.000Z$/, 'Z')

        const baseurl = `${API_HOST}/rest/v1/downloads/${resolution}?`
        const config = configGenerator({ token: auth.access_token })
        config.params = { startDate, endDate }

        return APIService._doRequest(baseurl, config)
    }

    static async getUsages(auth, resolution = 'month', tag, startDate, endDate) {
        startDate = (startDate || START_DATE).toJSON().replace(/\.000Z$/, 'Z')
        endDate = (endDate || new Date()).toJSON().replace(/\.000Z$/, 'Z')

        const baseurl = `${API_HOST}/rest/v1/usages/${resolution}`
        const config = configGenerator({ token: auth.access_token, timeout: 180000 })
        config.params = { startDate, endDate, tag }

        return APIService._doRequest(baseurl, config)
    }

    static async getJWVideoDesc(url) {
        const config = {
            headers: {
                // 'X-Requested-With': 'XMLHttpRequest',
                Accept: 'application/json'
            },
            timeout: 35000
        }
        return APIService._doRequest(url, config)
    }

    static async getInstallations(auth, tag) {
        const baseurl = `${API_HOST}/rest/v1/installations`
        const config = configGenerator({ token: auth.access_token })
        config.params = { tag: tag, resolution: 'all' }

        const result = {
            all: null,
            countries: null,
            timestamp: null
        }
        const allResult = await APIService._doRequest(baseurl, config)
        result.all = allResult.values
        result.timestamp = allResult.fetchDate

        config.params.resolution = 'country'
        const countryResult = await APIService._doRequest(baseurl, config)
        result.countries = countryResult.values
        result.timestamp = countryResult.fetchDate

        return result
    }

    static async getGwsStats(auth) {
        const baseurl = `${API_HOST}/rest/v1/gwsRegistrations`
        const config = configGenerator({ token: auth.access_token })
        console.log('getGwsStats', baseurl, JSON.stringify(config))
        return APIService._doRequest(baseurl, config)
    }

    static async getErvVisits(auth, resolution = 'month') {
        const baseurl = `${API_HOST}/rest/v1/ervVisits/${resolution}`
        const config = configGenerator({ token: auth.access_token, timeout: 60000 })
        return APIService._doRequest(baseurl, config)
    }

    static async getInstagramHandles(auth) {
        const baseurl = `${API_HOST}/rest/v1/instaHandles`
        const config = configGenerator({ token: auth.access_token })
        return APIService._doRequest(baseurl, config)
    }

    static async getAgencyStats(auth, resolution = 'month', agencyConfigName) {
        const baseurl = `${API_HOST}/rest/v1/agencyStats/${resolution}?config=${agencyConfigName}`
        const config = configGenerator({ token: auth.access_token })
        return APIService._doRequest(baseurl, config)
    }

    static async getAirDoctorStats(auth, resolution = 'month', startDate, endDate) {
        startDate = (startDate || START_DATE).toJSON().replace(/\.000Z$/, 'Z')
        endDate = (endDate || new Date()).toJSON().replace(/\.000Z$/, 'Z')

        const baseurl = `${API_HOST}/rest/v1/airDoctorStats/${resolution}`
        const config = configGenerator({ token: auth.access_token })
        config.params = { startDate, endDate }

        return APIService._doRequest(baseurl, config)
    }
}

APIService.clientsCache = {}
APIService.tilesCache = {}

export default APIService
