import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Chart, HighchartsChart, HighchartsProvider, PieSeries, YAxis } from 'react-jsx-highcharts'
import Highcharts from 'highcharts'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import getString from '../../config/strings'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch'
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px',
    justifyContent: 'flex-end'
  }
}))

const NavigationDetailsDialog = ({ closeFunc, dataLines }) => {
  const classes = useStyles()

  const pieData = dataLines.map(([, toPath, weight]) => ({ name: toPath, y: weight }))
  const fromPath = dataLines.length ? dataLines[0][0] : ''

  const abortFunc = () => {setTimeout(() => {closeFunc()}, 0)}

  console.log('INFO', 'NavigationDetailsDialog', JSON.stringify(dataLines))
  return (
    <Dialog open={true}
            onClose={abortFunc}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            disableEnforceFocus={true}>

      <DialogTitle id="form-dialog-title" className={classes.headLine}>
        {getString('NAVIGATION_DETAILS_TITLE',[fromPath])}
      </DialogTitle>

      <DialogContent className={classes.dialogContent} dividers={true}>
        <HighchartsProvider Highcharts={Highcharts}>
          <HighchartsChart>
            <Chart/>
            <YAxis>
              <PieSeries dataLabels={{ formatter: function () {
                  // display only if larger than 1
                  return this.y > 5 ? ('<b>' + this.point.name + ':</b> ' + this.y) : null;
                } }}
                         center={['50%', '50%']} innerSize="40%" colors={null} data={pieData}/>
            </YAxis>
          </HighchartsChart>
        </HighchartsProvider>
      </DialogContent>

      <DialogActions className={classes.footLine}>
        <Button onClick={abortFunc} color="secondary" variant="text" size="small" className="inputMargin">
          {getString('SANKEY_OPTIONS_CLOSE')}
        </Button>
      </DialogActions>

    </Dialog>)
}

NavigationDetailsDialog.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  dataLines: PropTypes.array.isRequired
}

export default memo(NavigationDetailsDialog)