import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { HashRouter } from 'react-router-dom'
import App from './App'
import { unregister } from './serviceWorker'

ReactDOM.render(<HashRouter><App /></HashRouter>, document.getElementById('root'))
unregister()
