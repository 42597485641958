import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import VotingView from '../VotingView'
import ApiService from '../../services/APIService'
import DataTable from '../library/tableComponents/DataTable'
import ContentSelector from '../ContentSelector'
import FilterField from '../library/pageComponents/FilterField'
import Typography from '@material-ui/core/Typography'
import { refreshAuth } from './helpers/pageHelper'
import {
  contentTagsFromAuth,
  validateContentTagFromProps,
  normalizeTitle
} from '../utils/helperFunctions'

const styles = theme => ({
  middleBox: {
    textAlign: 'right',
  },
  '@media (min-width: 600px)': {
    middleBox: {
      textAlign: 'center'
    }
  }
})

class VotingsPage extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      headLine: ['Legende', 'Question / Answers', 'Anzahl', 'Prozent'],
      alignments: ['left', 'left', 'right', 'right'],
      filter: '',
      dataLines: [],
      fetchDate: '',
      votings: [],
      contentTag: validateContentTagFromProps(props)
    }
  }

  componentDidMount () {
    this.updateStats()
  }

  static getDerivedStateFromProps (props, state) {
    const contentTag = validateContentTagFromProps(props)

    if (contentTag !== state.contentTag) {
      return { contentTag: contentTag, isLoading: true }
    }

    return null
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    if (prevState.contentTag !== this.state.contentTag) {
      this.updateStats()
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async updateStats () {
    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const contentTag = this.state.contentTag

      const votings = await ApiService.getVotings(newAuth, contentTag)

      const sortTiles = function (a, b) {
        const aQuestion = a.question || 'unknown'
        const bQuestion = b.question || 'unknown'
        return (aQuestion < bQuestion) ? -1 : ((aQuestion > bQuestion) ? 1 : 0)
      }
      const sortIds = function (a, b) {
        const aId = a.id || 0
        const bId = b.id || 0
        return (aId < bId) ? -1 : ((aId > bId) ? 1 : 0)
      }

      const votingList = []
      Object.keys(votings.votings).forEach(questionId => {

        const voting = votings.votings[questionId]

        if (voting.context && Array.isArray(voting.context) && voting.context.length > 0) {
          voting.rawContextString = voting.context.map(context => { return normalizeTitle(context.title)}).join(' ')
          voting.contextString = voting.context.map(context => {
            return context.type.substring(0, 1).toUpperCase() + context.type.substring(1).toLocaleLowerCase() + ': ' + normalizeTitle(context.title)
          }).join(' -> ')
        } else {
          voting.contextString = 'aktuell nicht im Content verlinkt'
        }

        voting.question = normalizeTitle(voting.question)
        voting.title = normalizeTitle(voting.title)
        voting.votingAnswers.forEach(answer => {
          answer.text = normalizeTitle(answer.text)
        })
        votingList.push(voting)
      })
      votingList.sort(sortTiles)

      const dataLines = []
      votingList.forEach(voting => {
        dataLines.push(['Context:', voting.contextString || null, null, null])
        dataLines.push(['Titel:', voting.title || null, null, null])
        dataLines.push(['Question:', voting.question || null, null, null])
        voting.votingAnswers.sort(sortIds).forEach(answer => {
          dataLines.push(['Answer ' + answer.id, answer.text, answer.count, answer.percent])
        })
      })

      this.setState({
        fetchDate: (new Date(votings.fetchDate)).toLocaleString(),
        votings: votingList,
        dataLines: dataLines,
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  updateFilter (value) {
    this.setState({ filter: value })
  }

  renderHeader () {
    const contentTags = contentTagsFromAuth(this.props.auth)
    const classes = this.props.classes
    const { fetchDate, contentTag, votings, filter } = this.state
    const hasData = votings && votings.length > 0

    if (Object.keys(contentTags).length <= 1) {
      return (<Grid container alignItems={'center'} style={{ padding: 24 }}>
        <Grid item xs={6}>
          {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          {hasData &&
          <FilterField value={filter} handleFilterChange={(value) => {this.updateFilter(value)}}/>}
        </Grid>
      </Grid>)
    } else {
      return (<Grid container alignItems={'center'} style={{ padding: 24 }}>
        <Grid item sm={4} xs={6}>
          {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
        </Grid>
        <Grid item sm={4} xs={6} className={classes.middleBox}>
          <ContentSelector linkTemplate={'/votings/{{PLACEHOLDER}}'}
                           actContentTag={contentTag} contentTags={contentTags}/>
        </Grid>
        {hasData && <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
          <FilterField value={filter} handleFilterChange={(value) => {this.updateFilter(value)}}/>
        </Grid>}
      </Grid>)
    }
  }

  render () {
    if (this.state.isLoading) {
      return (<WaitForLoad/>)
    } else {
      const { votings, headLine, alignments, dataLines, contentTag, fetchDate, filter } = this.state
      const hasData = votings && votings.length > 0
      const filenamePrefix = 'Votings-' + contentTag
      const filteredVotings = (filter === '') ? votings : votings.filter(voting => {
        const regexp = new RegExp(filter, 'i')
        if (voting.rawContextString && voting.rawContextString.match(regexp)) return true
        if (voting.title && voting.title.match(regexp)) return true
        if (voting.question && voting.question.match(regexp)) return true
        for (let i = 0, l = voting.votingAnswers.length; i < l; i++) {
          const answer = voting.votingAnswers[i]
          if (answer.text && answer.text.match(regexp)) return true
        }
        return false
      })

      return (<Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        {this.renderHeader()}

        {!hasData && <Grid item xs={12} style={{ padding: 16 }}>
          <Typography variant={'body1'} paragraph={true}>Es stehen gegenwärtig im Content keine Votings zur
            Verfügung.</Typography>
        </Grid>}

        {hasData && <Grid item xs={12}>
          {filteredVotings.map(voting => {return (<VotingView key={voting.id} voting={voting}/>)})}
        </Grid>}

        {hasData && <DataTable
          headLine={headLine}
          alignments={alignments}
          dataLines={dataLines}
          fetchDate={fetchDate}
          excelTitle={getString('MENU_VOTINGS')}
          filenamePrefix={filenamePrefix}
          chunks={20}/>}
      </Grid>)
    }
  }
}

VotingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  tabActions: PropTypes.object
}

export default withRouter(withStyles(styles)(VotingsPage))
