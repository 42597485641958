import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { normalizeTitle } from './utils/helperFunctions'

const useStyles = makeStyles(theme => ({
    grayText: {
        color: theme.palette.text.secondary
    }
}))

const ShowContextPath = ({ nugget }) => {
    const classes = useStyles()

    if (nugget.rawContextString) {
        return (
            <Typography variant={'body2'} paragraph={true}>
                {nugget.context.map((context, index) => {
                    return (<React.Fragment key={index}>
                                <span className={classes.grayText}>
                                    {(index > 0 ? ' ➞ ' : '') + context.type.substring(0, 1).toUpperCase() + context.type.substring(1).toLocaleLowerCase() + ': '}
                                </span>
                        {normalizeTitle(context.title)}
                    </React.Fragment>)
                })
                }</Typography>)
    } else {
        return (
            <Typography variant={'body2'} paragraph={true}>
                <span className={classes.grayText}>Context: </span>
                {nugget.contextString}
            </Typography>)
    }
}

ShowContextPath.propTypes = {
    nugget: PropTypes.object.isRequired
}

export default React.memo(ShowContextPath)
