export const AUTH_CONFIG = {
  host: process.env.REACT_APP_AUTH_HOST,
  register_client: process.env.REACT_APP_AUTH_USER,
  register_client_credentials: process.env.REACT_APP_AUTH_CREDENTIALS,
  register_client_scopes: 'authUser:read authUser:register authUser:update',

  passwordRules: {
    minSize: 8,
    specialChars: '+-.,;:_<>@#+*!§$%&/()=?"\''
  },

  user_client: process.env.REACT_APP_USER_USER,
  user_client_credentials: process.env.REACT_APP_USER_CREDENTIALS,
  user_client_scopes: process.env.REACT_APP_USER_SCOPES
}

export const API_HOST = process.env.REACT_APP_API_HOST

export const BOOKING_HOST = process.env.REACT_APP_AUTH_HOST
export const EPRUSER_HOST = process.env.REACT_APP_AUTH_HOST
export const DNS_HOST = process.env.REACT_APP_DNS_HOST
export const PUSH_HOST = process.env.REACT_APP_AUTH_HOST
export const REGISTRATION_REQUEST_HOST = process.env.REACT_APP_AUTH_HOST
export const NOTIFICATION_TEMPLATES_HOST = process.env.REACT_APP_AUTH_HOST
export const JOBS_HOST = process.env.REACT_APP_AUTH_HOST
export const CAMPAIGNS_HOST = process.env.REACT_APP_AUTH_HOST
export const ACTIVATION_CODE_HOST = process.env.REACT_APP_AUTH_HOST
export const AUTH_HOST = process.env.REACT_APP_AUTH_HOST

export const BOOKING_DATA_URL = '/rest/api/v1/booking'
export const EPRUSER_DATA_URL = '/rest/api/v1/eprUser'
export const REGISTRATION_REQUEST_DATA_URL = '/rest/api/v1/registrationRequest'
export const NOTIFICATION_TEMPLATES_DATA_URL = '/rest/api/v1/notificationTemplate'
export const DNS_DATA_URL = '/rest/v1/dnsEntry'
export const PUSH_DATA_URL = '/rest/api/v1/sendPush'
export const CHANNEL_DATA_URL = '/rest/api/v1/listPushChannel'
export const JOBS_DATA_URL = '/rest/api/v1/jobEntry'
export const CAMPAIGNS_DATA_URL = '/rest/api/v1/campaign'
export const ACTIVATION_CODE_DATA_URL = '/rest/api/v2/activationCode'

export const NOTIFICATION_TEMPLATE_CONTEXT = process.env.REACT_APP_NOTIFICATION_TEMPLATE_CONTEXT

export const START_DATE = new Date(process.env.REACT_APP_START_DATE)

export const USAGE_FILTER_USER = JSON.parse(process.env.REACT_APP_USAGE_FILTER_USER)
export const USAGE_FOR_PATH = parseInt(process.env.REACT_APP_USAGE_FOR_PATH) === 1

export const DEFAULT_TAG_LIST = []
export const DEFAULT_CLIENT_TYPE = 'web'
export const APP_SELECTIONS = JSON.parse(process.env.REACT_APP_APP_SELECTIONS || '[]')

