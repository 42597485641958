import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function TableEnhancedHead ({ order, orderBy, onRequestSort, headCells, showCheckBox }) {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow>
        {showCheckBox && (<TableCell padding={'none'}>{'\u00A0'}</TableCell>)}
        {headCells.map(headCell => {
          if (headCell.canBeSorted) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.align || 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={() => onRequestSort(headCell.id, (orderBy === headCell.id) ? (order === 'asc' ? 'desc' : 'asc') : 'asc')}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.align || 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}>
                {headCell.label}
              </TableCell>
            )
          }
        })}
      </TableRow>
    </TableHead>
  )
}

TableEnhancedHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
  showCheckBox: PropTypes.bool
}

export default React.memo(TableEnhancedHead)
