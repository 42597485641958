import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import getString from '../../config/strings'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: '2em',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  appLogo: {
    height: 48,
    float: 'left',
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 72
    }
  }
}))

const AppHeader = memo(({ auth, logout }) => {
  const classes = useStyles()
  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Link to="/"><img src={process.env.PUBLIC_URL + process.env.REACT_APP_LOGO_PATH}
                          className={classes.appLogo}
                          alt="logo"/></Link>
        <Typography variant="h6" color="inherit" className={classes.title}>
          {getString('APP_TITLE')}
        </Typography>
        {auth && <Button color="inherit" onClick={logout}>{getString('LOGOUT')}</Button>}
      </Toolbar>
    </AppBar>)
})

AppHeader.propTypes = {
  auth: PropTypes.object,
  logout: PropTypes.func.isRequired
}

export default AppHeader
