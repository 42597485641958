import { Subscription } from 'rxjs'
import { cancelSource } from 'eqmod-js-axiosservice'

export async function refreshAuth ({ refreshAuth, auth }) {
  const newAuth = await refreshAuth()
  if (newAuth === null) return null
  if (auth && auth['access_token'] !== newAuth['access_token']) return null
  return newAuth
}

export function deferredReload (pageComponent) {
  let later = function () {
    pageComponent.deferredTimeout = null
    const { page, rowsPerPage, order } = pageComponent.state
    pageComponent.reload(page, rowsPerPage, order.column, order.direction)
  }
  if (pageComponent.subscription != null) {
    pageComponent.subscription.unsubscribe()
    pageComponent.subscription = null
  }
  if ( pageComponent.deferredTimeout ) clearTimeout(pageComponent.deferredTimeout)
  pageComponent.deferredTimeout = setTimeout(later, 500)
}

export function addSubscription (pageComponent) {
  pageComponent.subscription = new Subscription()
  pageComponent.isCancelled = false
  pageComponent.subscription.add(() => {
    pageComponent.subscription = null
    pageComponent.isCancelled = true
    pageComponent.setState({ isLoading: false })

    if (cancelSource != null) cancelSource.cancel('User abort')
  })
}