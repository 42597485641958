import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import SettingsIcon from '@material-ui/icons/Settings'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import ApiService from '../../services/APIService'
import SankeyChart from '../charts/SankeyChart'
import DataTable from '../library/tableComponents/DataTable'
import {
  contentTagsFromAuth,
  sourceSelectorFromAuth,
  validateContentTagFromProps,
  validateSubDomainFromProps,
  normalizeTitle
} from '../utils/helperFunctions'
import { USAGE_FILTER_USER } from '../../config/config'
import ContentSelector from '../ContentSelector'
import { refreshAuth } from './helpers/pageHelper'
import SankeyOptionsDialog from '../dialogs/SankeyOptionsDialog'
import NavigationDetailsDialog from '../dialogs/NavigationDetailsDialog'

const styles = theme => ({
  middleBox: {
    textAlign: 'right',
  },
  '@media (min-width: 600px)': {
    middleBox: {
      textAlign: 'center'
    }
  }
})

class SankeyPage extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,

      options: {
        range: [100, Number.MAX_SAFE_INTEGER],
        scaleLogarithmic: false, // true/false
        diagramHeight: 500, // Pixel
        vertical: false // true/false
      },
      showOptionsDialog: false,
      maxWeight: Number.MAX_SAFE_INTEGER,

      showDetailsDialog: false,
      detailLines: [],

      logLines: [],
      alignments: ['left', 'left', 'right'],
      headLine: [],
      dataLines: [],
      fetchDate: '',
      tileInfos: {},

      contentTag: validateContentTagFromProps(props),
      subDomain: validateSubDomainFromProps(props)
    }
  }

  componentDidMount () {
    this.updateTileInfos().then(() => this.updateStats())
  }

  static getDerivedStateFromProps (props, state) {
    const contentTag = validateContentTagFromProps(props)
    const subDomain = validateSubDomainFromProps(props)

    const propsNames = props.hostNames ? Object.keys(props.hostNames) : []
    const stateNames = state.hostNames ? state.hostNames : {}
    const hostNamesChanged = propsNames.length !== Object.keys(stateNames).length || !propsNames.every(x => stateNames.hasOwnProperty(x))

    if (contentTag !== state.contentTag || subDomain !== state.subDomain || hostNamesChanged) {
      const changeSet = {}
      if (contentTag !== state.contentTag) changeSet.contentTag = contentTag
      if (subDomain !== state.subDomain) changeSet.subDomain = subDomain
      if (hostNamesChanged) changeSet.hostNames = props.hostNames
      return changeSet
    }

    return null
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const { contentTag, subDomain } = this.state
    if (prevState.contentTag !== contentTag || prevState.subDomain !== subDomain) {
      this.updateStats()
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  filterCountries (sortedCountries, auth) {
    if (auth && auth.user && auth.user.additionalData && auth.user.additionalData.countries) {
      let allowedCountries = auth.user.additionalData.countries
      if (!Array.isArray(allowedCountries)) allowedCountries = [allowedCountries]
      let upperAllowedCountries = allowedCountries.map(country => country.toString().toUpperCase())
      return sortedCountries.filter(country => upperAllowedCountries.indexOf(country.toString().toLocaleUpperCase()) >= 0)
    } else {
      return sortedCountries
    }
  }

  getTitle = (path) => {
    const { tileInfos } = this.state

    const isERV = Object.keys(USAGE_FILTER_USER)[0] === 'erv'
    const isEPR = Object.keys(USAGE_FILTER_USER)[0] === 'epr'

    if (isERV) {
      if (path === '10') path = '13'
      if (path === '10!13') path = '13'
      path = path.replace(/^10!/, '13!')
      path = path.replace(/^4!/, '40!')
    }

    if (isEPR) {
      if (path === 'KESI_SPOC_ROOT_EPR') path = 'Home'
      if (path === 'kesi2/KESI_SPOC_ROOT_EPR') path = 'Home'
    }

    const cId = path.split('!').pop()
    if (!tileInfos[cId]) {
      return cId
    } else {
      return normalizeTitle(tileInfos[cId].title)
    }
  }

  async updateTileInfos () {
    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const { contentTag } = this.state

      const tiles = await ApiService.getTiles(newAuth, contentTag, false)
      const tileInfos = (tiles && tiles['mapping']) ? tiles['mapping'] : {}

      // unify titles
      const uniCodeNbsp = ' '
      const titles = new Set()
      for (const entry of Object.values(tileInfos)) {
        while (titles.has(entry.title)) entry.title += uniCodeNbsp
        titles.add(entry.title)
      }

      this.setState({
        tileInfos: tileInfos,
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  async updateStats () {
    try {
      const self = this
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const { contentTag, options } = this.state

      const apiResult = await ApiService.getSankey(newAuth, contentTag)

      const logLines = apiResult.lines.filter(([fromPath, toPath,]) => {
        const fromLength = (fromPath.match(/!/g) || []).length
        const toLength = (toPath.match(/!/g) || []).length
        return (fromLength <= toLength)
      })

      const maxWeight = logLines.reduce((lastMax, [, , weight]) => ((lastMax < weight) ? weight : lastMax), 0)

      this.setState({
        headLine: ['From', 'To', 'Count'],
        logLines: logLines.map(([fromPath, toPath, weight]) => [self.getTitle(fromPath), self.getTitle(toPath), weight]),
        dataLines: apiResult.lines.map(([fromPath, toPath, weight]) => [self.getTitle(fromPath), self.getTitle(toPath), weight]),
        fetchDate: (new Date(apiResult.fetchDate)).toLocaleString(),
        maxWeight: maxWeight,
        options: Object.assign({}, options, { range: [options.range[0], maxWeight] }),
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  closeOptionsDialog = () => {
    this.setState({ showOptionsDialog: false })
  }

  showOptionsDialog = () => {
    this.setState({ showOptionsDialog: true })
  }

  changeOptions = (newOptions) => {
    this.setState({ options: newOptions })
  }

  onChartClick = (pointerEvent) => {
    if (pointerEvent && pointerEvent.point && pointerEvent.point.name) {
      const { tileInfos, dataLines } = this.state
      const entry = Object.values(tileInfos).find(entry => entry.title === pointerEvent.point.name)
      if (entry) {
        const filteredDataLines = dataLines.filter(([fromPath, toPath, weight]) => fromPath === pointerEvent.point.name)
        this.setState({ showDetailsDialog: true, detailLines: filteredDataLines })
      }
    }
  }

  closeDetailsDialog = () => {
    this.setState({ showDetailsDialog: false })
  }

  renderHeader () {
    const { fetchDate, subDomain, contentTag } = this.state
    const { auth, classes, hostNames } = this.props
    const sourceSelector = sourceSelectorFromAuth(auth)
    const contentTags = contentTagsFromAuth(auth)

    if (!sourceSelector) {
      return (<Grid container alignItems={'center'} style={{ padding: 24 }}>
        <Grid item xs={4}>
          {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <Button startIcon={<SettingsIcon/>}
                  onClick={this.showOptionsDialog}>{getString('SANKEY_DIALOG_TITLE')}</Button>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <ContentSelector linkTemplate={`/sankey/${subDomain}/{{PLACEHOLDER}}`}
                           actContentTag={contentTag} contentTags={contentTags}/>
        </Grid>
      </Grid>)
    } else {
      return (<Grid container alignItems={'center'} style={{ padding: 24 }}>
        <Grid item sm={4} xs={6}>
          {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
        </Grid>
        <Grid item sm={4} xs={6} className={classes.middleBox}>
          <ContentSelector linkTemplate={`/sankey/{{PLACEHOLDER}}/${contentTag}`}
                           label="Herkunft"
                           actContentTag={subDomain} contentTags={hostNames}/>

        </Grid>
        <Grid item sm={4} xs={12} style={{ textAlign: 'right' }}>
          <ContentSelector linkTemplate={`/sankey/${subDomain}/{{PLACEHOLDER}}`}
                           actContentTag={contentTag} contentTags={contentTags}/>
        </Grid>
      </Grid>)
    }
  }

  render () {
    const { isLoading } = this.state
    if (isLoading) {
      return (<WaitForLoad/>)
    } else {
      const {
        logLines,
        headLine,
        dataLines,
        alignments,
        fetchDate,
        contentTag,
        options,
        showOptionsDialog,
        maxWeight,
        showDetailsDialog,
        detailLines
      } = this.state

      const hasSeries = logLines && logLines.length > 0

      const seriesBase = logLines
        .filter(([, , weight]) => (weight >= options.range[0] && weight <= options.range[1]))
        .map(([fromPath, toPath, weight]) => [fromPath, toPath, options.scaleLogarithmic ? Math.log(weight) : weight])

      const filenamePrefix = 'Sankey-' + contentTag
      const diagramTitle = getString(options.scaleLogarithmic ? 'SANKEY_DIAGRAM_LOGARITHMIC_TITLE' : 'SANKEY_DIAGRAM_ABSOLUTE_TITLE')
      return (<Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        {this.renderHeader()}

        {hasSeries && (<Grid item xs={12}>
          <SankeyChart series={seriesBase}
                       title={diagramTitle}
                       seriesTitle="click paths"
                       inverted={options.vertical}
                       height={options.diagramHeight}
                       onClick={this.onChartClick}/>
        </Grid>)}

        <DataTable
          headLine={headLine}
          alignments={alignments}
          dataLines={dataLines}
          fetchDate={fetchDate}
          excelTitle={getString('MENU_SANKEY')}
          filenamePrefix={filenamePrefix}/>

        {showOptionsDialog && <SankeyOptionsDialog
          closeFunc={this.closeOptionsDialog}
          onChange={this.changeOptions}
          options={options}
          maxWeight={maxWeight}/>}
        {showDetailsDialog && <NavigationDetailsDialog closeFunc={this.closeDetailsDialog} dataLines={detailLines}/>}
      </Grid>)
    }
  }
}

SankeyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  hostNames: PropTypes.object.isRequired,
  tabActions: PropTypes.object
}

export default withRouter(withStyles(styles)(SankeyPage))
