import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@material-ui/core/Checkbox'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

function TableEnhancedBody ({ records, bodyCells, selected, isSelected, handleCheckBoxClick }) {
  const rowCount = records.length
  const showCheckBox = (typeof selected !== 'undefined' && selected !== null) && typeof isSelected !== 'undefined' && typeof handleCheckBoxClick !== 'undefined'
  return (
    <TableBody>
      {records.map(function (record, index) {

        return (<TableRow key={index}>
          {showCheckBox && (<TableCell
            padding={'none'}>
            <Checkbox
              checked={isSelected(record.id)}
              onClick={(event) => { handleCheckBoxClick(event, record.id)}}/>
          </TableCell>)}

          {bodyCells.map(cellDescription => {
              const type = cellDescription.type || 'data'
              switch (type) {

                case 'render':
                  return (<TableCell
                    key={cellDescription.id}
                    align={cellDescription.align || 'left'}
                    padding={cellDescription.disablePadding ? 'none' : 'normal'}>
                    {React.createElement(cellDescription.value, { record: record, index: index, rowcount: rowCount })}
                  </TableCell>)

                case 'htmlData':
                  return (<TableCell
                    key={cellDescription.id}
                    align={cellDescription.numeric ? 'right' : 'left'}
                    padding={cellDescription.disablePadding ? 'none' : 'normal'}
                    dangerouslySetInnerHTML={{ __html: typeof cellDescription.value === 'string' ? record[cellDescription.value] : cellDescription.value(record) }}
                  />)

                case 'data':
                default:
                  return (<TableCell
                    key={cellDescription.id}
                    align={cellDescription.numeric ? 'right' : 'left'}
                    padding={cellDescription.disablePadding ? 'none' : 'normal'}>
                    {typeof cellDescription.value === 'string' ? record[cellDescription.value] : cellDescription.value(record)}
                  </TableCell>)
              }
            }
          )}
        </TableRow>)
      })}
    </TableBody>
  )
}

TableEnhancedBody.propTypes = {
  records: PropTypes.array.isRequired,
  bodyCells: PropTypes.array.isRequired,
  // for selectable rows
  selected: PropTypes.array,
  isSelected: PropTypes.func,
  handleCheckBoxClick: PropTypes.func
}

export default React.memo(TableEnhancedBody)
