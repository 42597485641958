import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import MobileOff from '@material-ui/icons/MobileOff'

import { AuthContext } from '../library/pageComponents/AuthContext'
import FilterField from '../library/pageComponents/FilterField'
import WaitForLoad from '../library/pageComponents/WaitForLoad'
import enhanceEditButton, { EditButton } from '../library/tableComponents/EditButton'
import TableEnhanced from '../library/tableComponents/TableEnhanced'
import { showAlert } from 'eqmod-react-alert'

import getString from '../../config/strings'
import { APP_SELECTIONS } from '../../config/config'

import { addSubscription, deferredReload } from './helpers/pageHelper'
import { formatDateTime } from '../helpers/dateHelper'

import { activationCodeService } from '../../services/dataService'

import ActivationCodeDialog from '../dialogs/ActivationCodeDialog'
import ActivationCodeQrDialog from '../dialogs/ActivationCodeQrDialog'
import ActivationCodeDeactivateDialog from '../dialogs/ActivationCodeDeactivateDialog'
import { QrButton } from '../library/tableComponents/QrButton'

const headCells = [
  { id: '0', disablePadding: true, canBeSorted: false, label: '\u00A0' },
  { id: '1', disablePadding: true, canBeSorted: false, label: '\u00A0' },
  { id: 'code', canBeSorted: true, label: getString('ACTIVATION_CODE_HEADER_CODE') },
  { id: 'feature', canBeSorted: true, label: getString('ACTIVATION_CODE_HEADER_FEATURES') },
  { id: 'valid', canBeSorted: false, label: getString('ACTIVATION_CODE_HEADER_VALID') },
  { id: 'maxClients', canBeSorted: false, label: getString('ACTIVATION_CODE_HEADER_MAX_CLIENTS') },
  { id: 'maxUsers', canBeSorted: false, label: getString('ACTIVATION_CODE_HEADER_MAX_USERS') },
  { id: 'usages', canBeSorted: false, label: getString('ACTIVATION_CODE_HEADER_USAGES') },
  { id: '8', disablePadding: true, canBeSorted: false, label: '\u00A0' }
]

const bodyCells = [
  { id: '0', align: 'center', disablePadding: true, type: 'render' },
  { id: '1', align: 'center', disablePadding: true, type: 'render' },
  { id: 'code', type: 'data', value: 'code' },
  { id: 'feature', type: 'data', value: 'feature' },
  {
    id: 'valid',
    type: 'data',
    value: record => formatDateTime(record.valid_from) + ' - ' + formatDateTime(record.valid_until)
  },
  { id: 'maxClients', type: 'data', value: record => record.maxClients === -1 ? '∞' : record.maxClients },
  { id: 'maxUsers', type: 'data', value: record => record.only_oneuser ? 1 : '∞' },
  { id: 'usages', type: 'data', value: 'usages' },
  { id: '8', align: 'center', disablePadding: true, type: 'render' }
]

const labelSelectors = {
  zeroFiltered: 'ACTIVATION_CODE_ZERO_FILTERED',
  zero: 'ACTIVATION_CODE_ZERO',
  deleteHeader: 'ACTIVATION_CODE_DELETE_HEADER',
  deleteText: 'ACTIVATION_CODE_DELETE_TEXT'
}

const enhanceQrButton = (showFunc) => {
  return React.memo((props) => {
    const record = props?.record ?? {}
    const hasConfigMessage = !!(record._configMessage?.id)
    const hasAppSelections = !!(APP_SELECTIONS.length > 0)
    const isValid = (!record.valid_until || new Date(record.valid_until) > new Date())
    const isFirstActivable = (!record.firstActivateUntil || new Date(record.firstActivateUntil) > new Date())
    const isActivable = (!record.activateUntil || new Date(record.activateUntil) > new Date())

    return (hasConfigMessage && hasAppSelections && isValid && isFirstActivable && isActivable && !record?.revoked)
      ? <QrButton {...Object.assign({ color: 'primary' }, props, { showFunc: showFunc })} />
      : <span style={{ minWidth: 46, display: 'inline-block' }}>{'\u00A0'}</span>
  })
}

const enhanceDeleteButton = (editFunc) => {
  return React.memo((props) => {
    const now = new Date()
    const invalidTypes = {
      delete: (props?.record?.usages ?? 0) > 0,
      disableActivation: (props?.record?.firstActivateUntil && new Date(props?.record?.firstActivateUntil) < now),
      disableUsage: (props?.record?.valid_until && new Date(props?.record?.valid_until) < now)
    }
    let initialType = Object.keys(invalidTypes).find(key => !invalidTypes[key]) ?? ''
    return initialType === ''
      ? <span style={{ minWidth: 46, display: 'inline-block' }}>{'\u00A0'}</span>
      : <EditButton {...Object.assign({}, props, { editFunc: editFunc, icon: MobileOff, color: 'secondary' })} />
  })
}

const findSysMessage = (record) => {
  const sysMessages = record?._configMessage?.sysMessages
  if (!sysMessages) return null

  const sysMessage = sysMessages.find(message => (message?.systemAction ?? '') === 'checkActivationCode')
  return sysMessage ?? null
}

const extractActionParams = (record) => {
  const sysMessage = findSysMessage(record)
  const actionParams = { ...sysMessage?.actionParams }
  delete actionParams.activationCode
  return actionParams
}

class ActivationCodesPage extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      actRecord: null,
      dialogType: null,

      records: [],
      filter: '',
      page: 0,
      rowsPerPage: 10,
      count: 0,
      order: { column: 'code', direction: 'asc' },

      inscriptions: {},

      isLoading: false
    }

    this.head = React.createRef()
    this.deferredTimeout = null
    this.subscription = null
    this.isCancelled = false

    bodyCells[0].value = enhanceEditButton((index) => {this.openDialog(index)})
    bodyCells[1].value = enhanceQrButton((index) => {this.openQrDialog(index)})
    bodyCells[8].value = enhanceDeleteButton((index) => {this.openDeactivateDialog(index)})
  }

  async reload () {
    const { page, rowsPerPage, order } = this.state
    await this.load(page, rowsPerPage, order.column, order.direction)
  }

  componentDidMount () {
    this.reload()
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const { filter } = this.state
    if (prevState.filter !== filter) {
      deferredReload(this)
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async load (page, rowsPerPage, sortColumn, orderDirection) {
    const { filter } = this.state
    this.setState({ isLoading: true })

    addSubscription(this)

    try {
      if (this.isCancelled) return

      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        this.setState({ isLoading: false })
        return
      }
      if (this.isCancelled) return

      let count = await activationCodeService.countAll(newAuth, { filter: filter })
      if (this.isCancelled) return

      let start = page * rowsPerPage
      if (start > count) {
        start = 0
        page = 0
      }
      if (this.isCancelled) return

      let data = []
      if (count > 0) {
        data = await activationCodeService.getAll(newAuth, {
          offset: start,
          max: rowsPerPage,
          filter: filter,
          sort: sortColumn,
          order: orderDirection
        })
        if (this.isCancelled) return
      }

      this.setState({
        records: data,
        page: page,
        rowsPerPage: rowsPerPage,
        order: { column: sortColumn, direction: orderDirection },
        count: count,
        isLoading: false
      })

    } catch (error) {
      this.subscription = null
      if (this.isCancelled) return

      this.setState({ isLoading: false })
      console.error('ERROR', 'ActivationCodePage.reload', error)
      if (!error.message.startsWith('isCancelled'))
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  openDialog = (index) => {
    const { user, refreshAuth } = this.context
    if (!user) {
      refreshAuth()
      return
    }
    const defaultValues = user?.additionalData?.activationCodes?.fixedValues ?? {}
    const emptyRecord = { ...activationCodeService.getEmptyRecordImmediately(), ...defaultValues }

    const { records } = this.state

    if (index !== -1 && records[index]) {
      // copy record
      let record = Object.assign({}, records[index])
      // ensure all fields are initialized
      Object.keys(emptyRecord).forEach(fieldName => {
        if (record[fieldName] === null) record[fieldName] = emptyRecord[fieldName]
      })
      record._actionParams = { ...extractActionParams(record), ...defaultValues.actionParams }
      this.setState({ actRecord: record, dialogType: 'edit' })
    } else {
      // init with empty Record
      emptyRecord._actionParams = { ...extractActionParams(emptyRecord), ...defaultValues.actionParams }
      this.setState({ actRecord: emptyRecord, dialogType: 'edit' })
    }
  }

  openQrDialog = (index) => {
    const { records } = this.state
    if (index !== -1 && records[index]) {
      let record = Object.assign({}, records[index])
      this.setState({ actRecord: record, dialogType: 'qrCode' })
    }
  }

  openDeactivateDialog = (index) => {
    const { records } = this.state
    if (index !== -1 && records[index]) {
      let record = Object.assign({}, records[index])
      this.setState({ actRecord: record, dialogType: 'deactivate' })
    }
  }

  closeDialog = () => {
    this.setState({ actRecord: null })
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  async saveRecord (record) {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) return null

      const fixInteger = (fieldName) => {
        if (typeof record[fieldName] === 'undefined' || record[fieldName] === null || record[fieldName] === '')
          record[fieldName] = null
        else if (typeof record[fieldName] === 'string')
          record[fieldName] = Number.parseInt(record[fieldName])
      }
      for (const fieldName of ['maxClients']) fixInteger(fieldName)
      if (Array.isArray(record.publicPathes)) {
        record.publicPathes = record.publicPathes.length ? JSON.stringify(record.publicPathes) : ''
      }
      if (Array.isArray(record.allowedMailDo)) {
        record.allowedMailDomains = record.allowedMailDomains.length ? JSON.stringify(record.allowedMailDomains) : ''
      }

      if (!record._configMessage) {
        record._configMessage = {
          selectionKey: 'AppInitialization:RequestCode:' + record.code,
          sysMessages: [
            {
              actionParams: {
                activationCode: record.code,
              },
              systemAction: 'checkActivationCode'
            }
          ]
        }
      }
      const sysMessage = findSysMessage(record)
      if (sysMessage) {
        sysMessage.actionParams = { ...record._actionParams, activationCode: record.code }
      }
      delete record._actionParams
      delete record.usages

      let data
      if (!record.id) {
        data = await activationCodeService.save(newAuth, record)
      } else {
        data = await activationCodeService.update(newAuth, record)
      }

      await this.reload()
      return data

    } catch (error) {
      console.error('ERROR', 'ActivationCodesPage.saveRecord save job', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return null
    }
  }

  deleteRecord = async (record) => {
    if (record) {
      this.setState({ isLoading: true })
      try {
        const { refreshAuth } = this.context
        const newAuth = await refreshAuth()
        if (newAuth === null) {
          this.setState({ isLoading: false })
          return
        }

        await activationCodeService.remove(newAuth, record.id)
        await this.reload()
      } catch (error) {
        this.setState({ isLoading: false })
        console.error('ERROR', 'ActivationCodesPage.deleteRecord', error)
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      }
    }
  }

  renderHeader () {
    const { filter } = this.state

    return (
      <React.Fragment>
        <Grid item container xs={12} sm={6} justifyContent="flex-start" alignItems="flex-end">
          <div>
            <Button variant="contained" color="primary" onClick={() => this.openDialog(-1)}
                    startIcon={<AddIcon/>}>
              {getString('ACTIVATION_CODE_ADD_BUTTON')}
            </Button>
          </div>
        </Grid>

        <Grid item container xs={12} sm={6} justifyContent="flex-end" alignItems="flex-end" ref={this.head}>
          <FilterField value={filter} handleFilterChange={this.handleFilterChange}/>
        </Grid>
      </React.Fragment>
    )
  }

  renderDialog () {
    const { actRecord, dialogType } = this.state

    if (actRecord !== null) {
      switch (dialogType) {
        case 'edit':
          return (<ActivationCodeDialog
            title={getString(actRecord.id ? 'ACTIVATION_CODE_EDIT_HEADER' : 'ACTIVATION_CODE_ADD_BUTTON')}
            action={getString(actRecord.id ? 'ACTIVATION_CODE_UPDATE_BUTTON' : 'ACTIVATION_CODE_CREATE_BUTTON')}
            closeFunc={this.closeDialog}
            saveFunc={(record) => {return this.saveRecord(record)}}
            record={actRecord}
          />)

        case  'qrCode':
          return (<ActivationCodeQrDialog
            title={getString('ACTIVATION_CODE_QR_APP_HEADER')}
            action={getString('BACK_BUTTON')}
            closeFunc={this.closeDialog}
            record={actRecord}
          />)

        case 'deactivate':
          return (<ActivationCodeDeactivateDialog
            title={getString('ACTIVATION_CODE_DEACTIVATE_HEADER')}
            action={getString('ACTIVATION_CODE_DEACTIVATE_BUTTON')}
            closeFunc={this.closeDialog}
            saveFunc={(record) => {return this.saveRecord(record)}}
            deleteFunc={this.deleteRecord}
            record={actRecord}
          />)
      }
    }
    return null
  }

  renderBody () {
    const { count, filter, order, page, records, rowsPerPage } = this.state

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TableEnhanced records={records}
                         reloadFunc={(newPage, newRowsPerPage, newSortColumn, newOrderDirection) => {this.load(newPage, newRowsPerPage, newSortColumn, newOrderDirection)}}
                         page={page}
                         rowsPerPage={rowsPerPage}
                         sortColumn={order.column}
                         orderDirection={order.direction}
                         count={count}
                         isFiltered={filter !== ''}
                         headCells={headCells}
                         bodyCells={bodyCells}
                         labelSelectors={labelSelectors}/>
          {this.renderDialog()}
        </Grid>
      </React.Fragment>
    )
  }

  render () {
    const { isLoading } = this.state
    const top = this.head.current ? this.head.current.getBoundingClientRect().bottom : 0
    return (
      <Grid container spacing={3} style={{ width: '100%', margin: 0, marginTop: 12, paddingBottom: 16 }}>
        {this.renderHeader()}

        {isLoading && <WaitForLoad top={top}/>}
        {this.renderBody()}
      </Grid>
    )
  }
}

ActivationCodesPage.propTypes = {
  tabActions: PropTypes.object
}

ActivationCodesPage.contextType = AuthContext

export default ActivationCodesPage