import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import ApiService from '../../services/APIService'
import DataTable from '../library/tableComponents/DataTable'
import HighChart from '../charts/HighChart'
import { Legend } from 'react-jsx-highcharts'
import { refreshAuth } from './helpers/pageHelper'

const plotOptions = {
    column: {
        dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y}',
            y: 20
        }
    },
    series: {
        turboThreshold: 10000,
        events: {
            legendItemClick: function (e) {
                // if self is visible,
                const name = this.name
                if (this.visible) {
                    // invert visibility of others
                    this.chart.series.forEach((series) => {
                        if (series.name !== name) {
                            if (series.visible) series.hide()
                            else series.show()
                        }
                    })
                } else {
                    // show myself, hide the others
                    this.chart.series.forEach((series) => {
                        if (series.name === name) series.show()
                        else series.hide()
                    })
                }
                return false
            }
        }
    }
}

class GwsPage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            fetchDate: '',
            headLine: [],
            dataLines: [],
            registrationStates: null,
            resolution: 'monthly'
        }
    }

    componentDidMount() {
        this.updateStats()
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const { tabActions } = this.props
        if (tabActions) {
            setTimeout(() => {
                tabActions.updateIndicator()
            }, 0)
        }
    }

    async updateStats() {
        try {
            const newAuth = await refreshAuth(this.props)
            if (newAuth === null) return

            this.setState({ isLoading: true })

            const data = await ApiService.getGwsStats(newAuth)

            let series = { trials: [], registered: [] }
            let dataLines = []
            let max = null
            data.lines.forEach(function (entry) {
                if (max === null || max < entry.logDate) {
                    max = entry.logDate
                }
                series.trials.push([entry.logDate.substring(0, 10), entry.trials])
                series.registered.push([entry.logDate.substring(0, 10), entry.registered])
                dataLines.push([entry.logDate.substring(0, 10), entry.trials, entry.registered])
            })

            let registrationStates = []
            for (let key in series) {
                if (series.hasOwnProperty(key)) {
                    registrationStates.push({ name: key, id: key, data: series[key] })
                }
            }

            dataLines.sort((a, b) => {
                return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0
            })

            this.setState({
                fetchDate: new Date(max).toLocaleString(),
                headLine: [getString('DATE'), 'trials', 'registered'],
                dataLines: dataLines,
                registrationStates: registrationStates,
                isLoading: false
            })
        } catch (error) {
            this.setState({ isLoading: false })
            showAlert(getString(error.message), getString('ERROR_HEADLINE'))
        }
    }

    render() {
        if (this.state.isLoading) {
            return <WaitForLoad />
        } else {
            const { fetchDate, registrationStates, headLine, dataLines } = this.state
            const hasSeries = registrationStates && Object.keys(registrationStates).length > 0
            const filenamePrefix = 'gws-licenses'

            return (
                <Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
                    <Grid container alignItems="flex-start" style={{ padding: 24 }}>
                        <Grid item xs={6} style={{ textAlign: 'center', padding: 24 }}>
                            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
                        </Grid>
                    </Grid>

                    {hasSeries && (
                        <Grid item xs={12}>
                            <div style={{ margin: '32px 16px' }}>
                                <HighChart
                                    title={''}
                                    xAxisTitle={null}
                                    yAxisTitle={null}
                                    series={registrationStates}
                                    legend={<Legend verticalAlign="top" adjustChartSize={false} maxHeight={120} />}
                                    plotOptions={plotOptions}
                                />
                            </div>
                        </Grid>
                    )}

                    <DataTable
                        headLine={headLine}
                        dataLines={dataLines}
                        fetchDate={fetchDate}
                        excelTitle={getString('MENU_GWSREGISTRATIONS')}
                        filenamePrefix={filenamePrefix}
                    />
                </Grid>
            )
        }
    }
}

GwsPage.propTypes = {
    auth: PropTypes.object,
    refreshAuth: PropTypes.func.isRequired,
    tabActions: PropTypes.object
}

export default GwsPage
