import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import getString from '../../config/strings'

import { showAlert } from 'eqmod-react-alert'
import WaitForLoad from '../library/pageComponents/WaitForLoad'
import FilterField from '../library/pageComponents/FilterField'
import TableEnhanced from '../library/tableComponents/TableEnhanced'
import enhanceDeleteButton from '../library/tableComponents/DeleteButton'
import { eprUserService } from '../../services/dataService'
import { deferredReload, addSubscription } from './helpers/pageHelper'
import { formatDate, formatDateTime } from '../helpers/dateHelper'

import IconButton from '@material-ui/core/IconButton'
import ExcelIcon from '../icons/ExcelIcon'
import zipcelx from 'zipcelx'
import { AuthContext } from '../library/pageComponents/AuthContext'

const headCells = [
  { id: 'firstname', canBeSorted: true, label: getString('EPRUSER_HEADLINE_FIRSTNAME') },
  { id: 'lastname', canBeSorted: true, label: getString('EPRUSER_HEADLINE_LASTNAME') },
  { id: 'email', canBeSorted: true, label: getString('EPRUSER_HEADLINE_EMAIL') },
  { id: 'dateCreated', canBeSorted: true, label: getString('EPRUSER_HEADLINE_DATE_CREATED') },
  { id: 'lastAccess', canBeSorted: false, label: getString('EPRUSER_HEADLINE_LAST_ACCESS') },
  { id: '5', disablePadding: true, canBeSorted: false, label: '\u00A0' }
]

const bodyCells = [
  { id: 'firstname', type: 'data', value: 'firstname' },
  { id: 'lastname', type: 'data', value: 'lastname' },
  { id: 'email', type: 'data', value: 'email' },
  { id: 'dateCreated', type: 'data', value: record => formatDateTime(record['dateCreated']) },
  { id: 'lastAccess', type: 'data', value: record => formatDateTime(record['lastAccess']) },
  { id: '5', align: 'center', disablePadding: true, type: 'render' }
]

const labelSelectors = {
  zeroFiltered: 'EPRUSER_ZERO_FILTERED',
  zero: 'EPRUSER_ZERO',
  deleteHeader: 'EPRUSER_DELETE_HEADER',
  deleteText: 'EPRUSER_DELETE_TEXT'
}

class UserPage extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      records: [],
      filter: '',
      page: 0,
      rowsPerPage: 10,
      count: 0,
      isLoading: false,
      order: { column: 'dateCreated', direction: 'asc' }
    }

    this.head = React.createRef()
    this.deferredTimeout = null
    this.subscription = null
    this.isCancelled = false

    bodyCells[5].value = enhanceDeleteButton((index) => {this.deleteRecord(index)}, labelSelectors)
  }

  async reload () {
    const { page, rowsPerPage, order } = this.state
    await this.load(page, rowsPerPage, order.column, order.direction)
  }

  componentDidMount () {
    this.reload()
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const { filter } = this.state
    if (prevState.filter !== filter) {
      deferredReload(this)
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async load (page, rowsPerPage, sortColumn, orderDirection) {
    const { filter } = this.state
    this.setState({ isLoading: true })

    addSubscription(this)

    try {
      if (this.isCancelled) return

      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        this.setState({ isLoading: false })
        return
      }
      if (this.isCancelled) return

      let count = await eprUserService.countAll(newAuth, { filter: filter })
      if (this.isCancelled) return

      let start = page * rowsPerPage
      if (start > count) {
        start = 0
        page = 0
      }
      if (this.isCancelled) return

      let data = []
      if (count > 0) {
        data = await eprUserService.getAll(newAuth, {
          offset: start,
          max: rowsPerPage,
          filter: filter,
          sort: sortColumn,
          order: orderDirection
        })
        if (this.isCancelled) return
      }

      this.setState({
        records: data,
        page: page,
        rowsPerPage: rowsPerPage,
        order: { column: sortColumn, direction: orderDirection },
        count: count,
        isLoading: false
      })

    } catch (error) {
      this.subscription = null
      if (this.isCancelled) return

      this.setState({ isLoading: false })
      console.error('ERROR', 'UserPage.reload', error)
      if (!error.message.startsWith('isCancelled'))
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  async deleteRecord (index) {
    const { records } = this.state
    const record = (records[index]) ? Object.assign({}, records[index]) : null

    if (record) {
      this.setState({ isLoading: true })
      try {
        const { refreshAuth } = this.context
        const newAuth = await refreshAuth()
        if (newAuth === null) {
          this.setState({ isLoading: false })
          return
        }

        await eprUserService.remove(newAuth, record.id)
        await this.reload()
      } catch (error) {
        this.setState({ isLoading: false })
        console.error('ERROR', 'UserPage.deleteRecord', error)
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      }
    }
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  handleDownload = async event => {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        return []
      }

      const { filter } = this.state
      let data = await eprUserService.getUnlimited(newAuth, { filter: filter, sort: 'dateCreated', order: 'asc' })

      let headLine = [
        getString('EPRUSER_HEADLINE_FIRSTNAME'),
        getString('EPRUSER_HEADLINE_LASTNAME'),
        getString('EPRUSER_HEADLINE_EMAIL'),
        getString('EPRUSER_HEADLINE_DATE_CREATED'),
        getString('EPRUSER_HEADLINE_LAST_ACCESS'),
      ]

      let config = {
        filename: 'Anwender-' + new Date().toJSON().substring(0, 10),
        sheet: {
          data: []
        }
      }

      // headline
      config.sheet.data.push(headLine.map(header => {
        return { value: header, type: 'string' }
      }))

      const translation = [
        ['firstname', 'string'],
        ['lastname', 'string'],
        ['email', 'string'],
        ['dateCreated', 'dateTime'],
        ['lastAccess', 'dateTime']
      ]

      // body
      data.forEach(line => {
        config.sheet.data.push(translation.map((value, index) => {
          let [fieldName, type] = value
          let cellValue = line[fieldName]
          if (type === 'date') {
            cellValue = formatDate(cellValue)
          } else if (type === 'dateTime') {
            cellValue = formatDateTime(cellValue)
          }
          return { value: cellValue, type: type }
        }))
      })

      zipcelx(config)

    } catch (error) {
      console.error('ERROR', 'UserPage.handleDownload', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  renderHeader () {
    const { filter } = this.state

    return (
      <React.Fragment>
        <Grid item container xs={12} justifyContent="flex-end" alignItems="flex-end" ref={this.head}>
          <FilterField value={filter} handleFilterChange={this.handleFilterChange}/>
        </Grid>
      </React.Fragment>
    )
  }

  renderBody () {
    const { count, filter, order, page, records, rowsPerPage } = this.state

    return (
      <React.Fragment>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <IconButton onClick={this.handleDownload}>
            <ExcelIcon style={{ color: '#1f7144' }}/>
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <TableEnhanced records={records}
                         reloadFunc={(newPage, newRowsPerPage, newSortColumn, newOrderDirection) => {this.load(newPage, newRowsPerPage, newSortColumn, newOrderDirection)}}
                         page={page}
                         rowsPerPage={rowsPerPage}
                         sortColumn={order.column}
                         orderDirection={order.direction}
                         count={count}
                         isFiltered={filter !== ''}
                         headCells={headCells}
                         bodyCells={bodyCells}
                         labelSelectors={labelSelectors}/>
        </Grid>
      </React.Fragment>
    )
  }

  render () {
    const { isLoading } = this.state
    const top = this.head.current ? this.head.current.getBoundingClientRect().bottom : 0
    return (
      <Grid container spacing={3} style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        {this.renderHeader()}

        {isLoading && <WaitForLoad top={top}/>}
        {this.renderBody()}
      </Grid>
    )
  }
}

UserPage.propTypes = {
  tabActions: PropTypes.object
}

UserPage.contextType = AuthContext

export default UserPage
