import React from 'react'
import { Chart, HighchartsChart, HighchartsProvider, PieSeries, YAxis } from 'react-jsx-highcharts'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'

const AnswersPieChart = ({ voting, showAbsolute, innerSize }) => {
  const renderData = voting['votingAnswers'] ? voting['votingAnswers'].slice() : []

  const pieData = []
  renderData.forEach(entry => {
    pieData.push({ name: entry.text, y: showAbsolute ? entry.count : entry.percent })
  })

  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart>
        <Chart/>
        <YAxis>
          <PieSeries dataLabels={{ enabled: true, distance: -60, style: { fontSize: '15px' } }}
                     center={['50%', '50%']} innerSize={innerSize} colors={null} data={pieData}/>
        </YAxis>
      </HighchartsChart>
    </HighchartsProvider>
  )
}

AnswersPieChart.propTypes = {
  voting: PropTypes.object.isRequired,
  showAbsolute: PropTypes.bool.isRequired,
  innerSize: PropTypes.string
}

AnswersPieChart.defaultProps = {
  innerSize: null
}

export default React.memo(AnswersPieChart)
