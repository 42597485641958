import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

function OptionsPanel (props) {
  const { value, index, id, children } = props

  return (<Typography component="div" role="tabpanel" hidden={value !== index}
                      style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'stretch' }}
                      id={id}>
    {children}
  </Typography>)
}

OptionsPanel.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default React.memo(OptionsPanel)
