import React, { memo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { formatDateTime, formatDateTimeSeconds } from '../../helpers/dateHelper'
import getString from '../../../config/strings'

import 'moment/locale/de'
import 'moment/locale/en-gb'

const useStyles = makeStyles(theme => ({
  footerDates: {
    color: theme.palette.text.hint,
    fontSize: '0.875rem'
  }
}))

const LastUpdatedDate = memo(({ lastUpdated, showSeconds = false }) => {
  const formatFunc = showSeconds ? formatDateTimeSeconds : formatDateTime
  return lastUpdated ? (
    <>
      /<span> {getString('LAST_UPDATED') + ' ' + formatFunc(lastUpdated)}</span>
    </>
  ) : null
})

const RecordDates = memo(({ dateCreated, lastUpdated, showSeconds = false }) => {
  const classes = useStyles()
  const formatFunc = showSeconds ? formatDateTimeSeconds : formatDateTime
  return (
    <Box className={classes.footerDates}>
      {dateCreated ? (
        <>
          <span>{getString('DATE_CREATED') + ' ' + formatFunc(dateCreated)} </span>
          <LastUpdatedDate showSeconds={showSeconds} lastUpdated={lastUpdated}/>
        </>
      ) : null}
    </Box>
  )
})

export default RecordDates
