import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { showHtmlAlert } from 'eqmod-react-alert'
import getString from '../../../config/strings'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  }
}))

export const DeleteButton = (props) => {
  const { record, deleteFunc, labelSelectors, index, ...restProps } = props
  const classes = useStyles()

  const askDelete = () => {
    showHtmlAlert(getString(labelSelectors.deleteText, [record._getString()]),
      getString(labelSelectors.deleteHeader),
      [{ label: getString('YES'), callback: () => {deleteFunc(index)} },
        { label: getString('NO') }])
  }

  return (<IconButton color="secondary"
                      aria-label="edit"
                      className={classes.button}
                      size='small'
                      onClick={askDelete}
                      {...restProps}>
    <DeleteIcon/>
  </IconButton>)
}

DeleteButton.propTypes = {
  record: PropTypes.object.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  labelSelectors: PropTypes.object.isRequired,
  index: PropTypes.number
}

const enhanceDeleteButton = (deleteFunc, labelSelectors) => {
  return React.memo((props) => {
    return <DeleteButton {...Object.assign({}, props, { labelSelectors: labelSelectors, deleteFunc: deleteFunc })} />
  })
}

export default enhanceDeleteButton
