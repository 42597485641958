import { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import QrIcon from '../../icons/QrIcon'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  }
}))

export const QrButton = (props) => {
  const { showFunc, index, color, ...restProps } = props
  const classes = useStyles()

  return (<IconButton color={color}
                      aria-label="show"
                      className={classes.button}
                      size="small"
                      onClick={() => {showFunc(index, false)}}
                      {...restProps}>
    <QrIcon/>
  </IconButton>)
}

QrButton.defaultProps = {
  color: 'primary'
}

QrButton.propTypes = {
  color: PropTypes.string.isRequired,
  showFunc: PropTypes.func.isRequired,
  index: PropTypes.number
}

const enhanceQrButton = (showFunc) => {
  return memo((props) =>
    <QrButton {...Object.assign({}, props, { showFunc: showFunc })} />
  )
}

export default enhanceQrButton