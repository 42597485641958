import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Legend } from 'react-jsx-highcharts'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import DrillDownChart, { stackLabelSum } from '../charts/DrillDownChart'
import DataTable from '../library/tableComponents/DataTable'
import ApiService from '../../services/APIService'
import ResolutionSelector from '../ResolutionSelector'
import { validateResolutionFromProps } from '../../config/resolutions'
import { sortDate, formatDate, seriesHelper, seriesToTable } from '../utils/helperFunctions'
import { refreshAuth } from './helpers/pageHelper'

class DownloadsPage extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      seriesBase: {},
      headLine: [getString('DATE'), 'android', 'ios', 'zip', getString('TOTAL')],
      dataLines: [],
      fetchDate: '',
      resolution: validateResolutionFromProps(props)
    }
  }

  componentDidMount () {
    this.updateStats()
  }

  static getDerivedStateFromProps (props, state) {
    const resolution = validateResolutionFromProps(props)

    if (resolution.name !== state.resolution.name) {
      return { resolution: resolution, isLoading: true }
    }

    return null
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    if (prevState.resolution.name !== this.state.resolution.name) {
      this.updateStats()
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async updateStats () {
    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const apiResult = await ApiService.getDownloads(newAuth, this.state.resolution.apiParam)

      let dataLines = []
      let translator = { apks: 'android', ipas: 'ios', zips: 'zip' }
      // eslint-disable-next-line no-unused-vars
      const { data, tagNames, tagTotals } = seriesHelper(apiResult.lines,
        (entry) => formatDate(entry['logDate']),
        (entry) => ['apks', 'ipas', 'zips'],
        (contentTag) => translator[contentTag],
        (entry, contentTag) => entry[contentTag],
        true)

      const seriesBase = {
        data: data,
        description: {
          0: { title: '', drillUpText: '', order: Object.keys(data).sort(sortDate), colorByPoint: true },
          1: {
            title: 'Period: {{PLACEHOLDER}}',
            drillUpText: 'periods',
            order: ['android', 'ios', 'zip'],
            level: 'type',
            type: 'column',
          }
        }
      }

      dataLines = seriesToTable(seriesBase, true)

      this.setState({
        dataLines: dataLines,
        seriesBase: seriesBase,
        fetchDate: (new Date(data.fetchDate)).toLocaleString(),
        isLoading: false
      })
    } catch (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  renderHeader () {
    const { fetchDate, resolution } = this.state

    return (<Grid container alignItems={'center'} style={{ padding: 24 }}>
      <Grid item xs={6}>
        {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <ResolutionSelector linkTemplate="/{{PLACEHOLDER}}" actResolution={resolution}/>
      </Grid>
    </Grid>)
  }

  renderBody () {
    const { seriesBase, headLine, dataLines, fetchDate, resolution } = this.state
    const hasSeries = seriesBase && seriesBase.data && Object.keys(seriesBase.data).length > 0
    const hasData = dataLines && dataLines.length > 0
    const filenamePrefix = 'Downloads-' + resolution.chartName

    return (
      <React.Fragment>
        {hasSeries && (<Grid item xs={12}>
          <DrillDownChart
            title=''
            seriesBase={seriesBase}
            stackLabelConfig={stackLabelSum}
            legend={<Legend verticalAlign='top' adjustChartSize={false} maxHeight={120}/>}/>
        </Grid>)}

        {hasData && <DataTable
          headLine={headLine}
          dataLines={dataLines}
          fetchDate={fetchDate}
          excelTitle={getString('MENU_INSTALLATIONS')}
          filenamePrefix={filenamePrefix}/>}
      </React.Fragment>
    )
  }

  render () {
    if (this.state.isLoading) {
      return (<WaitForLoad/>)
    } else {
      return (<Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        {this.renderHeader()}
        {this.renderBody()}
      </Grid>)
    }
  }
}

DownloadsPage.propTypes = {
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  tabActions: PropTypes.object
}

export default withRouter(DownloadsPage)
