import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'

import { validResolutionsForApi } from '../config/resolutions'
import getString from '../config/strings'

const useStyles = makeStyles({
    formControl: {
        margin: 0,
        minWidth: 120
    }
})

const ResolutionSelector = ({ linkTemplate, actResolution, validResolutionList }) => {
    const classes = useStyles()
    const actResolutionName = actResolution.name

    return (<FormControl className={classes.formControl}>
        <InputLabel shrink htmlFor="resolutionfield">
            {getString('LABEL_TIME_RESOLUTION')}
        </InputLabel>
        <Select
            value={actResolutionName}
            onChange={(e) => {}}
            input={<Input name="resolution" id="resolutionfield"/>}
            name="resolution"
            style={{ textAlign: 'left' }}
        >
            {validResolutionList.map(resolution => <MenuItem key={resolution.name} value={resolution.name} dense>
                <Link component={RouterLink}
                      color={'textPrimary'}
                      to={`${linkTemplate.replace(/{{PLACEHOLDER}}/g, resolution.name)}`}
                      style={{ fontWeight: (resolution.name === actResolutionName ? 500 : 400), flexGrow: 1 }}>
                    {getString(resolution.optionName)}
                </Link>
            </MenuItem>)
            }
        </Select>
    </FormControl>)
}

ResolutionSelector.propTypes = {
    linkTemplate: PropTypes.string.isRequired,
    actResolution: PropTypes.object.isRequired,
    validResolutionList: PropTypes.array.isRequired
}

ResolutionSelector.defaultProps = {
    validResolutionList: validResolutionsForApi
}

export default React.memo(ResolutionSelector)
