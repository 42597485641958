import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ExcelIcon = props => {
  return (<SvgIcon {...props} width="192.026" height="192.026" viewBox="0 0 192.026 192.026">
    <rect
      style={{ fill: '#ffffff', fillOpacity: 1, stroke: 'none' }}
      width="171.5"
      height="134.5"
      x="13.5"
      y="28.026001"
      ry="3.375"/>
    <g transform="translate(0,87.031249)">
      <path style={{ fillOpacity: 1, stroke: 'none' }}
            d="m 104.26889,44.255993 29.25267,0 0,15.026711 -29.25267,0 z m 35.25,0 29.25267,0 0,14.995461 -29.25267,0 z m -35.25,-21.78125 29.25267,0 0,15.745461 -29.25267,0 z m 35.25,0 29.25267,0 0,15.745461 -29.25267,0 z m -35.25,-21.749999 29.25267,0 0,15.745461 -29.25267,0 z m 35.25,0 29.25267,0 0,15.745461 -29.25267,0 z m -35.25,-21.75 29.25267,0 0,15.745461 -29.25267,0 z m 35.25,0 29.25267,0 0,15.745461 -29.25267,0 z m -35.25,-21.75 29.25267,0 0,15.745461 -29.25267,0 z m 35.25,0 29.25267,0 0,15.745461 -29.25267,0 z M 103.25,-60.749999 l 0,5.96875 78.28125,0 0,126.78125 -78.28125,0 0,6 77.78125,0 c 3.60618,0 6.53125,-2.92507 6.53125,-6.53125 l 0,-125.6875 c 0,-3.606183 -2.92507,-6.53125 -6.53125,-6.53125 l -77.78125,0 z"/>
    </g>
    <path style={{ stroke: 'none', opacity: 1, fillOpacity: 1 }}
          d="M 108.84375 9.0625 L 4.46875 27.625 L 4.46875 163.5625 L 108.78125 182.40625 L 108.84375 9.0625 z M 75.46875 65 L 60.53125 95.71875 L 75.875 126.625 L 62 125.8125 L 52.875 103.1875 L 43.9375 124.78125 L 31.71875 124.09375 L 46.0625 95.71875 L 32.4375 67.03125 L 45.65625 66.40625 L 53.34375 87.3125 L 62.59375 65.59375 L 75.46875 65 z "
    />
  </SvgIcon>)
}

export default React.memo(ExcelIcon)
