import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'

import getString from '../../../config/strings'

const CancelButton = ({ onClick, variant, color, title }) => {
  console.log('INFO', 'CancelButton.render')
  return (
    <Button onClick={onClick} color={color} variant={variant} size='small' className='inputMargin'>
      {title}
    </Button>
  )
}

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
}

CancelButton.defaultProps = {
  variant: 'text',
  color: 'secondary',
  title: getString('CANCEL')
}

export default memo(CancelButton)
