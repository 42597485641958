import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AnswersBarChart from './charts/AnswersBarChart'
import AnswersPieChart from './charts/AnswersPieChart'
import PercentageAbsoluteSelector from './PercentageAbsoluteSelector'
import ShowContextPath from './ShowContextPath'

const AccordionSummary = withStyles({
    expanded: { backgroundColor: '#f5f5f5' }
})(MuiAccordionSummary)

const useStyles = makeStyles(theme => ({
    grayText: {
        color: theme.palette.text.secondary
    }
}))

const VotingView = ({ voting }) => {
    const classes = useStyles()
    const [showMode, setShowMode] = useState('absolute')

    const changeShowMode = (event) => { setShowMode(event.target.value) }

    let sum = 0
    voting['votingAnswers'].forEach(answer => {sum += answer.count})

    return (<Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls={'Voting-' + voting.id + '-content'}
            id={'Voting-' + voting.id + '-header'}
        >
            <Typography variant={'body1'}>{voting.question + ' (' + sum + ')'}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
            {voting.title && (<Typography variant={'body2'}>
                <span className={classes.grayText}>Titel: </span>
                {voting.title}
            </Typography>)
            }
            <ShowContextPath nugget={voting}/>
            <PercentageAbsoluteSelector showMode={showMode} handleModeChange={changeShowMode}/>

            {voting['presentationType'] === 'bar' &&
            <AnswersBarChart voting={voting} showAbsolute={showMode === 'absolute'}/>}
            {voting['presentationType'] === 'pie' &&
            <AnswersPieChart voting={voting} showAbsolute={showMode === 'absolute'}/>}
            {voting['presentationType'] === 'donut' &&
            <AnswersPieChart voting={voting} showAbsolute={showMode === 'absolute'} innerSize={'40%'}/>}
        </AccordionDetails>
    </Accordion>)

}

VotingView.propTypes = {
    voting: PropTypes.object.isRequired
}

export default React.memo(VotingView)
