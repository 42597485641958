import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Legend } from 'react-jsx-highcharts'

import getString from '../../config/strings'
import { showAlert } from 'eqmod-react-alert'

import WaitForLoad from '../library/pageComponents/WaitForLoad'
import ApiService from '../../services/APIService'
import DrillDownChart, { stackLabelSum } from '../charts/DrillDownChart'
import DataTable from '../library/tableComponents/DataTable'
import { sortDate, contentTagsFromAuth, seriesHelper, seriesToTable } from '../utils/helperFunctions'
import { DEFAULT_TAG_LIST, USAGE_FILTER_USER } from '../../config/config'
import { refreshAuth } from './helpers/pageHelper'

class RegistrationsPage extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isLoading: false,
      seriesBase: {},
      headLine: [],
      dataLines: [],
      dateTotals: [],
      fetchDate: ''
    }
  }

  componentDidMount () {
    this.updateStats()
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  static extractSeries (users, contentTags, drupalTag) {
    switch (drupalTag) {
      case 'ergopro':
        return seriesHelper(users,
          (entry) => entry['dateCreated'].substring(0, 7),
          (entry) => (!entry['region'] ? ['none'] : [entry['region']]),
          (contentTag) => contentTag,
          (entry, contentTag) => 1,
          true)

      case 'lernminiaturen':
        return seriesHelper(users,
          (entry) => entry['dateCreated'].substring(0, 7),
          (entry) => (!entry['mailDomain'] ? ['other'] : [entry['mailDomain']]),
          (contentTag) => contentTag,
          (entry, contentTag) => 1,
          true)

      default:
        return seriesHelper(users,
          (entry) => entry['dateCreated'].substring(0, 7),
          (entry) => {
            const userTags = entry['tags'] === null || entry['tags'].length === 0 ? DEFAULT_TAG_LIST : entry['tags']
            return (!Array.isArray(userTags)) ? [userTags] : userTags
          },
          (contentTag) => contentTags[contentTag],
          (entry, contentTag) => 1,
          true)
    }
  }

  async updateStats () {
    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) return

      this.setState({ isLoading: true })

      const contentTags = contentTagsFromAuth(newAuth)
      const clients = await ApiService.getClients(newAuth, Object.keys(contentTags))
      const users = clients && clients['userInfo'] ? Object.values(clients['userInfo']) : []
      const drupalTag = Object.keys(USAGE_FILTER_USER)[0]
      const isERGOPRO = drupalTag === 'ergopro'
      const isLearningMiniatures = drupalTag === 'lernminiaturen'

      const { data, tagNames, tagTotals } = RegistrationsPage.extractSeries(users, contentTags, drupalTag)

      const seriesBase = {
        data: data,
        description: {
          0: { title: '', drillUpText: '', order: Object.keys(data).sort(sortDate), colorByPoint: true },
          1: {
            title: 'Period: {{PLACEHOLDER}}',
            drillUpText: 'periods',
            order: Object.keys(tagNames).sort(),
            level: 'contentTag',
            type: 'column',
          }
        }
      }

      let dataLines = seriesToTable(seriesBase, isERGOPRO || isLearningMiniatures)

      let headLine = [getString('DATE'), ...seriesBase.description[1].order]
      if (isERGOPRO || isLearningMiniatures) {
        headLine.push(getString('TOTAL'))
      }

      let dateTotals = [getString('TOTAL'), ...seriesBase.description[1].order.map(contentTag => tagTotals[contentTag])]
      if (isERGOPRO || isLearningMiniatures) {
        dateTotals.push(Object.values(tagTotals).reduce((sum, currentValue) => sum + currentValue, 0))
      }

      this.setState({
        seriesBase: seriesBase,
        headLine: headLine,
        dataLines: dataLines,
        dateTotals: dateTotals,
        fetchDate: (new Date(clients.fetchDate)).toLocaleString(),
        isLoading: false
      })
    } catch
      (error) {
      this.setState({ isLoading: false })
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  render () {
    if (this.state.isLoading) {
      return (<WaitForLoad/>)
    } else {
      const { seriesBase, headLine, dataLines, dateTotals, fetchDate } = this.state
      const hasSeries = seriesBase && seriesBase.data && Object.keys(seriesBase.data).length > 0
      const filenamePrefix = 'Registrierungen-Monat'

      return (<Grid container style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        <Grid container alignItems={'center'} style={{ padding: 24 }}>
          <Grid item xs={12}>
            {getString('DATA_COLLECTION_DATE')}: <b>{fetchDate}</b>
          </Grid>
        </Grid>

        {hasSeries && (<Grid item xs={12}>
          <DrillDownChart
            title=""
            seriesBase={seriesBase}
            stackLabelConfig={stackLabelSum}
            legend={<Legend verticalAlign="top" adjustChartSize={false} maxHeight={120}/>}/>
        </Grid>)}

        <DataTable
          headLine={headLine}
          dataLines={dataLines}
          footLine={dateTotals}
          fetchDate={fetchDate}
          excelTitle={getString('MENU_REGISTRATIONS')}
          filenamePrefix={filenamePrefix}/>

      </Grid>)
    }
  }
}

RegistrationsPage
  .propTypes = {
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  tabActions: PropTypes.object
}

export default withRouter(RegistrationsPage)
