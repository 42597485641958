import axios from 'axios'
import getString from '../config/strings'
import {
  BOOKING_DATA_URL,
  BOOKING_HOST,
  EPRUSER_DATA_URL,
  EPRUSER_HOST,
  REGISTRATION_REQUEST_DATA_URL,
  REGISTRATION_REQUEST_HOST,
  DNS_DATA_URL,
  DNS_HOST,
  PUSH_DATA_URL,
  PUSH_HOST,
  CHANNEL_DATA_URL,
  NOTIFICATION_TEMPLATES_HOST,
  NOTIFICATION_TEMPLATES_DATA_URL,
  NOTIFICATION_TEMPLATE_CONTEXT,
  JOBS_HOST,
  JOBS_DATA_URL,
  CAMPAIGNS_HOST,
  CAMPAIGNS_DATA_URL,
  ACTIVATION_CODE_HOST,
  ACTIVATION_CODE_DATA_URL
} from '../config/config'
import { get, configGenerator } from 'eqmod-js-axiosservice'
import { formatDateTime } from '../components/helpers/dateHelper'

class APIService {

  constructor (host, data_url) {
    this.base_url = host + data_url
    this.timeouts = { _default: 15000 }
    this.filterParams = { enhanceData: {} }
    this.saveParams = { url: '', allowedStatus: [201], enhanceData: {} }
    this.updateParams = { url: '/', enhanceData: {} }
    this.deleteParams = { url: '/' }
  }

  _configParamsFromTokenAndRequest (token, requestName = null) {
    const timeout = (requestName && this.timeouts[requestName]) ? this.timeouts[requestName] : this.timeouts._default
    return { token: token, timeout: timeout }
  }

  _handleAxiosError (error, reject) {
    const data = error?.response?.data ?? null
    if (data) {
      if (data.error) {
        reject(new Error(data.error))
        return
      }
      if (data.errors) {
        reject(new Error(data.errors.join(', ')))
        return
      }
    }
    reject(error)
  }

  _checkResultData (result, reject) {
    if (result.data.hasOwnProperty('success') && result.data.success === false) {
      reject(new Error(result.data.errors.join(',')))
      return false
    } else {
      return true
    }
  }

  addServiceAndGetString (record) {
    record._service = this
    record._getString = function () { return this._service.getString(this) }
  }

  countAll = (auth, options) => {
    const self = this
    const url = this.base_url + '/_count'
    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'countAll')
    const enhancedOptions = { ...options, ...this.filterParams.enhanceData }

    return new Promise((resolve, reject) => {
      get(url, enhancedOptions, configParams).then((result) => {
        resolve(result.count)
      }).catch(error => self._handleAxiosError(error, reject))
    })
  }

  getAll = (auth, options) => {
    const self = this
    const url = this.base_url
    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'getAll')
    const enhancedOptions = { ...options, ...this.filterParams.enhanceData }

    return new Promise((resolve, reject) => {
      get(url, enhancedOptions, configParams).then((result) => {
        if (result && result.length) {
          result.forEach((record) => self.addServiceAndGetString(record))
        }
        resolve(result)
      }).catch(error => self._handleAxiosError(error, reject))
    })
  }

  async getUnlimited (auth, options) {
    const normalizedOptionsCopy = Object.assign({}, options || {})
    delete normalizedOptionsCopy.offset
    delete normalizedOptionsCopy.max

    const count = await this.countAll(auth, options)
    const pageSize = 100
    const data = []

    while (data.length < count) {
      const options = Object.assign({}, normalizedOptionsCopy, {
        offset: data.length,
        max: Math.min(count - data.length, pageSize)
      })
      data.push(...await this.getAll(auth, options))
    }

    return data
  }

  get (auth, id) {
    const self = this
    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'get')

    return new Promise((resolve, reject) => {
      get(self.base_url + '/' + id, {}, configParams).then((data) => {
        self.addServiceAndGetString(data)
        resolve(data)
      }).catch(error => self._handleAxiosError(error, reject))
    })
  }

  save (auth, record) {
    const self = this

    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'save')
    const config = configGenerator(configParams)
    config.headers['Content-Type'] = 'application/json'
    const enhancedRecord = { ...record, ...this.saveParams.enhanceData }

    return new Promise((resolve, reject) => {
      axios.post(self.base_url + self.saveParams.url, enhancedRecord, config).then((result) => {
        if (self.saveParams.allowedStatus.includes(result.status)) {
          if (self._checkResultData(result, reject)) {
            if (result.data) {
              self.addServiceAndGetString(result.data)
            }
            resolve(result.data)
          }
        } else if (!self._checkResultData(result, reject)) {
          reject(new Error(getString('ERROR_HTTP_COMMUNICATION')))
        }
      }).catch(error => self._handleAxiosError(error, reject))
    })
  }

  update (auth, record) {
    const self = this

    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'update')
    const config = configGenerator(configParams)
    config.headers['Content-Type'] = 'application/json'
    const enhancedRecord = { ...record, ...this.saveParams.enhanceData }

    return new Promise((resolve, reject) => {
      axios.put(self.base_url + self.updateParams.url + record.id, enhancedRecord, config).then((result) => {
        if (result.status === 200) {
          if (self._checkResultData(result, reject)) {
            if (result.data) {
              self.addServiceAndGetString(result.data)
            }
            resolve(result.data)
          }
        } else if (!self._checkResultData(result, reject)) {
          reject(new Error(getString('ERROR_HTTP_COMMUNICATION')))
        }
      }).catch(error => self._handleAxiosError(error, reject))
    })
  }

  remove (auth, id) {
    const self = this

    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'remove')
    const config = configGenerator(configParams)

    return new Promise((resolve, reject) => {
      axios.delete(self.base_url + self.deleteParams.url + id, config).then((result) => {
        if (result.status === 200 || result.status === 204) {
          if (self._checkResultData(result, reject)) {
            resolve(result.data)
          }
        } else if (!self._checkResultData(result, reject)) {
          reject(new Error(getString('ERROR_HTTP_COMMUNICATION')))
        }
      }).catch(error => self._handleAxiosError(error, reject))
    })

  }

  getAllForIds (auth, idList) {
    return this.getAll(auth, { ids: idList.join(',') })
  }

  _join (record, fields, glue) {
    glue = glue || ' '
    let parts = []
    if (fields && fields.length) {
      for (let i = 0; i < fields.length; i++) {
        if (record[fields[i]]) {
          parts.push(record[fields[i]])
        }
      }
    }
    return parts.length ? parts.join(glue) : ''
  }

  getString (record) {
    return 'must be implemented'
  }
}

class SendPushService extends APIService {
  constructor () {
    super(PUSH_HOST, PUSH_DATA_URL)
    this.timeouts.save = 180000
    this.saveParams = { url: '/addPushMessage', allowedStatus: [200], enhanceData: {} }
  }

  getString (record) {
    return this._join(record, ['title'])
  }
}

export const sendPushService = new SendPushService()

class ChannelService extends APIService {
  constructor () {
    super(PUSH_HOST, CHANNEL_DATA_URL)
  }

  getString (record) {
    return record ? ((record.channelName ? record.channelName : '') +
      (record.subscribers ? ' (' + record.subscribers + ')' : '')) : ''
  }
}

export const channelService = new ChannelService()

class BookingService extends APIService {
  constructor () {
    super(BOOKING_HOST, BOOKING_DATA_URL)
    this.saveParams = { url: '/save', allowedStatus: [200, 201], enhanceData: {} }
    this.deleteParams = { url: '/delete/' }
  }

  accept (auth, record) {
    const self = this

    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'accept')
    const config = configGenerator(configParams)
    config.headers['Content-Type'] = 'application/json'

    return new Promise((resolve, reject) => {
      axios.put(self.base_url + '/accept/' + record.id, record, config).then((result) => {
        if (result.status === 200) {
          if (result.data) {
            self.addServiceAndGetString(result.data)
          }
          resolve(result.data)
        } else {
          reject(new Error(getString('ERROR_HTTP_COMMUNICATION')))
        }
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.errors) {
          reject(new Error(error.response.data.errors.join(',')))
        } else {
          reject(error)
        }
      })
    })
  }

  getString (record) {
    return this._join(record, ['email', 'courseName'])
  }
}

export const bookingService = new BookingService()

class EprUserService extends APIService {
  constructor () {
    super(EPRUSER_HOST, EPRUSER_DATA_URL)
    this.deleteParams = { url: '/delete/' }
  }

  addServiceAndGetString (record) {
    super.addServiceAndGetString(record)
    if (record.dateCreated) record.dateCreated = new Date(record.dateCreated)
    if (record.lastAccess) record.lastAccess = new Date(record.lastAccess)
  }

  get (auth, id) {
    throw new Error('getting user is not implemented')
  }

  save (auth, record) {
    throw new Error('saving user is not implemented')
  }

  update (auth, record) {
    throw new Error('updating user is not implemented')
  }

  getString (record) {
    return this._join(record, ['firstname', 'firstname', 'email'])
  }
}

export const eprUserService = new EprUserService()

class RegistrationRequestService extends APIService {
  constructor () {
    super(REGISTRATION_REQUEST_HOST, REGISTRATION_REQUEST_DATA_URL)
    this.saveParams = { url: '/save', allowedStatus: [200, 201], enhanceData: {} }
    this.updateParams = { url: '/update/', enhanceData: {} }
    this.deleteParams = { url: '/delete/' }
  }

  getString (record) {
    return this._join(record, ['email'])
  }

  registrations (auth) {
    const self = this
    let data = { activationCode: 'visit' }
    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'registrations')

    return new Promise((resolve, reject) => {
      get(self.base_url + '/registrations', data, configParams).then((result) => {
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getEmptyRecordImmediately () {
    const record = {
      email: '',
      firstname: '',
      lastname: '',
      companyName: '',
      phone: '',
      allowed: null,
      allowDomain: null,
      denied: null,
      denyDomain: null,
      additionalData: null
    }
    this.addServiceAndGetString(record)
    return record
  }
}

export const registrationRequestService = new RegistrationRequestService()

class DnsEntryService extends APIService {
  constructor () {
    super(DNS_HOST, DNS_DATA_URL)
  }

  getString (record) {
    return this._join(record, ['name'])
  }

  addServiceAndGetString (record) {
    super.addServiceAndGetString(record)
    if (record.start_time) record.start_time = new Date(record.start_time)
    if (record.date_created) record.date_created = new Date(record.date_created)
    if (record.last_updated) record.last_updated = new Date(record.last_updated)
  }
}

export const dnsEntryService = new DnsEntryService()

class NotificationTemplateService extends APIService {
  constructor () {
    super(NOTIFICATION_TEMPLATES_HOST, NOTIFICATION_TEMPLATES_DATA_URL)
    const enhancedData = NOTIFICATION_TEMPLATE_CONTEXT ? { context: NOTIFICATION_TEMPLATE_CONTEXT } : {}
    this.filterParams = { enhanceData: enhancedData }
    this.saveParams = { url: '/save', allowedStatus: [200, 201], enhanceData: enhancedData }
    this.updateParams = { url: '/update/', enhanceData: enhancedData }
    this.deleteParams = { url: '/delete/' }
  }

  getString (record) {
    return this._join(record, ['name', 'subject'], ': ')
  }

  send (auth, record) {
    const self = this

    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'send')
    const config = configGenerator(configParams)
    config.headers['Content-Type'] = 'application/json'

    return new Promise((resolve, reject) => {
      axios.post(self.base_url + '/send', record, config).then((result) => {
        if (result.status === 200) {
          resolve(result.data)
        } else {
          reject(new Error(getString('ERROR_HTTP_COMMUNICATION')))
        }
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.errors) {
          reject(new Error(error.response.data.errors.join(',')))
        } else {
          reject(error)
        }
      })
    })
  }

  getEmptyRecordImmediately () {
    const record = {
      name: '',
      context: NOTIFICATION_TEMPLATE_CONTEXT,
      subject: '',
      template: '',
      defaultFrom: '',
      defaultTo: null,
      defaultReplyTo: ''
    }
    this.addServiceAndGetString(record)
    return record
  }
}

export const notificationTemplateService = new NotificationTemplateService()

class JobService extends APIService {
  constructor () {
    super(JOBS_HOST, JOBS_DATA_URL)
    const enhancedData = NOTIFICATION_TEMPLATE_CONTEXT ? { context: NOTIFICATION_TEMPLATE_CONTEXT } : {}
    this.filterParams = { enhanceData: enhancedData }
    this.saveParams = { url: '/save', allowedStatus: [200, 201], enhanceData: {} }
    this.updateParams = { url: '/update/', enhanceData: {} }
    this.deleteParams = { url: '/delete/' }
  }

  getString (record) {
    return (record && record._campaign ? record._campaign._getString() : '') +
      ': ' +
      (record && record.actionDate ? formatDateTime(record.actionDate) : '')
  }

  addServiceAndGetString (record) {
    record._service = this
    record._getString = () => this._service.getString(this)
    record._notification._getString = () => this.name
    record._campaign._getString = () => this.selectionKey ? this.selectionKey.substring(10) : ''
  }

  getEmptyRecordImmediately () {
    const record = {
      campaignId: null,
      _campaign: {
        id: null,
        selectionKey: ''
      },
      notificationId: null,
      _notification: {
        id: null,
        name: ''
      },
      actionDate: null,
      jobStatus: 'NONE'
    }
    this.addServiceAndGetString(record)
    return record
  }
}

export const jobService = new JobService()

class CampaignService extends APIService {
  constructor () {
    super(CAMPAIGNS_HOST, CAMPAIGNS_DATA_URL)
    this.saveParams = { url: '/save', allowedStatus: [200, 201], enhanceData: {} }
    this.updateParams = { url: '/update/', enhanceData: {} }
    this.deleteParams = { url: '/delete/' }
  }

  getInscriptions = (auth, options) => {
    const self = this
    const data = Object.assign({}, options || {})
    const configParams = this._configParamsFromTokenAndRequest(auth.access_token, 'getInscriptions')

    return new Promise((resolve, reject) => {
      get(self.base_url + '/getInscriptions', data, configParams).then((result) => {
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getString (record) {
    return (record && record._campaignId ? record._campaignId : '') +
      ': ' + (record && record.selectionKey ? record.selectionKey.substring(10) : '')

  }

  addServiceAndGetString (record) {
    record._service = this
    for (const sysMessage of record.sysMessages) {
      const title = sysMessage.actionParams && sysMessage.actionParams.campaignName ? sysMessage.actionParams.campaignName : ''
      if (title === record.selectionKey.substring(10) && sysMessage.id) {
        record._campaignId = sysMessage.id
        break
      }
    }
    record._title = record && record.selectionKey ? record.selectionKey.substring(10) : ''
    record._getString = () => this._service.getString(this)
  }

  getEmptyRecordImmediately () {
    const record = {
      id: null,
      selectionKey: '',
      sysMessages: [],
      _campaignId: '',
      _title: '',
      dateCreated: null,
      lastUpdated: null
    }
    this.addServiceAndGetString(record)
    return record
  }
}

export const campaignService = new CampaignService()

class ActivationCodeService extends APIService {
  constructor () {
    super(ACTIVATION_CODE_HOST, ACTIVATION_CODE_DATA_URL)
    this.saveParams = { url: '/save', allowedStatus: [200, 201], enhanceData: {} }
    this.updateParams = { url: '/update/', enhanceData: {} }
    this.deleteParams = { url: '/delete/' }
  }

  getString (record) {
    return record?.code ?? ''
  }

  getEmptyRecordImmediately () {
    const record = {
      id: null,
      code: '',
      feature: '',
      dateCreated: null,
      lastUpdated: null,
      valid_from: null,
      firstActivateUntil: null,
      activateUntil: null,
      maxClients: -1,
      must_personalized: false,
      only_oneuser: false,
      only_onedevice: false,
      revoked: false,
      registrationTokenLength: 0,
      passwordResetTokenLength: 0,
      allowPasswordReset: false,
      requiresEmail: false,
      invitationSent: null,
      validDaysFromActivation: '',
      valid_until: null,
      publicPathes: '',
      allowedMailDomains: '',
      exposeMailDomains: false,
      additionalData: {},
      primaryCode: null,
    }
    this.addServiceAndGetString(record)
    return record
  }
}

export const activationCodeService = new ActivationCodeService()