import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import { AuthContext } from '../library/pageComponents/AuthContext'
import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'

import getString from '../../config/strings'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  statusField: {
    margin: theme.spacing(1),
    flex: '1 0 30%'
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    paddingBottom: 16
  }
})

function timeout (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

class CampaignDialog extends PureComponent {

  constructor (props) {
    super(props)

    const record = props.record

    this.state = {
      actRecord: Object.assign({}, record),
      isSubmitting: false,
      errors: {
        _title: '',
        _campaignId: ''
      }
    }
  }

  checkField (fieldName, value, error) {
    switch (fieldName) {
      case '_campaignId':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_CAMPAIGN_ID_EXPECTED')
        } else if (value.length < 2) {
          error = getString('ERROR_CAMPAIGN_ID_INVALID')
        }
        break

      case '_title':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_CAMPAIGN_TITLE_EXPECTED')
        } else if (value.length < 2) {
          error = getString('ERROR_CAMPAIGN_TITLE_INVALID')
        }
        break

      default:
    }

    return { sanitizedValue: value, error: error }
  }

  changeField (fieldName, value) {
    const { actRecord, errors } = this.state
    const newRecord = Object.assign({}, actRecord)
    const newErrors = Object.assign({}, errors)
    const changeSet = {}

    const checkResult = this.checkField(fieldName, value, '')
    if (newRecord[fieldName] !== checkResult.sanitizedValue) {
      newRecord[fieldName] = checkResult.sanitizedValue
      changeSet.actRecord = newRecord
    }
    if (newErrors[fieldName] !== checkResult.error) {
      newErrors[fieldName] = checkResult.error
      changeSet.errors = newErrors
    }

    if (!checkResult.error && checkResult.otherFields) {
      changeSet.actRecord = Object.assign(newRecord, checkResult.otherFields)
    }

    if (Object.keys(changeSet).length > 0) this.setState(changeSet)
  }

  handleSave = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { isSubmitting } = this.state
    if (!isSubmitting) {
      this.setState({ isSubmitting: true }, () => {this.internalHandleSave()})
    }
  }

  checkAll () {
    const { actRecord, errors } = this.state
    const newErrors = Object.assign({}, errors)
    const newRecord = Object.assign({}, actRecord)
    let hasErrors = false
    const fieldNames = Object.keys(newErrors)
    for (const fieldName of fieldNames) {
      let fieldValue = newRecord[fieldName]
      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.replace(/(^\s+|\s+$)/g, '')
        newRecord[fieldName] = fieldValue
      }
      let checkResult = this.checkField(fieldName, newRecord[fieldName], '')
      newErrors[fieldName] = checkResult.error
      if (checkResult.error !== '') hasErrors = true
    }
    return [hasErrors, newErrors, newRecord]
  }

  async internalHandleSave () {
    await timeout(200)

    // check all fields again
    const [hasErrors, newErrors, newRecord] = this.checkAll()

    if (!hasErrors) {
      const { saveFunc, closeFunc } = this.props

      newRecord.selectionKey = 'campaign: ' + newRecord._title
      if (newRecord.sysMessages.length === 0) {
        newRecord.sysMessages.push({
          systemAction: 'addOrUpdateDataStore',
          actionParams: {
            path: 'public.features.visitBerlin.campaigns',
            data: newRecord._campaignId,
            campaignName: newRecord._title
          },
          id: newRecord._campaignId
        })
      } else {
        for( const sysMessage of newRecord.sysMessages ) {
          if ( sysMessage.id && sysMessage.systemAction === 'addOrUpdateDataStore' &&
            sysMessage.actionParams && sysMessage.actionParams.path === 'public.features.visitBerlin.campaigns') {
            sysMessage.id = newRecord._campaignId
            sysMessage.actionParams.data = newRecord._campaignId
            sysMessage.actionParams.campaignName = newRecord._title
            break
          }
        }
      }

      let sendResult = await saveFunc(newRecord)
      if (!sendResult) {
        this.setState({ isSubmitting: false })
      } else {
        closeFunc()
      }
    } else {
      console.log('ERROR', 'CampaignDialog.internalHandleSave', newErrors)
      this.setState({ errors: newErrors, isSubmitting: false })
    }
  }

  render () {
    const { classes, action, title, closeFunc } = this.props
    const { isSubmitting, actRecord, errors } = this.state

    const [hasErrors, ,] = this.checkAll()

    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogBackDrop open={isSubmitting}/>

        <form onSubmit={this.handleSave}>
          <DialogTitle id="form-dialog-title" className={classes.headLine}>{title}</DialogTitle>

          <DialogContent className={classes.content} dividers={true}>
            <TextField
              id="_campaignId"
              label={getString('CAMPAIGN_HEADER_ID')}
              required={true}
              autoFocus={true}
              type="text"
              error={errors._campaignId !== ''}
              helperText={errors._campaignId}
              onChange={(event) => {this.changeField('_campaignId', event.target.value)}}
              value={actRecord._campaignId}
              className={classes.formControl}
              style={{ flex: '1 0 98%' }}
            />

            <TextField
              id="_title"
              label={getString('CAMPAIGN_HEADER_TITLE')}
              required={true}
              type="text"
              error={errors._title !== ''}
              helperText={errors._title}
              onChange={(event) => {this.changeField('_title', event.target.value)}}
              value={actRecord._title}
              className={classes.formControl}
              style={{ flex: '1 0 98%' }}
            />
          </DialogContent>

          <DialogActions className={classes.footLine}>
            <CancelButton onClick={closeFunc}/>
            <SubmitButton title={action} disabled={hasErrors}/>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

CampaignDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  saveFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired
}

CampaignDialog.contextType = AuthContext

export default withStyles(styles)(CampaignDialog)