import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'

import getString from '../../config/strings'
import QRCode from '../library/QRCode'

import { APP_SELECTIONS, AUTH_HOST } from '../../config/config'

const useStyles = makeStyles(theme => ({
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  },
  formControl: {
    margin: theme.spacing(1),
  }
}))

const normalizeWidthPercent = (configValue) => {
  const DEFAULT_PERCENT = 0.33

  if (configValue) {
    let percentValue
    if (typeof configValue === 'string') {
      percentValue = Number.parseFloat(configValue)
      if (Number.isNaN(percentValue)) {
        return DEFAULT_PERCENT
      }
    } else if (typeof configValue !== 'number') {
      return DEFAULT_PERCENT
    } else {
      percentValue = configValue
    }
    if (percentValue > 1) {
      percentValue = percentValue / 100.0
    }
    return (percentValue > 1) ? DEFAULT_PERCENT : percentValue
  } else {
    return DEFAULT_PERCENT
  }
}

const ActivationCodeQrDialog = ({ record, closeFunc, title, action }) => {
  const hasSelection = APP_SELECTIONS.length > 1
  const defaultAppName = APP_SELECTIONS.length > 0 ? APP_SELECTIONS[0].id : ''
  const defaultLogoConfig = APP_SELECTIONS.length > 0 && APP_SELECTIONS[0].qrLogo ? Object.assign({}, APP_SELECTIONS[0].qrLogo) : null
  if (defaultLogoConfig) {
    defaultLogoConfig.widthPercent = normalizeWidthPercent(defaultLogoConfig.widthPercent)
  }

  const classes = useStyles()

  const [appName, setAppName] = useState(defaultAppName)
  const [logoConfig, setLogoConfig] = useState(defaultLogoConfig)
  const [includeLogo, setIncludeLogo] = useState(defaultLogoConfig !== null)

  const changeAppName = (event) => {
    let givenAppName = event.target.value
    if (givenAppName === null || typeof givenAppName === 'undefined') givenAppName = defaultAppName
    const configIndex = APP_SELECTIONS.findIndex(it => it.id === givenAppName)
    if (configIndex === -1) {
      if (appName !== defaultAppName) {
        setAppName(defaultAppName)
        setLogoConfig(defaultLogoConfig)
      }
    } else {
      if (appName !== givenAppName) {
        setAppName(givenAppName)
        const givenConfig = Object.assign({}, APP_SELECTIONS[configIndex].qrLogo) || null
        if (givenConfig) givenConfig.widthPercent = normalizeWidthPercent(givenConfig.widthPercent)
        setLogoConfig(givenConfig)
      }
    }
  }

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
      <DialogBackDrop open={false}/>

      <form>
        <DialogTitle id="form-dialog-title" className={classes.headLine}>{title}</DialogTitle>

        <DialogContent style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'stretch' }}
                       dividers={true}>

          <TextField
            id="appName"
            label={getString('QRCODE_LABEL_APPNAME')}
            select={hasSelection}
            onChange={changeAppName}
            value={appName}
            readOnly={!hasSelection}
            disabled={!hasSelection}
            style={{ flex: '1 0 65%' }}
            className={classes.formControl}
          >
            {hasSelection && APP_SELECTIONS.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {getString(option.labelCode)}
              </MenuItem>
            ))}
          </TextField>
          {logoConfig !== null &&
          <FormControl component="div" className={classes.formControl} style={{ flex: '1 0 30%', paddingTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={includeLogo}
                  onChange={(event) => {setIncludeLogo(event.target.checked)}}
                  value="true"
                  color="primary"
                />
              }
              label={getString('SHOW_APP_LOGO')}
            />
          </FormControl>
          }
          <QRCode content={AUTH_HOST + '/appRedirect/' + appName + '?id=' + (record?._configMessage?.id ?? '')}
                  title={record.code}
                  filename={'qrcode-' + record.code}
                  id={record?._configMessage?.id ?? record.code}
                  type={'plainlink'}
                  logoConfig={includeLogo ? logoConfig : null}
          />
        </DialogContent>

        <DialogActions className={classes.footLine}>
          <Button variant="contained" size="small" onClick={closeFunc} color="primary">
            {getString(action)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ActivationCodeQrDialog.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired
}

export default memo(ActivationCodeQrDialog)
