import { dateLocale } from '../../config/strings'
import moment from 'moment'

export const formatDate = (date) => {
  return date ? moment(new Date(date)).locale(dateLocale()).format('L') : ''
}

export const formatDateTime = (date) => {
  return date ? moment(new Date(date)).locale(dateLocale()).format('L LT') : ''
}

export const formatDateTimeSeconds = (date) => {
  return date ? moment(new Date(date)).locale(dateLocale()).format('L LTS') : ''
}

export const formatTime = (date) => {
  return date ? moment(new Date(date)).locale(dateLocale()).format('LT') : ''
}