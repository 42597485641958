import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  }
}))

export const EditButton = (props) => {
  const { color, record, editFunc, index, icon, ...restProps } = props
  const classes = useStyles()

  return (<IconButton color={color}
                      aria-label="edit"
                      className={classes.button}
                      size='small'
                      onClick={() => {editFunc(index)}}
                      {...restProps}>
    {React.createElement(icon)}
  </IconButton>)
}

EditButton.propTypes = {
  color: PropTypes.string,
  record: PropTypes.object.isRequired,
  editFunc: PropTypes.func.isRequired,
  icon: PropTypes.object,
  index: PropTypes.number
}

EditButton.defaultProps = {
  icon: EditIcon,
  color: 'primary'
}

export const ViewButton = (props) => {
  const { record, editFunc, index, ...restProps } = props

  return (<EditButton record={record} editFunc={editFunc} index={index} icon={VisibilityIcon} {...restProps} />)
}

ViewButton.propTypes = {
  record: PropTypes.object.isRequired,
  editFunc: PropTypes.func.isRequired,
  index: PropTypes.number
}

export const MessageButton = (props) => {
  const { record, editFunc, index, ...restProps } = props

  return (<EditButton record={record} editFunc={editFunc} index={index} icon={MailOutlineIcon} {...restProps} />)
}

MessageButton.propTypes = {
  record: PropTypes.object.isRequired,
  editFunc: PropTypes.func.isRequired,
  index: PropTypes.number
}

const enhanceEditButton = (editFunc) => {
  return React.memo((props) => {
    return <EditButton {...Object.assign({}, props, { editFunc: editFunc })} />
  })
}

export default enhanceEditButton