import React from 'react'
import { Chart, ColumnSeries, HighchartsChart, HighchartsProvider, XAxis, YAxis } from 'react-jsx-highcharts'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'

const AnswersBarChart = ({ voting, showAbsolute }) => {
  const renderData = voting['votingAnswers'] ? voting['votingAnswers'].slice() : []
  const xAxisData = []
  const series = []

  renderData.forEach(entry => {
    xAxisData.push(entry.text)
    series.push(showAbsolute ? entry.count : entry.percent)
  })

  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart>
        <Chart/>
        <XAxis categories={xAxisData}/>
        <YAxis allowDecimals={!showAbsolute} max={showAbsolute ? null : 100}>
          <YAxis.Title>{showAbsolute ? 'Stimmen' : 'Prozent'}</YAxis.Title>
          <ColumnSeries dataLabels={{ enabled: true, style: { fontSize: '15px' } }} data={series}/>
        </YAxis>
      </HighchartsChart>
    </HighchartsProvider>
  )
}

AnswersBarChart.propTypes = {
  voting: PropTypes.object.isRequired,
  showAbsolute: PropTypes.bool.isRequired
}

export default React.memo(AnswersBarChart)
