import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import getString from '../../config/strings'

import { showAlert } from 'eqmod-react-alert'

import { AuthContext } from '../library/pageComponents/AuthContext'
import FilterField from '../library/pageComponents/FilterField'
import WaitForLoad from '../library/pageComponents/WaitForLoad'
import enhanceEditButton from '../library/tableComponents/EditButton'
import enhanceDeleteButton from '../library/tableComponents/DeleteButton'
import TableEnhanced from '../library/tableComponents/TableEnhanced'
import { deferredReload, addSubscription } from './helpers/pageHelper'

import CampaignDialog from '../dialogs/CampaignDialog'

import { campaignService } from '../../services/dataService'
import ExcelIcon from '../icons/ExcelIcon'
import zipcelx from 'zipcelx'

const headCells = [
  { id: '0', disablePadding: true, canBeSorted: false, label: '\u00A0' },
  { id: '_campaignId', canBeSorted: false, label: getString('CAMPAIGN_HEADER_ID') },
  { id: 'selectionKey', canBeSorted: true, label: getString('CAMPAIGN_HEADER_TITLE') },
  { id: '_subscribers', canBeSorted: false, label: getString('CAMPAIGN_HEADER_SUBSCRIBERS') },
  { id: '4', disablePadding: true, canBeSorted: false, label: '\u00A0' }
]

const bodyCells = [
  { id: '0', align: 'center', disablePadding: true, type: 'render' },
  { id: '_campaignId', type: 'data', value: record => record._campaignId },
  { id: 'selectionKey', type: 'data', value: record => record._title },
  { id: '_subscribers', type: 'data', value: record => record._subscribers || '\u00A0' },
  { id: '4', align: 'center', disablePadding: true, type: 'render' }
]

const labelSelectors = {
  zeroFiltered: 'CAMPAIGN_ZERO_FILTERED',
  zero: 'CAMPAIGN_ZERO',
  deleteHeader: 'CAMPAIGN_DELETE_HEADER',
  deleteText: 'CAMPAIGN_DELETE_TEXT'
}

const enhanceRecords = (records, inscriptions) => {
  if (!records || !Array.isArray(records)) return records

  return records.map(record => {
    if (record._campaignId && inscriptions.hasOwnProperty(record._campaignId)) {
      record._subscribers = inscriptions[record._campaignId].length
    }
    return record
  })
}

class CampaignsPage extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      actRecord: null,

      records: [],
      filter: '',
      page: 0,
      rowsPerPage: 10,
      count: 0,
      order: { column: 'selectionKey', direction: 'asc' },

      inscriptions: {},

      isLoading: false
    }

    this.head = React.createRef()
    this.deferredTimeout = null
    this.subscription = null
    this.isCancelled = false

    bodyCells[0].value = enhanceEditButton((index) => {this.openDialog(index)})
    bodyCells[4].value = enhanceDeleteButton((index) => {this.deleteRecord(index)}, labelSelectors)
  }

  async reload () {
    const { page, rowsPerPage, order } = this.state
    await this.load(page, rowsPerPage, order.column, order.direction)
  }

  async getInscriptions () {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        return
      }

      const inscriptions = await campaignService.getInscriptions(newAuth, {})
      const { records } = this.state
      this.setState({ inscriptions: inscriptions, records: enhanceRecords(records, inscriptions) })
    } catch (error) {
      this.setState({ isLoading: false })
      console.error('ERROR', 'CampaignsPage.getInscriptions', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  componentDidMount () {
    this.reload()
    this.getInscriptions()
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const { filter } = this.state
    if (prevState.filter !== filter) {
      deferredReload(this)
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async load (page, rowsPerPage, sortColumn, orderDirection) {
    const { filter } = this.state
    this.setState({ isLoading: true })

    addSubscription(this)

    try {
      if (this.isCancelled) return

      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        this.setState({ isLoading: false })
        return
      }
      if (this.isCancelled) return

      let count = await campaignService.countAll(newAuth, { filter: filter })
      if (this.isCancelled) return

      let start = page * rowsPerPage
      if (start > count) {
        start = 0
        page = 0
      }
      if (this.isCancelled) return

      let data = []
      if (count > 0) {
        data = await campaignService.getAll(newAuth, {
          offset: start,
          max: rowsPerPage,
          filter: filter,
          sort: sortColumn,
          order: orderDirection
        })
        if (this.isCancelled) return
      }

      const { inscriptions } = this.state
      this.setState({
        records: enhanceRecords(data, inscriptions),
        page: page,
        rowsPerPage: rowsPerPage,
        order: { column: sortColumn, direction: orderDirection },
        count: count,
        isLoading: false
      })

    } catch (error) {
      this.subscription = null
      if (this.isCancelled) return

      this.setState({ isLoading: false })
      console.error('ERROR', 'CampaignsPage.reload', error)
      if (!error.message.startsWith('isCancelled'))
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  openDialog = (index) => {
    const { records } = this.state
    const emptyRecord = campaignService.getEmptyRecordImmediately()

    if (index !== -1 && records[index]) {
      // copy record
      let record = Object.assign({}, records[index])
      // ensure all fields are initialized
      Object.keys(emptyRecord).forEach(fieldName => {
        if (record[fieldName] === null) record[fieldName] = emptyRecord[fieldName]
      })
      this.setState({ actRecord: record })
    } else {
      // init with empty Record
      this.setState({ actRecord: emptyRecord })
    }
  }

  closeDialog = () => {
    this.setState({ actRecord: null })
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  async saveRecord (record) {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) return null

      let data

      if (!record.id) {
        data = await campaignService.save(newAuth, record)
      } else {
        data = await campaignService.update(newAuth, record)
      }

      await this.reload()
      return data

    } catch (error) {
      console.error('ERROR', 'CampaignsPage.saveRecord save job', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return null
    }
  }

  async deleteRecord (index) {
    const { records } = this.state
    const record = (records[index]) ? Object.assign({}, records[index]) : null

    if (record) {
      this.setState({ isLoading: true })
      try {
        const { refreshAuth } = this.context
        const newAuth = await refreshAuth()
        if (newAuth === null) {
          this.setState({ isLoading: false })
          return
        }

        await campaignService.remove(newAuth, record.id)
        await this.reload()
      } catch (error) {
        this.setState({ isLoading: false })
        console.error('ERROR', 'CampaignsPage.deleteRecord', error)
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      }
    }
  }

  handleInscriptionsDownload = async event => {
    event.preventDefault()
    event.stopPropagation()

    const { inscriptions } = this.state

    if ( Object.keys(inscriptions).length === 0 )  return

    try {
      let headLine = [
        'Kampagne',
        'email'
      ]

      let config = {
        filename: 'Abonnenten-' + new Date().toJSON().substring(0, 10),
        sheet: {
          data: []
        }
      }

      // headline
      config.sheet.data.push(headLine.map(header => {
        return { value: header, type: 'string' }
      }))

      // body
      for ( const campaignId of Object.keys(inscriptions) )  {
        for ( const email of inscriptions[campaignId] ) {
          config.sheet.data.push([{ value: campaignId, type: 'string' }, { value: email, type: 'string' }])
        }
        config.sheet.data.push([])
        config.sheet.data.push([])
      }

      zipcelx(config)

    } catch (error) {
      console.error('ERROR', 'CampaignsPage.handleInscriptionsDownload', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  renderHeader () {
    const { filter } = this.state

    return (
      <React.Fragment>
        <Grid item container xs={12} sm={6} justifyContent="flex-start" alignItems="flex-end">
          <div>
            <Button variant="contained" color="primary" onClick={() => this.openDialog(-1)}
                    startIcon={<AddIcon/>}>
              {getString('CAMPAIGN_ADD_BUTTON')}
            </Button>
          </div>
        </Grid>

        <Grid item container xs={12} sm={6} justifyContent="flex-end" alignItems="flex-end" ref={this.head}>
          <FilterField value={filter} handleFilterChange={this.handleFilterChange}/>
        </Grid>
      </React.Fragment>
    )
  }

  renderBody () {
    const { actRecord, count, filter, order, page, records, rowsPerPage, inscriptions } = this.state
    const dialogOpen = actRecord !== null
    const hasInscriptions = Object.keys(inscriptions).length > 0

    return (
      <React.Fragment>
        {hasInscriptions && <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button startIcon={<ExcelIcon style={{ color: '#1f7144' }}/>} onClick={this.handleInscriptionsDownload}
                  variant="contained" style={{ marginLeft: 16 }}>
            {getString('CAMPAIGN_SUBSCRIPTION_DOWNLOAD_USERS')}
          </Button>
        </Grid>}

        <Grid item xs={12}>
          <TableEnhanced records={records}
                         reloadFunc={(newPage, newRowsPerPage, newSortColumn, newOrderDirection) => {this.load(newPage, newRowsPerPage, newSortColumn, newOrderDirection)}}
                         page={page}
                         rowsPerPage={rowsPerPage}
                         sortColumn={order.column}
                         orderDirection={order.direction}
                         count={count}
                         isFiltered={filter !== ''}
                         headCells={headCells}
                         bodyCells={bodyCells}
                         labelSelectors={labelSelectors}/>
          {dialogOpen &&
          <CampaignDialog
            title={getString(actRecord.id ? 'CAMPAIGN_EDIT_HEADER' : 'CAMPAIGN_ADD_BUTTON')}
            action={getString(actRecord.id ? 'CAMPAIGN_UPDATE_BUTTON' : 'CAMPAIGN_CREATE_BUTTON')}
            closeFunc={this.closeDialog}
            saveFunc={(record) => {return this.saveRecord(record)}}
            record={actRecord}
          />}
        </Grid>
      </React.Fragment>
    )
  }

  render () {
    const { isLoading } = this.state
    const top = this.head.current ? this.head.current.getBoundingClientRect().bottom : 0
    return (
      <Grid container spacing={3} style={{ width: '100%', margin: 0, marginTop: 12, paddingBottom: 16 }}>
        {this.renderHeader()}

        {isLoading && <WaitForLoad top={top}/>}
        {this.renderBody()}
      </Grid>
    )
  }
}

CampaignsPage.propTypes = {
  tabActions: PropTypes.object
}

CampaignsPage.contextType = AuthContext

export default CampaignsPage