import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'

import getString from '../../../config/strings'

const useStyles = makeStyles(theme => ({
  textField: {
    flexBasis: '100%',
    [theme.breakpoints.only('xs')]: {
      maxWidth: 600,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 450,
    }
  }
}))

const FilterField = ({ value, handleFilterChange }) => {
  const classes = useStyles()

  return (<FormControl className={classes.textField}>
    <InputLabel htmlFor="filterField">{getString('ASSIGNMENT_LABEL_FILTER')}</InputLabel>
    <Input
      id="filterField"
      type='text'
      value={value}
      onChange={(event) => {handleFilterChange(event.target.value)}}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label={getString('ASSIGNMENT_DELETE_FILTER')}
            onClick={() => {handleFilterChange('')}}
          >
            <ClearIcon/>
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>)
}

FilterField.propTypes = {
  value: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired
}

export default React.memo(FilterField)
