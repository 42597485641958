import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Highcharts from 'highcharts'

import applyExporting from 'highcharts/modules/exporting'
import applyOffline from 'highcharts/modules/offline-exporting'
import applyDrilldown from 'highcharts/modules/drilldown'
import applyDebugger from 'highcharts/modules/debugger'
import applyAdaptChartToLegend from 'highcharts-adapt-chart-to-legend'

import {
  Chart,
  ColumnSeries,
  HighchartsChart,
  HighchartsProvider,
  Title,
  Tooltip,
  XAxis,
  YAxis
} from 'react-jsx-highcharts'

applyExporting(Highcharts)
applyOffline(Highcharts)
applyDrilldown(Highcharts)
applyDebugger(Highcharts)
applyAdaptChartToLegend(Highcharts)

export const stackLabelSum = {
  enabled: true,
  align: 'center',
  // rotation: 90,
  formatter: function () {
    let sum = 0

    this.axis.series.forEach(series => {
      if (series.visible && series.options.stacking === 'normal')
        sum += series.yData[this.x]
    })
    if (this.total > 0) {
      return Highcharts.numberFormat(sum, 0)
    } else {
      return ''
    }
  }
}

const plotDrillOptions = {
  column: {
    stacking: 'normal',
    cropThreshold: 1000, // normal 50
    dataLabels: {
      enabled: false
    },
    borderWidth: 0
  },
  series: {
    turboThreshold: 10000,
    events: {
      legendItemClick: function (e) {
        // if self is visible,
        const name = this.name
        if (this.visible) {
          // invert visibility of others
          this.chart.series.forEach(series => {
            if (series.name !== name) {
              if (series.visible)
                series.hide()
              else
                series.show()
            }
          })
        } else {
          // show myself, hide the others
          this.chart.series.forEach(series => {
            if (series.name === name)
              series.show()
            else
              series.hide()
          })
        }
        return false
      }
    }
  }
}

/*
 1. Nehme statt einer Hierarchie eine Array von Arrays ohne Feldnamen, und stecke diese Infos in die Description
 2. Erlaube von einem Punkt ein beliebiges Drilldown aus der Liste der noch nicht verwendten Merkmale

  console.log('DrillDownChart.getSeries', JSON.stringify({
    actDepth,
    maxDepth,
    xOptions: xOptions,
    yOptions: yOptions,
    depthStack: depthStack,
    data: data
  }))

 */

const maxKey = (object) => parseInt(Object.keys(object).sort().pop())

export function getSeries (seriesBase, colors, depthStack) {
  let { description, data } = seriesBase

  const maxDepth = maxKey(description)
  const actDepth = maxKey(depthStack)
  const xOptions = description[actDepth]
  const yOptions = description[actDepth + 1]

  for (let depth = 0; depth < actDepth; depth++) {
    data = data[depthStack[depth].value]
  }

  const yKeys = new Set()
  for (const values of Object.values(data)) {
    if (typeof values === 'object') {
      for (const yKey of Object.keys(values)) yKeys.add(yKey)
    }
  }

  const allSeries = []

  if (yKeys.size === 0 || (yKeys.size === 1 && [...yKeys][0] === '_')) {
    const value = depthStack[actDepth - 1].value
    const name = ((description[actDepth - 1].keyTranslation && description[actDepth - 1].keyTranslation[value]) ? description[actDepth - 1].keyTranslation[value] : value)
    const series = {
      name: name,
      id: value,
      colorByPoint: true,
      type: 'column',
      data: xOptions.order.filter(yKey => data.hasOwnProperty(yKey)).map((yKey) => {
        return {
          name: ((xOptions.keyTranslation && xOptions.keyTranslation[yKey]) ? xOptions.keyTranslation[yKey] : yKey),
          y: (data[yKey] && typeof data[yKey] === 'object') ? (data[yKey]['_'] || 0) : (data[yKey] || 0)
        }
      })
    }
    allSeries.push(series)
  } else {

    const drillDown = yOptions && yOptions.hasOwnProperty('level') ? yOptions.level : actDepth < maxDepth

    yOptions.order.filter(yKey => yKeys.has(yKey)).forEach((yKey) => {
      const series = {
        name: ((yOptions.keyTranslation && yOptions.keyTranslation[yKey]) ? yOptions.keyTranslation[yKey] : yKey),
        id: yKey,
        type: xOptions.type ? xOptions.type : 'column',
        custom: { drillUpText: yOptions.drillUpText },
        data: xOptions.order.filter(xKey => data.hasOwnProperty(xKey)).map((xKey) => ({
          id: xKey,
          name: ((xOptions.keyTranslation && xOptions.keyTranslation[xKey]) ? xOptions.keyTranslation[xKey] : xKey),
          y: data[xKey] && data[xKey][yKey] ? (typeof data[xKey][yKey] === 'object' && data[xKey][yKey].hasOwnProperty('_') ? data[xKey][yKey]['_'] : data[xKey][yKey]) : 0,
          drilldown: drillDown
        }))
      }

      if (actDepth === 0) {
        series.colorByPoint = true
      } else if (colors && colors.length) {
        series.color = colors[allSeries.length % colors.length]
      }
      allSeries.push(series)
    })
  }

  return allSeries
}

class DrillDownChart extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {}
    this.colors = ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1']
    this.depthStack = { 0: { value: null } }
    Highcharts.setOptions({ lang: { drillUpText: '◁ Back to {series.custom.drillUpText}' } })
  }

  drillDown = (e) => {
    if (!e.seriesOptions) {
      const chart = e.point.series.chart
      const xKey = e.point.id
      const xName = e.point.name

      // update depth stack
      const actDepth = maxKey(this.depthStack)
      this.depthStack[actDepth].value = xKey

      // calculate title from description
      const { seriesBase } = this.props

      // increase stack level
      const nextDepth = actDepth + 1
      const nextLevelDescription = seriesBase.description[nextDepth]
      const title = nextLevelDescription.title.replace(/{{PLACEHOLDER}}/g, xName)
      const actTitle = chart.title.textStr || ''
      this.depthStack[nextDepth] = {
        value: null,
        title: actTitle ? (actTitle + ' ⇨ ' + title) : title
      }

      // calculate series
      const series = getSeries(seriesBase, this.colors, this.depthStack)

      // set title
      chart.setTitle({ text: this.depthStack[nextDepth].title })

      // apply calculated series
      if (series.length === 1) {
        chart.addSeriesAsDrilldown(e.point, series[0])
      } else {
        series.forEach(series => {
          chart.addSingleSeriesAsDrilldown(e.point, series)
        })
        chart.applyDrilldown()
      }
    }
  }

  drillUpAll = (e) => {
    const chart = e.target

    let actDepth = maxKey(this.depthStack)

    // decrease stack level
    delete this.depthStack[actDepth]
    actDepth--
    this.depthStack[actDepth].value = null

    // reset title
    chart.setTitle({ text: this.depthStack[actDepth].title || '' })
  }

  render () {
    const { seriesBase, plotOptions, title, legend, stackLabelConfig } = this.props
    const series = getSeries(seriesBase, this.colors, this.depthStack)

    return (
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart plotOptions={plotOptions} displayErrors={true}>
          <Chart zoomType="x" type="column" onDrilldown={this.drillDown} onDrillupall={this.drillUpAll} height={400}/>

          <Title>{title}</Title>

          {legend}

          <Tooltip/>

          <XAxis type="category"/>

          <YAxis min={0} stackLabels={stackLabelConfig}>
            {series.map(column => <ColumnSeries id={column.id} key={column.id} name={column.name}
                                                data={column.data} custom={column.custom}/>)}
          </YAxis>
        </HighchartsChart>
      </HighchartsProvider>
    )
  }
}

DrillDownChart.propTypes = {
  title: PropTypes.string.isRequired,
  seriesBase: PropTypes.object.isRequired,
  plotOptions: PropTypes.object.isRequired,
  legend: PropTypes.object,
  stackLabelConfig: PropTypes.object
}

DrillDownChart.defaultProps = {
  plotOptions: plotDrillOptions,
  stackLabelConfig: stackLabelSum
}

export default DrillDownChart
