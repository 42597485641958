import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SwipeableViews from 'react-swipeable-views'

import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'
import OptionsPanel from './OptionsPanel'
import SingleSuggest from './fields/autosuggest/SingleSuggest'
import getString, { target } from '../../config/strings'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

const formatDate = (string) => {
  console.log('INFO', 'PushSendDialog.formatDate', 'sentDate', string)

  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L LT') : ''
}

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  },
  tabSelected: {
    backgroundColor: '#f5f5f5'
  }
})

function timeout (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function getTileString () {
  return this.id + ': ' + this.title
}

const FilterAppComponent = ({ readOnly, actRecord, changeField, hiddenFields }) => {
  if (readOnly) return null

  const showOldApp = hiddenFields.indexOf('oldApp') === -1
  const showNewApp = hiddenFields.indexOf('newApp') === -1
  const showYep2020 = hiddenFields.indexOf('yep2020') === -1
  const showFkt2020 = hiddenFields.indexOf('fkt2020') === -1
  const showEmployees = hiddenFields.indexOf('employees') === -1
  const showPublicUsers = hiddenFields.indexOf('publicUsers') === -1

  if (!showOldApp && !showNewApp) return null

  return (
    <div style={{ flex: '1 0 60%', height: 244 }}>
      {showOldApp &&
        <FormControlLabel
          control={
            <Checkbox
              checked={actRecord.oldApp}
              onChange={(event) => {changeField('oldApp', event.target.checked)}}
              size="small"
              name="oldApp"
              color="primary"
            />
          }
          style={{ display: 'flex', flex: '1 0 80%', marginTop: 8 }}
          label="alte App"
        />}

      {(showOldApp && actRecord.oldApp && (showYep2020 || showFkt2020)) &&
        <div style={{ paddingLeft: '10%' }}>
          {showYep2020 &&
            <FormControlLabel
              control={
                <Checkbox
                  checked={actRecord.yep2020}
                  onChange={(event) => {changeField('yep2020', event.target.checked)}}
                  size="small"
                  name="yep2020"
                  color="primary"
                />
              }
              label="nur Yep Besucher"
            />}

          {showFkt2020 &&
            <FormControlLabel
              control={
                <Checkbox
                  checked={actRecord.fkt2020}
                  onChange={(event) => {changeField('fkt2020', event.target.checked)}}
                  size="small"
                  name="fkt2020"
                  color="primary"
                />
              }
              label="nur FKT Besucher"
            />}
        </div>}

      {showNewApp &&
        <FormControlLabel
          control={
            <Checkbox
              checked={actRecord.newApp}
              onChange={(event) => {changeField('newApp', event.target.checked)}}
              size="small"
              name="newApp"
              color="primary"
            />
          }
          style={{ display: 'flex', flex: '1 0 80%', marginTop: 8 }}
          label="neue App"
        />}

      {(showNewApp && actRecord.newApp && showPublicUsers) &&
        <div style={{ paddingLeft: '10%', flex: '1 0 80%' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={actRecord.publicUsers}
                onChange={(event) => {changeField('publicUsers', event.target.checked)}}
                size="small"
                name="publicUsers"
                color="primary"
              />
            }
            label="öffentliche Nutzer"
          />
        </div>}

      {(showNewApp && actRecord.newApp && showEmployees) &&
        <div style={{ paddingLeft: '10%', flex: '1 0 80%' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={actRecord.employees}
                onChange={(event) => {changeField('employees', event.target.checked)}}
                size="small"
                name="employees"
                color="primary"
              />
            }
            label="nur Mitarbeiter"
          />

          {(showNewApp && actRecord.employees && (showYep2020 || showFkt2020)) &&
            <div style={{ paddingLeft: '10%' }}>
              {showYep2020 &&
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actRecord.yep2020}
                      onChange={(event) => {changeField('yep2020', event.target.checked)}}
                      size="small"
                      name="yep2020"
                      color="primary"
                    />
                  }
                  label="nur Yep Besucher"
                />}

              {showFkt2020 &&
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actRecord.fkt2020}
                      onChange={(event) => {changeField('fkt2020', event.target.checked)}}
                      size="small"
                      name="fkt2020"
                      color="primary"
                    />
                  }
                  label="nur FKT Besucher"
                />}
            </div>}
        </div>}

    </div>
  )
}

class PushSendDialog extends PureComponent {

  constructor (props) {
    super(props)
    console.log('INFO', 'PushSendDialog.constructor', JSON.stringify(props.record), JSON.stringify(props.dialogConfig))
    this.state = {
      actRecord: Object.assign({ limitChannels: '' }, props.record),
      handleSave: false,
      chosenTab: 0,
      channels: [],
      tiles: {},
      tileList: [],
      readOnly: Boolean(props.record.clients || props.record.errors),
      errors: {
        title: '',
        message: '',
        data: '',
        appFilter: '',
        oldApp: '',
        yep2020: '',
        fkt2020: '',
        newApp: '',
        publicUsers: '',
        employees: '',
        requestCodes: '',
        limitChannels: ''
      }
    }
  }

  componentDidMount () {
    let self = this
    const { dialogConfig, getTiles, getChannels } = this.props
    const hiddenFields = dialogConfig !== null && dialogConfig.hideFields && Array.isArray(dialogConfig.hideFields)
      ? dialogConfig.hideFields
      : []

    if (hiddenFields.indexOf('tile') === -1) {
      getTiles()
        .then(function (tiles) {
          const tileList = Object.values(tiles)
            .filter(tile => tile.type === 'lecture')
            .map(tile => ({
              id: tile.id,
              title: tile.title,
              _getString: getTileString
            }))
          tileList.sort((b, a) => {return a.id < b.id ? -1 : (a.id > b.id) ? 1 : 0})
          self.setState({ tiles: tiles, tileList: tileList })
        })
        .catch(function (e) {console.error('ERROR', 'PushSendDialog.componentDidMount', 'getTiles', e)})
    }

    if (hiddenFields.indexOf('limitChannels') === -1) {
      getChannels().then(function (channels) {
        self.setState({ channels: channels })
      })
        .catch(function (e) {console.error('ERROR', 'PushSendDialog.componentDidMount', 'getChannels', e)})
    }
  }

  translateData (data) {
    if (!data) return ''
    let dataTranslated
    try { dataTranslated = JSON.parse(data) } catch (e) { dataTranslated = null }
    if (!dataTranslated) return ''
    if (dataTranslated['systemAction'] && dataTranslated['systemAction'] === 'navigateTo') {
      if (dataTranslated['actionParams'] && dataTranslated['actionParams']['id']) {
        const tileId = dataTranslated['actionParams']['id']
        if (Object.keys(this.state.tiles).length === 0) return ''
        if (!this.state.tiles[tileId]) return ''
        const tileDescription = this.state.tiles[tileId]
        return getTileString.call(tileDescription)
      }
    }
    return ''
  }

  transformToTile (data) {
    if (!data) return null
    let dataTranslated
    try {
      dataTranslated = (typeof data === 'string') ? JSON.parse(data) : Object.assign({}, data)
    } catch (e) {
      dataTranslated = null
    }
    if (!dataTranslated) return null
    if (dataTranslated.id && dataTranslated.title && dataTranslated._getString) return dataTranslated
    if (dataTranslated['systemAction'] && dataTranslated['systemAction'] === 'navigateTo') {
      if (dataTranslated['actionParams'] && dataTranslated['actionParams']['id']) {
        const tileId = dataTranslated['actionParams']['id']
        if (Object.keys(this.state.tiles).length === 0) return null
        if (!this.state.tiles[tileId]) return null
        const tileDescription = this.state.tiles[tileId]
        return { id: tileId, title: tileDescription.title, _getString: getTileString }
      }
    }
    return null
  }

  checkField (fieldName, value, error) {
    console.log('checkfield', fieldName, value, typeof value, error)

    switch (fieldName) {
      case 'title':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')

        if (value.length > 100) {
          error = getString('ERROR_PUSH_TITLE_TOOLONG')
        }
        break

      case 'message':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')

        if (value === '') {
          error = getString('ERROR_PUSH_MESSAGE_EMPTY')
        } else if (value.length > 200) {
          error = getString('ERROR_PUSH_MESSAGE_TOOLONG')
        }
        break

      case 'data':
        break

      case 'appFilter':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value) {
          let parts = value.split(/[,\s]+/)
          for (let part of parts) {
            if (!part.match(/[0-9abcdef]{12}/i)) {
              error = getString('ERROR_PUSH_APPFILTERWRONG')
              break
            }
          }
        }
        break

      case 'requestCodes':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value) {
          let parts = value.split(/[,\s]+/)
          for (let part of parts) {
            if (!part.match(/^[0123456789a-zA-ZäöüßÄÖÜ_][0123456789a-zA-ZäöüßÄÖÜ _-]{1,38}[0123456789a-zA-ZäöüßÄÖÜ_]$/)) {
              error = getString('ERROR_PUSH_REQUESTCODESWRONG')
              break
            }
          }
        }
        break

      default:
    }
    return { sanitizedValue: value, error: error }
  }

  changeField (fieldName, value) {
    let newRecord = Object.assign({}, this.state.actRecord)
    let errors = Object.assign({}, this.state.errors)
    let changeSet = {}

    let checkResult = this.checkField(fieldName, value, '')
    if (newRecord[fieldName] !== checkResult.sanitizedValue) {
      newRecord[fieldName] = checkResult.sanitizedValue
      changeSet.actRecord = newRecord
    }
    if (errors[fieldName] !== checkResult.error) {
      errors[fieldName] = checkResult.error
      changeSet.errors = errors
    }

    if (Object.keys(changeSet).length > 0) this.setState(changeSet)
  }

  handleSave (event) {
    event.preventDefault()

    if (!this.state.handleSave) {
      if (this.state.readOnly) {
        this.props.closeFunc(event)
      } else {
        this.setState({ handleSave: true }, () => {this.internalHandleSave()})
      }
    }
  }

  async internalHandleSave () {
    await timeout(200)

    // check all checkable fields again
    let errors = Object.assign({}, this.state.errors)
    let hasErrors = false
    let fieldNames = Object.keys(this.state.errors)
    for (let i = 0, l = fieldNames.length; i < l; i++) {
      let fieldName = fieldNames[i]
      let checkResult = this.checkField(fieldName, this.state.actRecord[fieldName], '')
      errors[fieldName] = checkResult.error
      if (checkResult.error !== '') hasErrors = true
    }

    if (!hasErrors) {
      let record = Object.assign({}, this.state.actRecord)
      if (record.appFilter && typeof record.appFilter === 'string')
        record.appFilter = record.appFilter.split(/[,\s]+/).filter(client => client)
      if (record.requestCodes && typeof record.requestCodes === 'string')
        record.requestCodes = record.requestCodes.split(/[,\s]+/).filter(requestCode => requestCode)

      if (record.data && record.data.id) {
        record.data = {
          systemAction: 'navigateTo',
          actionParams: { id: record.data.id.toString() }
        }
      }

      if (!record.data) record.data = {}
      record.data.notification_message = record.message
      if (record.title) record.data.notification_title = record.title

      console.log('PushSendDialog.internalHandleSave', JSON.stringify(record))
      let sendResult = await this.props.sendFunc(record)
      if (!sendResult) this.setState({ handleSave: false })
    } else {
      this.setState({ errors: errors, handleSave: false })
    }
  }

  handleChangeTab = (event, newValue) => {
    this.setState({ chosenTab: newValue })
  }

  render () {
    const { classes, action, title, closeFunc, dialogConfig } = this.props
    const { chosenTab, readOnly, errors, actRecord, handleSave, tileList, channels } = this.state
    const hiddenFields = dialogConfig !== null && dialogConfig.hideFields && Array.isArray(dialogConfig.hideFields)
      ? dialogConfig.hideFields
      : []

    const titleText = readOnly ? getString('PUSH_VIEW_HEADER') : title
    const actionText = readOnly ? getString('BACK_BUTTON') : action
    const selectableChannels = [{ channelName: '', _getString: () => '\u00A0' }, ...channels]

    const showTileSelection = !readOnly && hiddenFields.indexOf('tile') === -1
    const showChannelSelect = !readOnly && channels.length > 0 && hiddenFields.indexOf('limitChannels') === -1
    const hidePerson = hiddenFields.indexOf('requestCodes') !== -1
    const hideDevices = hiddenFields.indexOf('appFilter') !== -1

    /*
    data: wird zu suggestField für navigateTo, und benötigt  Tiles
    clients:  Möglichkeit der Einschränkung, uuid pattern Liste

     */
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogBackDrop open={handleSave}/>

        <form onSubmit={(event) => {this.handleSave(event)}}>
          <DialogTitle id="form-dialog-title" className={classes.headLine}>{titleText}</DialogTitle>

          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignContent: 'stretch'
            }}
            dividers={true}>

            <TextField
              id="title"
              label={getString('PUSH_HEADLINE_TITLE')}
              required={false}
              type="text"
              error={errors.title !== ''}
              helperText={errors.title}
              onChange={(event) => {this.changeField('title', event.target.value)}}
              value={actRecord.title}
              style={{ flex: '1 0 98%' }}
              className={classes.formControl}
              readOnly={readOnly}
              disabled={readOnly}
            />

            <TextField
              id="message"
              label={getString('PUSH_HEADLINE_MESSAGE')}
              required={true}
              type="text"
              multiline
              error={errors.message !== ''}
              helperText={errors.message}
              minRows={3}
              onChange={(event) => {this.changeField('message', event.target.value)}}
              value={actRecord.message}
              style={{ flex: '1 0 98%' }}
              className={classes.formControl}
              readOnly={readOnly}
              disabled={readOnly}
            />

            {showTileSelection && <div style={{ flex: '1 0 98%' }}>
              <SingleSuggest
                id="tile"
                required={false}
                error={errors.data !== ''}
                helperText={errors.data}
                label={getString('PUSH_LABEL_NAVIGATETO')}
                placeholder={getString('PUSH_HELPER_NAVIGATETO')}
                records={tileList}
                value={this.transformToTile(actRecord.data)}
                onChange={(newValue) => {this.changeField('data', newValue)}}
              />
            </div>}

            {!readOnly && !hideDevices && !hidePerson &&
              <React.Fragment>
                <div style={{ flex: '1 0 100%', marginTop: 16 }}>
                  <Tabs
                    value={chosenTab}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="play mode options"
                  >
                    {!hideDevices &&  <Tab label={getString('PUSH_TAB_DEVICES')} id="tab_devices" key="tab_devices"
                         aria-controls="tab_panel_devices" classes={{ selected: classes.tabSelected }}/>}
                    {!hidePerson && <Tab label={getString('PUSH_TAB_PERSONAL_NUMBERS')} id="tab_personal_numbers"
                         key="tab_personal_numbers"
                         aria-controls="tab_panel_personal_numbers" classes={{ selected: classes.tabSelected }}/>}
                  </Tabs>
                </div>

                <SwipeableViews
                  axis={'x'}
                  index={chosenTab}
                  onChangeIndex={this.handleChangeTab}
                  style={{ width: '100%', border: '1px solid rgba(0,0,0,0.26)' }}
                >
                  {!hideDevices && <OptionsPanel value={chosenTab} index={0} dir={'ltr'} id="tab_panel_devices" key={0}>
                    <TextField
                      id="appFilter"
                      label={getString('PUSH_LABEL_RECEIVER')}
                      required={false}
                      type="text"
                      multiline
                      placeholder={getString('PUSH_HELPER_RECEIVER')}
                      error={errors.appFilter !== ''}
                      helperText={errors.appFilter}
                      minRows={3}
                      onChange={(event) => {this.changeField('appFilter', event.target.value)}}
                      value={actRecord.appFilter}
                      style={{ flex: '1 0 98%' }}
                      className={classes.formControl}
                    />
                  </OptionsPanel>}
                  {!hidePerson && <OptionsPanel value={chosenTab} index={1} dir={'ltr'} id="tab_panel_personal_numbers" key={1}>
                    <TextField
                      id="requestCodes"
                      label={getString('PUSH_LABEL_PERSONS')}
                      required={false}
                      type="text"
                      multiline
                      placeholder={getString('PUSH_HELPER_PERSONS')}
                      error={errors.requestCodes !== ''}
                      helperText={errors.requestCodes}
                      minRows={3}
                      onChange={(event) => {this.changeField('requestCodes', event.target.value)}}
                      value={actRecord.requestCodes}
                      style={{ flex: '1 0 98%' }}
                      className={classes.formControl}
                    />
                  </OptionsPanel>}
                </SwipeableViews>
              </React.Fragment>}

            <FilterAppComponent
              actRecord={actRecord}
              changeField={(fieldName, value) => this.changeField(fieldName, value)}
              hiddenFields={hiddenFields}
              readOnly={readOnly}/>

            {showChannelSelect && <TextField
              id="limitChannels"
              label={'nur an Channel'}
              required={false}
              type="text"
              select={true}
              error={errors.limitChannels !== ''}
              helperText={errors.limitChannels}
              onChange={(event) => {this.changeField('limitChannels', event.target.value)}}
              value={actRecord.limitChannels}
              style={{ flex: '1 0 30%' }}
              className={classes.formControl}
              readOnly={readOnly}
              disabled={readOnly}
            >{selectableChannels.map(channel => <MenuItem key={channel.channelName} value={channel.channelName}>
              {channel._getString()}
            </MenuItem>)}</TextField>}

            {readOnly && <React.Fragment>
              <div className="formNewLine"/>
              <TextField
                id="data"
                label={getString('PUSH_LABEL_NAVIGATETO')}
                type="text"
                onChange={(event) => {}}
                value={this.translateData(actRecord.data)}
                style={{ flex: '1 0 98%' }}
                className={classes.formControl}
                readOnly={readOnly}
                disabled={readOnly}
              />

              <div className="formNewLine"/>

              <TextField
                id="sentDate"
                label={getString('PUSH_HEADLINE_SENT')}
                type="text"
                onChange={(event) => {}}
                value={formatDate(actRecord.sentDate)}
                style={{ flex: '1 0 30%' }}
                className={classes.formControl}
                readOnly={readOnly}
                disabled={readOnly}
              />

              <TextField
                id="clients"
                label={getString('PUSH_HEADLINE_RECEIVERS')}
                type="number"
                onChange={(event) => {}}
                value={actRecord.clients}
                style={{ flex: '1 0 30%' }}
                className={classes.formControl}
                readOnly={readOnly}
                disabled={readOnly}
              />

              <TextField
                id="errors"
                label={getString('PUSH_HEADLINE_ERRORS')}
                type="number"
                onChange={(event) => {}}
                value={actRecord.errors}
                style={{ flex: '1 0 30%' }}
                className={classes.formControl}
                readOnly={readOnly}
                disabled={readOnly}
              />
            </React.Fragment>}
          </DialogContent>

          <DialogActions className={classes.footLine}>
            {!readOnly && <CancelButton onClick={closeFunc}/>}
            <SubmitButton title={actionText} disabled={false}/>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

PushSendDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  sendFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired,
  getChannels: PropTypes.func.isRequired,
  getTiles: PropTypes.func.isRequired,
  dialogConfig: PropTypes.object
}

export default withStyles(styles)(PushSendDialog)
