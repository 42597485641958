import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ApiService from '../services/APIService'

class UrlHelper {

    static getUrlParams (path) {
        if (!path && (!window || !window.location || !window.location.search)) {
            return {}
        }

        const search = path || window.location.search
        const hashes = search.slice(search.indexOf('?') + 1).split('&')
        const params = {}
        hashes.map(hash => {
            const [key, val] = hash.split('=')
            params[key] = decodeURIComponent(val)
            return params
        })

        return params
    }

    static parseURL (url) {
        const parser = document.createElement('a')
        const searchObject = {}
        parser.href = url
        const queries = (parser.search) ? parser.search.replace(/^\?/, '').split('&') : []
        for (let i = 0; i < queries.length; i++) {
            const split = queries[i].split('=')
            searchObject[split[0]] = split[1]
        }
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash
        }
    }

}

class VideoPlayer extends PureComponent {

    constructor (props) {
        super(props)
        this.state = {
            videoRequestResult: null,
            sanitizedSrc: null
        }
        this.video = React.createRef()
        this.lastPosition = 0
        this.lastWindowWidth = null
    }

    onTimeUpdate = (event) => {
        let actPosition = Math.trunc(this.video.current.currentTime)
        if (actPosition !== this.lastPosition) {
            this.lastPosition = actPosition
            this.props.updatePosition(actPosition, 'video')
        }
    }

    setTime = (second) => {
        if (this.video.current) {
            this.video.current.currentTime = second
        }
    }

    onresize = (event) => {
        this.getCorrectVideoUrl(this._correctM3u8(this.props.video.src))
    }

    componentDidMount () {
        this.getCorrectVideoUrl(this._correctM3u8(this.props.video.src))

        if (this.video.current) {
            this.video.current.addEventListener('timeupdate', this.onTimeUpdate)
            window.addEventListener('resize', this.onresize)
            this.props.returnPointSetter((second) => {this.setTime(second)})
        }
    }

    componentWillUnmount () {
        if (this.video.current) {
            this.video.current.removeEventListener('timeupdate', this.onTimeUpdate)
            window.removeEventListener('resize', this.onresize)
        }
    }

    getCorrectVideoUrl = async (m3u8) => {
        try {
            const parts = UrlHelper.parseURL(m3u8)
            const filename = parts.pathname.split('/').pop()
            const mediaId = filename.substring(0, filename.indexOf('.'))
            const infoUrl = parts.protocol + '//cdn.jwplayer.com/v2/media/' + mediaId
            const videoRequestResult = this.state.videoRequestResult || await ApiService.getJWVideoDesc(infoUrl)
            if (videoRequestResult) {
                const sources = videoRequestResult.playlist[0].sources

                let optimalLongSide = null
                let longSideDiff = null
                let optimalMp4File = null

                const width = ((window.innerWidth && window.innerWidth > 600) ? window.innerWidth / 2 : window.innerWidth) || 640
                if (this.lastWindowWidth === null) {
                    this.lastWindowWidth = width
                } else if (this.lastWindowWidth === width) {
                    return
                } else {
                    this.lastWindowWidth = width
                }

                // const width = this.videoElement && this.videoElement.offsetWidth > 0 ? this.videoElement.offsetWidth : 640
                for (let i = 0, l = sources.length; i < l; i++) {
                    const description = sources[i]
                    if (description.label === 'Passthrough') continue
                    if (!description.hasOwnProperty('width')) continue
                    if (!optimalLongSide || Math.abs(width - description.width) < longSideDiff) {
                        optimalMp4File = description.file
                        optimalLongSide = description.width
                        longSideDiff = Math.abs(width - optimalLongSide)
                    }
                }

                if (optimalMp4File) {
                    // set actualized mp4 file
                    const changeSet = { sanitizedSrc: optimalMp4File }
                    if (!this.state.videoRequestResult) { changeSet.videoRequestResult = videoRequestResult}
                    this.setState(changeSet)
                }
            } else {
                console.error('ERROR', 'VideoView.getCorrectVideoUrl', videoRequestResult)
            }
        } catch (error) {
            console.error('ERROR', 'VideoView.getCorrectVideoUrl', error)
        }
    }

    _correctProtocol (url) {
        const protocol = window.location.protocol.toLowerCase()
        if (protocol === 'file:' || protocol === 'https:' || protocol === 'http:') {
            const parts = UrlHelper.parseURL(url)
            const protocolToUse = protocol === 'file:' ? 'https:' : protocol
            const host = (protocol === 'https:' && parts.host.match(/:80$/))
                ? parts.host.substring(0, parts.host.length - 3)
                : ((protocol === 'http:' && parts.host.match(/:443$/))
                    ? parts.host.substring(0, parts.host.length - 4)
                    : parts.host)
            return protocolToUse + '//' + host + (parts.pathname.substring(0, 1) !== '/' ? '/' : '') + parts.pathname + parts.search + parts.hash
        }
        return url
    }

    _correctM3u8 (url) {
        if (url.match('cdn.equeo.de')) {
            url = url.replace(/cdn.equeo.de/, 'content.jwplatform.com')
        }
        return this._correctProtocol(url)
    }

    render () {
        return (<video ref={this.video} id={'video_' + this.props.video.id}
                       src={this.state.sanitizedSrc || this.props.video.src} controls={true}
                       style={{ width: '100%' }} />)
    }
}

VideoPlayer.propTypes = {
    video: PropTypes.object.isRequired,
    updatePosition: PropTypes.func.isRequired,
    returnPointSetter: PropTypes.func.isRequired
}

export default VideoPlayer
