import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Highcharts from 'highcharts'
import {
  Chart,
  HighchartsChart,
  HighchartsProvider,
  Title,
  XAxis,
  YAxis,
  AreaSeries,
  Tooltip
} from 'react-jsx-highcharts'
import PercentageAbsoluteSelector from '../PercentageAbsoluteSelector'

class EngagementChart extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showMode: 'absolute',
      chartElement: null,
      height: 240
    }
    this.boxRef = React.createRef()
  }

  onresize = (event) => {
    if (this.boxRef.current) {
      this.setState({ height: Math.max(240, Math.trunc((this.boxRef.current.offsetWidth * 9 / 16) - 42)) })
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.onresize)
    this.onresize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onresize)
  }

  changeShowMode = (event) => {
    this.setState({ showMode: event.target.value })
  }

  loadHandler = (event) => {
    let self = this
    setTimeout(() => {
      self.setState({ chartElement: event.target })
      self.props.returnPointSetter((xValue) => {self.selectPoint(xValue)})
    }, 0)
  }

  selectPoint = (xValue) => {
    const { chartElement } = this.state
    if (chartElement !== null) {
      const point = chartElement.series[0].points.find(point => {return point.x === xValue})
      if (point) {
        point.series.chart.xAxis[0].drawCrosshair(null, point)
        point.series.chart.tooltip.refresh(point) // Show the tooltip
        point.setState('select')
      }
    }
  }

  render () {
    const { views, updatePosition } = this.props
    const { showMode, height } = this.state

    const entries = {}
    views.forEach(viewEntry => {
      let sequences = viewEntry.sequences
      if (sequences && Array.isArray(sequences)) {
        sequences.forEach(sequence => {
          let second = Math.trunc(sequence.start)
          let end = Math.trunc(sequence.end)
          while (second <= end) {
            const index = second.toString()
            if (!entries[index])
              entries[index] = 1
            else
              entries[index] += 1
            second += 1
          }
        })
      }
    })

    const xAxisData = []
    const series = []
    const maxView = Math.max(...Object.values(entries).map(value => Number.parseInt(value.toString()))) // views.length

    Object.keys(entries).sort((a, b) => {return Number.parseInt(a) - Number.parseInt(b)}).forEach(second => {
      xAxisData.push(second)
      series.push(showMode === 'absolute' ? entries[second] : ((maxView > 0) ? Math.round(10000.0 * entries[second] / maxView) / 100 : 0))
    })

    const tooltipPositioner = function () {
      return { x: this.chart.chartWidth - this.label.width - 32, y: 2 }
    }

    const plotOptions = {
      series: {
        allowPointSelect: true,
        point: {
          events: {
            mouseOver: function (event) {
              event.target.series.chart.xAxis[0].drawCrosshair(event, event.target)
              event.target.series.chart.tooltip.refresh(event.target) // Show the tooltip
              event.target.setState('select')
              updatePosition(event.target.x, 'engagement')
            }
          }
        }
      }
    }

    const labelOptions = {
      formatter: function () {
        const minutes = Math.trunc(this.value / 60).toString()
        let seconds = (this.value % 60).toString()
        if (seconds.length === 1) seconds = '0' + seconds
        return minutes + ':' + seconds
      }
    }

    return (<Box align={'right'} ref={this.boxRef}>
      <PercentageAbsoluteSelector showMode={showMode} handleModeChange={this.changeShowMode}/>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart plotOptions={plotOptions} displayErrors={true}>
          <Chart height={height} onAddSeries={this.loadHandler}/>
          <Title>Engagement</Title>
          <XAxis crosshair={{ color: 'red' }} categories={xAxisData} labels={labelOptions}/>
          <YAxis allowDecimals={showMode !== 'absolute'}
                 max={showMode === 'absolute' ? null : 100}>
            <AreaSeries dataLabels={{ enabled: false }} data={series} marker={{ enabled: false }}/>
          </YAxis>
          <Tooltip
            positioner={tooltipPositioner}
            borderWidth={0}
            backgroundColor="none"
            pointFormat={'{point.y}' + (showMode !== 'absolute' ? '%' : '')}
            headerFormat=""
            shadow={false}
            style={{ fontSize: '18px' }}
            valueDecimals={showMode !== 'absolute'}/>
        </HighchartsChart>
      </HighchartsProvider>
    </Box>)
  }
}

EngagementChart.propTypes = {
  views: PropTypes.array.isRequired,
  updatePosition: PropTypes.func.isRequired,
  returnPointSetter: PropTypes.func.isRequired
}

export default EngagementChart
