import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'

import getString from '../../config/strings'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    paddingBottom: 16
  }
})

function timeout (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

class MailSendDialog extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      actRecord: { receivers: '' },
      errors: { receivers: '' },
      isSubmitting: false
    }
  }

  checkField (fieldName, value, error) {
    switch (fieldName) {
      case 'receivers':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_MAIL_SEND_RECEIVERS_EXPECTED')
        } else {
          const parts = value.split(/,|\s+/).filter(email => email !== '')
          for ( const part of parts ) {
            if (!part.match(/^[A-Za-zäöüßÄÖÜ0-9!#$%&'*+/=?^_`{|}~-]+(\.[A-Za-zäöüßÄÖÜ0-9!#$%&'*+/=?^_`{|}~-]+)*@([A-Za-zäöüßÄÖÜ0-9][A-Za-zäöüßÄÖÜ0-9-]*\.)+[A-Za-zäöüßÄÖÜ0-9]{2,}$/)) {
              error = getString('ERROR_MAIL_SEND_RECEIVERS_WRONG')
              break
            }
          }
        }
        break
      default:
    }

    return { sanitizedValue: value, error: error }
  }

  changeField (fieldName, value) {
    const { actRecord, errors } = this.state
    const newRecord = Object.assign({}, actRecord)
    const newErrors = Object.assign({}, errors)
    const changeSet = {}

    const checkResult = this.checkField(fieldName, value, '')
    if (newRecord[fieldName] !== checkResult.sanitizedValue) {
      newRecord[fieldName] = checkResult.sanitizedValue
      changeSet.actRecord = newRecord
    }
    if (newErrors[fieldName] !== checkResult.error) {
      newErrors[fieldName] = checkResult.error
      changeSet.errors = newErrors
    }

    if (!checkResult.error && checkResult.otherFields) {
      changeSet.actRecord = Object.assign(newRecord, checkResult.otherFields)
    }

    if (Object.keys(changeSet).length > 0) this.setState(changeSet)
  }

  checkAll () {
    const { actRecord, errors } = this.state
    const newErrors = Object.assign({}, errors)
    const newRecord = Object.assign({}, actRecord)
    let hasErrors = false
    const fieldNames = Object.keys(newErrors)
    for (const fieldName of fieldNames) {
      let fieldValue = newRecord[fieldName]
      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.replace(/(^\s+|\s+$)/g, '')
        newRecord[fieldName] = fieldValue
      }
      let checkResult = this.checkField(fieldName, newRecord[fieldName], '')
      newErrors[fieldName] = checkResult.error
      if (checkResult.error !== '') hasErrors = true
    }
    return [hasErrors, newErrors, newRecord]
  }

  sendMail = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { isSubmitting } = this.state
    if (!isSubmitting) {
      this.setState({ isSubmitting: true }, () => {this.internalSendMail()})
    }
  }

  async internalSendMail () {
    await timeout(200)

    // check all fields again
    const [hasErrors, newErrors, newRecord] = this.checkAll()

    if (!hasErrors) {
      const { sendFunc, closeFunc, record } = this.props
      let sendResult = await sendFunc({ templateId: record.id, receivers: newRecord.receivers.split(/,|\s+/).filter(email => email !== '') })
      if (!sendResult) {
        this.setState({ isSubmitting: false })
      } else {
        closeFunc()
      }
    } else {
      console.log('ERROR', 'MailSendDialog.internalSendMail', newErrors)
      this.setState({ errors: newErrors, isSubmitting: false })
    }
  }

  render () {
    const { classes, record, closeFunc } = this.props
    const { isSubmitting, actRecord, errors } = this.state

    const [hasErrors, ,] = this.checkAll()

    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogBackDrop open={isSubmitting} />
        <form onSubmit={this.sendMail}>
          <DialogTitle id="form-dialog-title" className={classes.headLine}>
            {getString('NOTIFICATION_TEMPLATE_SEND_TITLE')}
          </DialogTitle>

          <DialogContent className={classes.content} dividers={true}>
            <DialogContentText id="alert-dialog-description"
                               style={{ flex: '1 0 98%' }}
                               dangerouslySetInnerHTML={{__html: getString('NOTIFICATION_TEMPLATE_SEND_COPY',[record._getString()])}} />
            <TextField
              id="receivers"
              label={getString('NOTIFICATION_TEMPLATE_SEND_LABEL_RECEIVERS')}
              required={true}
              multiline={true}
              minRows={7}
              type="text"
              error={errors.receivers !== ''}
              helperText={errors.receivers}
              onChange={(event) => {this.changeField('receivers', event.target.value)}}
              value={actRecord.receivers}
              className={classes.formControl}
              style={{ flex: '1 0 98%' }}
            />
          </DialogContent>

          <DialogActions className={classes.footLine}>
            <CancelButton onClick={closeFunc} />
            <SubmitButton title={getString('NOTIFICATION_TEMPLATE_SEND_SUBMIT')} disabled={hasErrors} />
          </DialogActions>
        </form>
      </Dialog>)
  }
}

MailSendDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  closeFunc: PropTypes.func.isRequired,
  sendFunc: PropTypes.func.isRequired
}

export default withStyles(styles)(MailSendDialog)
