import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import getString from '../../config/strings'

import { showAlert } from 'eqmod-react-alert'

import { AuthContext } from '../library/pageComponents/AuthContext'
import FilterField from '../library/pageComponents/FilterField'
import WaitForLoad from '../library/pageComponents/WaitForLoad'
import enhanceEditButton, { MessageButton } from '../library/tableComponents/EditButton'
import enhanceDeleteButton from '../library/tableComponents/DeleteButton'
import TableEnhanced from '../library/tableComponents/TableEnhanced'
import { deferredReload, addSubscription } from './helpers/pageHelper'
import NotificationTemplateDialog from '../dialogs/NotificationTemplateDialog'
import MailSendDialog from '../dialogs/MailSendDialog'

import { notificationTemplateService } from '../../services/dataService'

const headCells = [
  { id: '0', disablePadding: true, canBeSorted: false, label: '\u00A0' },
  { id: 'name', canBeSorted: true, label: getString('NOTIFICATION_TEMPLATE_LABEL_NAME') },
  { id: 'subject', canBeSorted: true, label: getString('NOTIFICATION_TEMPLATE_LABEL_SUBJECT') },
  { id: '3', disablePadding: true, canBeSorted: false, label: '\u00A0' },
  { id: '4', disablePadding: true, canBeSorted: false, label: '\u00A0' }
]

const bodyCells = [
  { id: '0', align: 'center', disablePadding: true, type: 'render' },
  { id: 'name', type: 'data', value: 'name' },
  { id: 'subject', type: 'data', value: 'subject' },
  { id: '3', align: 'center', disablePadding: true, type: 'render' },
  { id: '4', align: 'center', disablePadding: true, type: 'render' }
]

const labelSelectors = {
  zeroFiltered: 'NOTIFICATION_TEMPLATE_ZERO_FILTERED',
  zero: 'NOTIFICATION_TEMPLATE_ZERO',
  deleteHeader: 'NOTIFICATION_TEMPLATE_DELETE_HEADER',
  deleteText: 'NOTIFICATION_TEMPLATE_DELETE_TEXT'
}

const enhanceMailButton = (openFunc) => {
  return React.memo((props) => {
    return <MessageButton {...Object.assign({}, props, { editFunc: openFunc, color: 'secondary' })} />
  })
}

class TemplatesPage extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      actRecord: null,
      sendRecord: null,

      records: [],
      filter: '',
      page: 0,
      rowsPerPage: 10,
      count: 0,
      order: { column: 'name', direction: 'asc' },

      isLoading: false
    }

    this.head = React.createRef()
    this.deferredTimeout = null
    this.subscription = null
    this.isCancelled = false

    bodyCells[0].value = enhanceEditButton((index) => {this.openDialog(index)})
    bodyCells[3].value = enhanceDeleteButton((index) => {this.deleteRecord(index)}, labelSelectors)
    bodyCells[4].value = enhanceMailButton((index) => {this.openMailDialog(index)})
  }

  async reload () {
    const { page, rowsPerPage, order } = this.state
    await this.load(page, rowsPerPage, order.column, order.direction)
  }

  componentDidMount () {
    this.reload()
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const { filter } = this.state
    if (prevState.filter !== filter) {
      deferredReload(this)
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  async load (page, rowsPerPage, sortColumn, orderDirection) {
    const { filter } = this.state
    this.setState({ isLoading: true })

    addSubscription(this)

    try {
      if (this.isCancelled) return

      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        this.setState({ isLoading: false })
        return
      }
      if (this.isCancelled) return

      let count = await notificationTemplateService.countAll(newAuth, { filter: filter })
      if (this.isCancelled) return

      let start = page * rowsPerPage
      if (start > count) {
        start = 0
        page = 0
      }
      if (this.isCancelled) return

      let data = []
      if (count > 0) {
        data = await notificationTemplateService.getAll(newAuth, {
          offset: start,
          max: rowsPerPage,
          filter: filter,
          sort: sortColumn,
          order: orderDirection
        })
        if (this.isCancelled) return
      }

      this.setState({
        records: data,
        page: page,
        rowsPerPage: rowsPerPage,
        order: { column: sortColumn, direction: orderDirection },
        count: count,
        isLoading: false
      })

    } catch (error) {
      this.subscription = null
      if (this.isCancelled) return

      this.setState({ isLoading: false })
      console.error('ERROR', 'TemplatesPage.reload', error)
      if (!error.message.startsWith('isCancelled'))
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  sendTestMail = async (record) => {
    console.log('INFO','TemplatesPage.sendTestMail to send:', JSON.stringify(record))

    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) return null

      const data = await notificationTemplateService.send(newAuth, record)
      console.log('INFO','TemplatesPage.sendTestMail result:', JSON.stringify(data))
      return data

    } catch (error) {
      console.error('ERROR', 'TemplatesPage.sendTestMail', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return null
    }
  }

  openDialog = (index) => {
    const { records } = this.state
    const emptyRecord = notificationTemplateService.getEmptyRecordImmediately()

    if (index !== -1 && records[index]) {
      // copy record
      let record = Object.assign({}, records[index])
      // ensure all fields are initialized
      Object.keys(emptyRecord).forEach(fieldName => {
        if (record[fieldName] === null) record[fieldName] = emptyRecord[fieldName]
      })
      this.setState({ actRecord: record })
    } else {
      // init with empty Record
      this.setState({ actRecord: emptyRecord })
    }
  }

  closeDialog = () => {
    this.setState({ actRecord: null })
  }

  openMailDialog = (index) => {
    const { records } = this.state

    if (index !== -1 && records[index]) {
      // copy record
      this.setState({ sendRecord: records[index] })
    }
  }

  closeMailDialog = () => {
    this.setState({ sendRecord: null })
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  async saveRecord (record) {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) return null

      let data

      if (!record.id) {
        data = await notificationTemplateService.save(newAuth, record)
      } else {
        data = await notificationTemplateService.update(newAuth, record)
      }

      await this.reload()
      return data

    } catch (error) {
      console.error('ERROR', 'TemplatesPage.saveRecord save template', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return null
    }
  }

  async deleteRecord (index) {
    const { records } = this.state
    const record = (records[index]) ? Object.assign({}, records[index]) : null

    if (record) {
      this.setState({ isLoading: true })
      try {
        const { refreshAuth } = this.context
        const newAuth = await refreshAuth()
        if (newAuth === null) {
          this.setState({ isLoading: false })
          return
        }

        await notificationTemplateService.remove(newAuth, record.id)
        await this.reload()
      } catch (error) {
        this.setState({ isLoading: false })
        console.error('ERROR', 'TemplatesPage.deleteRecord', error)
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      }
    }
  }

  renderHeader () {
    const { filter } = this.state

    return (
      <React.Fragment>
        <Grid item container xs={12} sm={6} justifyContent="flex-start" alignItems="flex-end">
          <div>
            <Button variant="contained" color="primary" onClick={() => this.openDialog(-1)}
                    startIcon={<AddIcon/>}>
              {getString('NOTIFICATION_TEMPLATE_ADD_BUTTON')}
            </Button>
          </div>
        </Grid>

        <Grid item container xs={12} sm={6} justifyContent="flex-end" alignItems="flex-end" ref={this.head}>
          <FilterField value={filter} handleFilterChange={this.handleFilterChange}/>
        </Grid>
      </React.Fragment>
    )
  }

  renderBody () {
    const { actRecord, count, filter, order, page, records, rowsPerPage, sendRecord } = this.state
    const dialogOpen = actRecord !== null
    const mailSendOpen = sendRecord !== null
    return (
      <Grid item xs={12}>
        <TableEnhanced records={records}
                       reloadFunc={(newPage, newRowsPerPage, newSortColumn, newOrderDirection) => {this.load(newPage, newRowsPerPage, newSortColumn, newOrderDirection)}}
                       page={page}
                       rowsPerPage={rowsPerPage}
                       sortColumn={order.column}
                       orderDirection={order.direction}
                       count={count}
                       isFiltered={filter !== ''}
                       headCells={headCells}
                       bodyCells={bodyCells}
                       labelSelectors={labelSelectors}/>
        {dialogOpen &&
        <NotificationTemplateDialog
          title={getString(actRecord.id ? 'NOTIFICATION_TEMPLATE_EDIT_HEADER' : 'NOTIFICATION_TEMPLATE_ADD_BUTTON')}
          action={getString(actRecord.id ? 'NOTIFICATION_TEMPLATE_UPDATE_BUTTON' : 'NOTIFICATION_TEMPLATE_CREATE_BUTTON')}
          closeFunc={this.closeDialog}
          saveFunc={(record) => {return this.saveRecord(record)}}
          record={actRecord}
        />}
        {mailSendOpen && <MailSendDialog record={sendRecord} closeFunc={this.closeMailDialog} sendFunc={this.sendTestMail}/>}
      </Grid>
    )
  }

  render () {
    const { isLoading } = this.state
    const top = this.head.current ? this.head.current.getBoundingClientRect().bottom : 0
    return (
      <Grid container spacing={3} style={{ width: '100%', margin: 0, marginTop: 12, paddingBottom: 16 }}>
        {this.renderHeader()}

        {isLoading && <WaitForLoad top={top}/>}
        {this.renderBody()}
      </Grid>
    )
  }
}

TemplatesPage.propTypes = {
  tabActions: PropTypes.object
}

TemplatesPage.contextType = AuthContext

export default TemplatesPage
