import React, { memo } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
}))

const DialogBackDrop = ({ open }) => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="primary"/>
    </Backdrop>
  )
}

DialogBackDrop.propTypes = {
  open: PropTypes.bool.isRequired
}

export default memo(DialogBackDrop)
