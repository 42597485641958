import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'

import ShowContextPath from './ShowContextPath'
import EngagementChart from './charts/EngagementChart'
import ViewsHistoryChart from './ViewsHistoryChart'
import VideoPlayer from './VideoPlayer'

const AccordionSummary = withStyles({
    expanded: {
        backgroundColor: '#f5f5f5'
    }
})(MuiAccordionSummary)

class VideoView extends PureComponent {

    constructor (props) {
        super(props)
        this.engagementSetter = null
        this.videoSetter = null
    }

    updatePosition = (second, initiator) => {
        if (initiator === 'video' && this.engagementSetter) { this.engagementSetter(second) }
        if (initiator === 'engagement' && this.videoSetter) { this.videoSetter(second) }
    }

    storeEngagementSetter = (func) => {
        this.engagementSetter = func
    }

    storeVideoSetter = (func) => {
        this.videoSetter = func
    }

    render () {
        const { video } = this.props
        let viewCount = video.views && Array.isArray(video.views) ? video.views.length : 0
        let viewSeconds = 0

        if (viewCount) {
            video.views.forEach(viewEntry => {
                let sequences = viewEntry.sequences
                if (sequences && Array.isArray(sequences)) {
                    sequences.forEach(sequence => {
                        let start = Math.trunc(sequence.start)
                        let end = Math.trunc(sequence.end)
                        viewSeconds += end - start
                    })
                }
            })
        }

        return (<Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={'Video-' + video.id + '-content'}
                id={'Video-' + video.id + '-header'}
            >
                <Typography variant={'body1'}>{video.title + ' (' + viewCount + ' - ' + (viewSeconds / 60).toFixed(1).replace('.',',') + 'min.)'}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column' }}>
                <ShowContextPath nugget={video} />
                <Grid container spacing={4}>
                    <Grid item sm={6} xs={12}>
                        <VideoPlayer video={video} updatePosition={this.updatePosition}
                                     returnPointSetter={this.storeVideoSetter} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <EngagementChart views={video.views} updatePosition={this.updatePosition}
                                         returnPointSetter={this.storeEngagementSetter} />
                    </Grid>
                    <Grid item xs={12}>
                        <ViewsHistoryChart views={video.views} />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>)
    }
}

VideoView.propTypes = {
    video: PropTypes.object.isRequired
}

export default VideoView
