import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

const useStyles = makeStyles(theme => ({
  backdrop: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: theme.zIndex.drawer + 1
  },
}))

const WaitForLoad = ({ invisible, top }) => {
  const classes = useStyles()

  const backdropDiv = useRef()
  top = top || 0
  invisible = invisible || false

  useEffect(() => {
    if (backdropDiv.current) {
      backdropDiv.current.style.top = top + 'px'
    }
  })

  return (
    <Backdrop className={classes.backdrop} open={true} ref={backdropDiv} invisible={invisible}>
      <CircularProgress/>
    </Backdrop>)
}

WaitForLoad.propTypes = {
  invisible: PropTypes.bool,
  top: PropTypes.number
}

WaitForLoad.defaultProps = {
  invisible: false,
  top: 0
}

export default React.memo(WaitForLoad)
