import React, { PureComponent, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import { AuthContext } from '../library/pageComponents/AuthContext'
import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'
import RecordDates from '../library/dialogComponents/RecordDates'

import JsonEditor from '../library/dialogComponents/fields/jsonEditor/JsonEditor'
import '../library/dialogComponents/fields/jsonEditor/editor.css'

import ace from 'brace'
import 'brace/mode/json'
import 'brace/theme/clouds'

import Ajv from 'ajv'
import getString, { dateLocale } from '../../config/strings'

const ajv = new Ajv({ allErrors: true, verbose: true })

const listSchema = {
  type: 'array',
  items: {
    type: 'string'
  }
}

const configMessageSchema = {
  type: 'object',
  properties: {
    title: { type: 'string' },
    dialogMessage: { type: 'string' },
    author: { type: 'boolean' },
    demoMode: { type: 'boolean' },
    customer: { type: 'string' }
  },
  required: [],
  additionalProperties: true
}

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  statusField: {
    margin: theme.spacing(1),
    flex: '1 0 30%'
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px',
    justifyContent: 'space-between'
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    paddingBottom: 16
  }
})

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    width: '100%',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    padding: 0
  },
}))(MuiAccordionDetails)

function timeout (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const jsonTranslate = (content, defaultValue) => {
  if (typeof content === 'undefined') return defaultValue
  if (content === null) return defaultValue
  if (typeof content === 'string') {
    return content ? JSON.parse(content) : defaultValue
  }
  return content
}

const RenderValidity = ({ classes, actRecord, errors, fixedValues, changeField, fields }) => {
  const [expanded, setExpanded] = useState(fields.length < 3)

  const myMoment = moment()
  myMoment.locale(dateLocale())

  const handleChange = (event) => {
    setExpanded(!expanded)
  }

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        {getString('ACTIVATION_CODE_SUBTITLE_VALIDITY')}
      </AccordionSummary>
      <AccordionDetails>
        {fields.includes('valid_from') && <div className={classes.formControl} style={{ flex: '1 0 45%' }}>
          <KeyboardDateTimePicker
            format={myMoment.localeData().longDateFormat('L') + ' ' + myMoment.localeData().longDateFormat('LT')}
            id="valid_from"
            label={getString('ACTIVATION_CODE_LABEL_VALID_FROM')}
            value={actRecord.valid_from}
            onChange={(date) => {changeField('valid_from', date)}}
            disabled={fixedValues.hasOwnProperty('valid_from')}
            error={errors.valid_from !== ''}
            helperText={errors.valid_from}
            ampm={false}
            fullWidth
          />
        </div>}

        {fields.includes('valid_until') && <div className={classes.formControl} style={{ flex: '1 0 45%' }}>
          <KeyboardDateTimePicker
            format={myMoment.localeData().longDateFormat('L') + ' ' + myMoment.localeData().longDateFormat('LT')}
            id="valid_until"
            label={getString('ACTIVATION_CODE_LABEL_VALID_UNTIL')}
            value={actRecord.valid_until}
            onChange={(date) => {changeField('valid_until', date)}}
            disabled={fixedValues.hasOwnProperty('valid_until')}
            error={errors.valid_until !== ''}
            helperText={errors.valid_until}
            ampm={false}
            fullWidth
          />
        </div>}

        {fields.includes('validDaysFromActivation') && <TextField
          id="validDaysFromActivation"
          label={getString('ACTIVATION_CODE_LABEL_VALID_DAYS')}
          type="number"
          error={errors.validDaysFromActivation !== ''}
          helperText={errors.validDaysFromActivation}
          onChange={(event) => {changeField('validDaysFromActivation', event.target.value)}}
          value={actRecord.validDaysFromActivation}
          className={classes.formControl}
          disabled={fixedValues.hasOwnProperty('validDaysFromActivation')}
          style={{ flex: '1 0 45%' }}
        />}

        {fields.includes('revoked') && <FormControlLabel
          control={
            <Checkbox
              checked={actRecord.revoked}
              onChange={(event) => {changeField('revoked', event.target.checked)}}
              size="small"
              name="revoked"
              color="primary"
            />
          }
          disabled={fixedValues.hasOwnProperty('revoked')}
          style={{ display: 'flex', flex: '1 0 45%', margin: 8, marginBottom: 0 }}
          label={getString('ACTIVATION_CODE_LABEL_REVOKED')}
        />}

        {fields.includes('activateUntil') &&
          <div className={classes.formControl} style={{ flex: '1 0 45%' }}>
            <KeyboardDateTimePicker
              format={myMoment.localeData().longDateFormat('L') + ' ' + myMoment.localeData().longDateFormat('LT')}
              id="activateUntil"
              label={getString('ACTIVATION_CODE_LABEL_ACTIVATE_UNTIL')}
              value={actRecord.activateUntil}
              onChange={(date) => {changeField('activateUntil', date)}}
              disabled={fixedValues.hasOwnProperty('activateUntil')}
              error={errors.activateUntil !== ''}
              helperText={errors.activateUntil}
              ampm={false}
              fullWidth
            />
          </div>}

        {fields.includes('firstActivateUntil') &&
          <div className={classes.formControl} style={{ flex: '1 0 45%' }}>
            <KeyboardDateTimePicker
              format={myMoment.localeData().longDateFormat('L') + ' ' + myMoment.localeData().longDateFormat('LT')}
              id="firstActivateUntil"
              label={getString('ACTIVATION_CODE_LABEL_FIRST_ACTIVATE_UNTIL')}
              value={actRecord.firstActivateUntil}
              onChange={(date) => {changeField('firstActivateUntil', date)}}
              disabled={fixedValues.hasOwnProperty('firstActivateUntil')}
              error={errors.firstActivateUntil !== ''}
              helperText={errors.firstActivateUntil}
              ampm={false}
              fullWidth
            />
          </div>}
      </AccordionDetails>
    </Accordion>)
}

const RenderPersonalization = ({ classes, actRecord, errors, fixedValues, changeField, fields }) => {
  const [expanded, setExpanded] = useState(fields.length < 3)

  const handleChange = (event) => {
    setExpanded(!expanded)
  }

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        {getString('ACTIVATION_CODE_SUBTITLE_PERSONALIZATION')}
      </AccordionSummary>
      <AccordionDetails>
        {fields.includes('must_personalized') && <FormControlLabel
          control={
            <Checkbox
              checked={actRecord.must_personalized}
              onChange={(event) => {changeField('must_personalized', event.target.checked)}}
              size="small"
              name="must_personalized"
              color="primary"
            />
          }
          style={{ display: 'flex', flex: '1 0 45%', margin: 8, marginBottom: 0 }}
          disabled={fixedValues.hasOwnProperty('must_personalized')}
          label={getString('ACTIVATION_CODE_LABEL_MUST_PERSONALIZED')}
        />}

        {fields.includes('only_onedevice') && <FormControlLabel
          control={
            <Checkbox
              checked={actRecord.only_onedevice}
              onChange={(event) => {changeField('only_onedevice', event.target.checked)}}
              size="small"
              name="only_onedevice"
              color="primary"
            />
          }
          style={{ display: 'flex', flex: '1 0 45%', margin: 8, marginBottom: 0 }}
          disabled={fixedValues.hasOwnProperty('only_onedevice')}
          label={getString('ACTIVATION_CODE_LABEL_ONLY_ONE_DEVICE')}
        />}

        {fields.includes('only_oneuser') && <FormControlLabel
          control={
            <Checkbox
              checked={actRecord.only_oneuser}
              onChange={(event) => {changeField('only_oneuser', event.target.checked)}}
              size="small"
              name="only_oneuser"
              color="primary"
            />
          }
          style={{ display: 'flex', flex: '1 0 45%', margin: 8, marginBottom: 0 }}
          disabled={fixedValues.hasOwnProperty('only_oneuser')}
          label={getString('ACTIVATION_CODE_LABEL_ONLY_ONE_USER')}
        />}

        {fields.includes('registrationTokenLength') && <TextField
          id="registrationTokenLength"
          label={getString('ACTIVATION_CODE_LABEL_REGISTRATION_TOKEN_LENGTH')}
          required={false}
          type="number"
          error={errors.registrationTokenLength !== ''}
          helperText={errors.registrationTokenLength}
          onChange={(event) => {changeField('registrationTokenLength', event.target.value)}}
          value={actRecord.registrationTokenLength}
          className={classes.formControl}
          disabled={fixedValues.hasOwnProperty('registrationTokenLength')}
          style={{ flex: '1 0 45%' }}
        />}

        {fields.includes('allowPasswordReset') && <FormControlLabel
          control={
            <Checkbox
              checked={actRecord.allowPasswordReset}
              onChange={(event) => {changeField('allowPasswordReset', event.target.checked)}}
              size="small"
              name="allowPasswordReset"
              color="primary"
            />
          }
          style={{ display: 'flex', flex: '1 0 45%', margin: 8, marginBottom: 0 }}
          disabled={fixedValues.hasOwnProperty('allowPasswordReset')}
          label={getString('ACTIVATION_CODE_LABEL_ALLOW_PASSWORD_RESET')}
        />}

        {fields.includes('passwordResetTokenLength') && <TextField
          id="passwordResetTokenLength"
          label={getString('ACTIVATION_CODE_LABEL_PASSWORD_RESET_TOKEN_LENGTH')}
          type="number"
          error={errors.passwordResetTokenLength !== ''}
          helperText={errors.passwordResetTokenLength}
          onChange={(event) => {changeField('passwordResetTokenLength', event.target.value)}}
          value={actRecord.passwordResetTokenLength}
          className={classes.formControl}
          disabled={fixedValues.hasOwnProperty('passwordResetTokenLength')}
          style={{ flex: '1 0 45%' }}
        />}
      </AccordionDetails>
    </Accordion>)
}

const RenderMailHandling = ({ classes, actRecord, errors, fixedValues, changeField, fields, setErrors }) => {
  return (<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
      {getString('ACTIVATION_CODE_SUBTITLE_MAIL_HANDLING')}
    </AccordionSummary>
    <AccordionDetails>
      {fields.includes('requiresEmail') && <FormControlLabel
        control={
          <Checkbox
            checked={actRecord.requiresEmail}
            onChange={(event) => {changeField('requiresEmail', event.target.checked)}}
            size="small"
            name="requiresEmail"
            color="primary"
          />
        }
        style={{ display: 'flex', flex: '1 0 45%', margin: 8, marginBottom: 0 }}
        disabled={fixedValues.hasOwnProperty('requiresEmail')}
        label={getString('ACTIVATION_CODE_LABEL_REQUIRES_EMAIL')}
      />}

      {fields.includes('exposeMailDomains') && <FormControlLabel
        control={
          <Checkbox
            checked={actRecord.exposeMailDomains}
            onChange={(event) => {changeField('exposeMailDomains', event.target.checked)}}
            size="small"
            name="exposeMailDomains"
            color="primary"
          />
        }
        style={{ display: 'flex', flex: '1 0 45%', margin: 8, marginBottom: 0 }}
        disabled={fixedValues.hasOwnProperty('exposeMailDomains')}
        label={getString('ACTIVATION_CODE_LABEL_EXPOSE_MAIL_DOMAINS')}
      />}

      {fields.includes('allowedMailDomains') && <FormControl className={classes.formControl}
                                                             error={errors.allowedMailDomains !== ''}
                                                             aria-describedby="publicPathes-helper-text"
                                                             disabled={false}
                                                             style={{ flex: '1 0 98%' }}>
        <InputLabel htmlFor="allowedMailDomains-helper-text" shrink={true}>
          {getString('ACTIVATION_CODE_LABEL_ALLOWED_MAIL_DOMAINS')}
        </InputLabel>
        <JsonEditor
          value={actRecord.allowedMailDomains}
          onChange={(value) => {changeField('allowedMailDomains', value, '')}}
          onEditable={(node) => {return !fixedValues.hasOwnProperty('allowedMailDomains')}}
          onValidationError={(error) =>
            setErrors({ ...errors, allowedMailDomains: error.map(parts => parts.message).join(', ') })
          }
          ajv={ajv}
          schema={listSchema}
          mode={'text'}
          history={false}
          statusBar={false}
          navigationBar={false}
          search={false}
          mainMenuBar={false}
          ace={ace}
          theme={'ace/theme/clouds'}
          htmlElementProps={{ style: { paddingTop: 16, height: '100%', minHeight: 100 } }}
        />
      </FormControl>}

    </AccordionDetails>
  </Accordion>)
}

const RenderAdditionalData = ({ classes, actRecord, errors, fixedValues, changeField, fields, setErrors }) => {
  return (<Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
      {getString('ACTIVATION_CODE_SUBTITLE_ADDITIONAL_DATA')}
    </AccordionSummary>
    <AccordionDetails>
      {fields.includes('publicPathes') && <FormControl className={classes.formControl}
                                                       error={errors.publicPathes !== ''}
                                                       aria-describedby="publicPathes-helper-text"
                                                       disabled={false}
                                                       style={{ flex: '1 0 98%' }}>
        <InputLabel htmlFor="publicPathes-helper-text" shrink={true}>
          {getString('ACTIVATION_CODE_LABEL_PUBLIC_PATHS')}
        </InputLabel>
        <JsonEditor
          value={actRecord.publicPathes}
          onChange={(value) => {changeField('publicPathes', value, '')}}
          onEditable={(node) => {return !fixedValues.hasOwnProperty('publicPathes')}}
          onValidationError={(error) =>
            setErrors({ ...errors, publicPathes: error.map(parts => parts.message).join(', ') })
          }
          ajv={ajv}
          schema={listSchema}
          mode={'text'}
          history={false}
          statusBar={false}
          navigationBar={false}
          search={false}
          mainMenuBar={false}
          ace={ace}
          theme={'ace/theme/clouds'}
          htmlElementProps={{ style: { paddingTop: 16, height: '100%', minHeight: 100 } }}
        />
      </FormControl>}

      {fields.includes('additionalData') && <FormControl className={classes.formControl}
                                                         error={errors.additionalData !== ''}
                                                         aria-describedby="additionalData-helper-text"
                                                         disabled={false}
                                                         style={{ flex: '1 0 98%' }}>
        <InputLabel htmlFor="additionalData-helper-text" shrink={true}>
          {getString('ACTIVATION_CODE_LABEL_ADDITIONAL_DATA')}
        </InputLabel>
        <JsonEditor
          value={actRecord.additionalData}
          onChange={(value) => {changeField('additionalData', value, '')}}
          onEditable={(node) => {return !fixedValues.hasOwnProperty('additionalData')}}
          onValidationError={(error) =>
            setErrors({ ...errors, additionalData: error.map(parts => parts.message).join(', ') })
          }
          ajv={ajv}
          schema={null}
          mode={'text'}
          history={false}
          statusBar={false}
          navigationBar={false}
          search={false}
          mainMenuBar={false}
          ace={ace}
          theme={'ace/theme/clouds'}
          htmlElementProps={{ style: { paddingTop: 16, height: '100%', minHeight: 150 } }}
        />
      </FormControl>}
    </AccordionDetails>
  </Accordion>)
}

const RenderActionParams = ({ classes, actRecord, errors, fixedValues, changeField, fields, setErrors }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        {getString('ACTIVATION_CODE_SUBTITLE_ACTION_PARAMS')}
      </AccordionSummary>
      <AccordionDetails>
        {fields.includes('_actionParams') && <FormControl className={classes.formControl}
                                                           error={errors._actionParams !== ''}
                                                           aria-describedby="_actionParams-helper-text"
                                                           disabled={false}
                                                           style={{ flex: '1 0 98%' }}>
          <InputLabel htmlFor="_actionParams-helper-text" shrink={true}>
            {getString('ACTIVATION_CODE_LABEL_ACTION_PARAMS')}
          </InputLabel>
          <JsonEditor
            value={actRecord._actionParams}
            onChange={(value) => {changeField('_actionParams', value, '')}}
            onEditable={(node) => {return !fixedValues.hasOwnProperty('_actionParams')}}
            onValidationError={(error) =>
              setErrors({ ...errors, _actionParams: error.map(parts => parts.message).join(', ') })
            }
            ajv={ajv}
            schema={configMessageSchema}
            mode={'text'}
            history={false}
            statusBar={false}
            navigationBar={false}
            search={false}
            mainMenuBar={false}
            ace={ace}
            theme={'ace/theme/clouds'}
            htmlElementProps={{ style: { paddingTop: 16, height: '100%', minHeight: 150 } }}
          />
        </FormControl>}
      </AccordionDetails>
    </Accordion>)
}

class ActivationCodeDialog extends PureComponent {

  constructor (props) {
    super(props)

    const record = props.record

    this.state = {
      actRecord: Object.assign({}, record),
      isSubmitting: false,
      errors: {
        code: '',
        feature: '',
        valid_from: '',
        firstActivateUntil: '',
        activateUntil: '',
        maxClients: '',
        must_personalized: '',
        only_oneuser: '',
        only_onedevice: '',
        revoked: '',
        registrationTokenLength: '',
        passwordResetTokenLength: '',
        allowPasswordReset: '',
        requiresEmail: '',
        invitationSent: '',
        validDaysFromActivation: '',
        valid_until: '',
        publicPathes: '',
        allowedMailDomains: '',
        exposeMailDomains: '',
        additionalData: '',
        primaryCode: '',
        _actionParams: ''
      }
    }

    this.state.actRecord.publicPathes = jsonTranslate(record.publicPathes, [])
    this.state.actRecord.allowedMailDomains = jsonTranslate(record.allowedMailDomains, [])
  }

  checkField (fieldName, value, error) {
    const { actRecord } = this.state
    let callback = null
    let otherFields = null

    // TODO registrationTokenLength, passwordResetTokenLength
    switch (fieldName) {
      case 'code':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_CODE_EXPECTED')
        } else if (!(/^[0123456789a-zA-ZäöüßÄÖÜ_][0123456789a-zA-ZäöüßÄÖÜ _-]{1,38}[0123456789a-zA-ZäöüßÄÖÜ_]$/.test(value))) {
          error = getString('ERROR_CODE_INVALID')
        }
        break

      case 'feature':
        if (typeof value === 'undefined' || value === null) value = ''
        value = value.replace(/^\s+/, '')
        if (value !== '') {
          if (!(/^\S+([, ]\S+)*$/.test(value))) {
            error = getString('ERROR_FEATURE_INVALID')
          }
        }
        break

      case 'maxClients':
        if (typeof value === 'undefined' || value === null) value = ''
        if (typeof value === 'number') value = value.toString()
        value = value.replace(/^\s+/, '')
        if (value !== '') {
          if (!value.match(/^-?\d+$/)) {
            error = getString('ERROR_MAX_CLIENTS_INVALID')
          } else {
            let intValue = Number.parseInt(value)
            value = intValue.toString()
            if (intValue !== -1 && intValue <= 0) {
              error = getString('ERROR_MAX_CLIENTS_INVALID')
            }
          }
        }
        break

      case 'valid_from':
        if (typeof value === 'object' && value !== null) {
          if (value._isAMomentObject || value instanceof Date) {
            let date = value instanceof Date ? value : value.toDate()
            if (!isNaN(date.getTime())) {
              value = date
              if (actRecord.valid_until && value > actRecord.valid_until) {
                error = getString('ERROR_ACTIVATION_CODE_FROM_AFTER_UNTIL')
              } else {
                callback = () => {
                  const { actRecord, errors } = this.state
                  let checkResult = this.checkField('valid_until', actRecord.valid_until, '')
                  let newErrors = Object.assign({}, errors)
                  if (newErrors.valid_until !== checkResult.error) {
                    newErrors.valid_until = checkResult.error
                    this.setState({ errors: newErrors })
                  }
                }
              }
            } else {
              error = getString('ERROR_ACTIVATION_CODE_NO_VALID_DATE')
            }
          }
        }
        break

      case 'valid_until':
        if (typeof value === 'object' && value !== null) {
          if (value._isAMomentObject || value instanceof Date) {
            let date = value instanceof Date ? value : value.toDate()
            if (!isNaN(date.getTime())) {
              value = date
              if (actRecord.valid_from && value < actRecord.valid_from) {
                error = getString('ERROR_ACTIVATION_CODE_UNTIL_BEFORE_FROM')
              } else {
                callback = () => {
                  const { actRecord, errors } = this.state
                  let checkResult = this.checkField('valid_from', actRecord.valid_from, '')
                  let newErrors = Object.assign({}, errors)
                  if (newErrors.valid_from !== checkResult.error) {
                    newErrors.valid_from = checkResult.error
                    this.setState({ errors: newErrors })
                  }
                }
              }
            } else {
              error = getString('ERROR_ACTIVATION_CODE_NO_VALID_DATE')
            }
          }
        }
        break

      case 'validDaysFromActivation':
        if (typeof value === 'undefined' || value === null) value = ''
        if (typeof value === 'number') value = value.toString()
        value = value.replace(/^\s+/, '')
        if (value !== '') {
          if (!value.match(/^\d+$/)) {
            error = getString('ERROR_VALID_DAYS_INVALID')
          } else {
            let intValue = Number.parseInt(value)
            value = intValue.toString()
            if (intValue <= 0) {
              error = getString('ERROR_VALID_DAYS_INVALID')
            }
          }
        }
        break

      case 'activateUntil':
      case 'firstActivateUntil':
        if (typeof value === 'object' && value !== null) {
          if (value._isAMomentObject || value instanceof Date) {
            let date = value instanceof Date ? value : value.toDate()
            if (!isNaN(date.getTime())) {
              value = date
              if (actRecord.valid_from && value < actRecord.valid_from) {
                error = getString('ERROR_ACTIVATION_CODE_ACTIVATE_BEFORE_FROM')
              }
            } else {
              error = getString('ERROR_ACTIVATION_CODE_NO_VALID_DATE')
            }
          }
        }
        break

      default:
    }

    return {
      sanitizedValue: value,
      error: error,
      ...(otherFields ? { otherFields: otherFields } : {}),
      ...(callback ? { callback: callback } : {})
    }
  }

  changeField = (fieldName, value, error = '') => {
    const { actRecord, errors } = this.state
    const newRecord = { ...actRecord }
    const newErrors = { ...errors }
    const changeSet = {}

    const checkResult = this.checkField(fieldName, value, error)
    if (newRecord[fieldName] !== checkResult.sanitizedValue) {
      newRecord[fieldName] = checkResult.sanitizedValue
      changeSet.actRecord = newRecord
    }
    if (newErrors[fieldName] !== checkResult.error) {
      newErrors[fieldName] = checkResult.error
      changeSet.errors = newErrors
    }

    if (!checkResult.error && checkResult.otherFields) {
      changeSet.actRecord = Object.assign(newRecord, checkResult.otherFields)
    }

    if (Object.keys(changeSet).length > 0) this.setState(changeSet, checkResult.callback ?? null)
  }

  handleSave = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { isSubmitting } = this.state
    if (!isSubmitting) {
      this.setState({ isSubmitting: true }, () => {this.internalHandleSave()})
    }
  }

  checkAll () {
    const { actRecord, errors } = this.state
    const newErrors = Object.assign({}, errors)
    const newRecord = Object.assign({}, actRecord)
    let hasErrors = false
    const fieldNames = Object.keys(newErrors)
    for (const fieldName of fieldNames) {
      let fieldValue = newRecord[fieldName]
      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.replace(/(^\s+|\s+$)/g, '')
        newRecord[fieldName] = fieldValue
      }
      let checkResult = this.checkField(fieldName, newRecord[fieldName], '')
      newErrors[fieldName] = checkResult.error
      if (checkResult.error !== '') hasErrors = true
    }
    return [hasErrors, newErrors, newRecord]
  }

  async internalHandleSave () {
    await timeout(200)

    // check all fields again
    const [hasErrors, newErrors, newRecord] = this.checkAll()

    // check all checkable fields again
    if (!hasErrors) {
      const { saveFunc, closeFunc } = this.props

      let sendResult = await saveFunc(newRecord)
      if (!sendResult) {
        this.setState({ isSubmitting: false })
      } else {
        closeFunc()
      }
    } else {
      this.setState({ errors: newErrors, handleSave: false })
    }
  }

  render () {
    const { hideDates, showSeconds, showMode } = { hideDates: false, showSeconds: false, showMode: false }
    const { classes, action, title, closeFunc } = this.props
    const { isSubmitting, actRecord, errors } = this.state
    const { user } = this.context

    const fixedValues = user?.additionalData?.activationCodes?.fixedValues ?? {}

    let hiddenDialogFields = (user?.additionalData?.activationCodes?.hiddenDialogFields ?? []).slice()
    const validityFields = ['valid_from', 'valid_until', 'validDaysFromActivation', 'revoked', 'activateUntil', 'firstActivateUntil'].filter(fieldName => !hiddenDialogFields.includes(fieldName))

    hiddenDialogFields = (user?.additionalData?.activationCodes?.hiddenDialogFields ?? []).slice()
    if (!actRecord.must_personalized) {
      for (const fieldName of ['only_onedevice', 'only_oneuser', 'registrationTokenLength', 'allowPasswordReset', 'passwordResetTokenLength']) {
        if (!hiddenDialogFields.includes(fieldName)) hiddenDialogFields.push(fieldName)
      }
    }
    if (!actRecord.allowPasswordReset) {
      for (const fieldName of ['passwordResetTokenLength']) {
        if (!hiddenDialogFields.includes(fieldName)) hiddenDialogFields.push(fieldName)
      }
    }
    const personalizationFields = ['must_personalized', 'only_onedevice', 'only_oneuser', 'registrationTokenLength', 'allowPasswordReset', 'passwordResetTokenLength'].filter(fieldName => !hiddenDialogFields.includes(fieldName))

    hiddenDialogFields = (user?.additionalData?.activationCodes?.hiddenDialogFields ?? []).slice()
    if (!actRecord.must_personalized) {
      for (const fieldName of ['requiresEmail', 'exposeMailDomains', 'allowedMailDomains']) {
        if (!hiddenDialogFields.includes(fieldName)) hiddenDialogFields.push(fieldName)
      }
    }
    const mailFields = ['requiresEmail', 'exposeMailDomains', 'allowedMailDomains'].filter(fieldName => !hiddenDialogFields.includes(fieldName))

    hiddenDialogFields = (user?.additionalData?.activationCodes?.hiddenDialogFields ?? []).slice()
    const additionalFields = ['publicPathes', 'additionalData'].filter(fieldName => !hiddenDialogFields.includes(fieldName))

    hiddenDialogFields = (user?.additionalData?.activationCodes?.hiddenDialogFields ?? []).slice()
    const actionFields = ['_actionParams'].filter(fieldName => !hiddenDialogFields.includes(fieldName))

    console.log('ActivationCodeDialog', JSON.stringify(actRecord))
    const [hasErrors, ,] = this.checkAll()

    const setErrors = (errors) => this.setState({ errors: errors })

    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogBackDrop open={isSubmitting}/>

        <form onSubmit={this.handleSave}>
          <DialogTitle id="form-dialog-title" className={classes.headLine}>{title}</DialogTitle>

          <DialogContent className={classes.content} dividers={true}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <TextField
                id="code"
                label={getString('ACTIVATION_CODE_LABEL_CODE')}
                required={true}
                autoFocus={true}
                type="text"
                error={errors.code !== ''}
                helperText={errors.code}
                onChange={(event) => {this.changeField('code', event.target.value)}}
                value={actRecord.code}
                className={classes.formControl}
                style={{ flex: '1 0 30%' }}
              />

              <TextField
                id="feature"
                label={getString('ACTIVATION_CODE_LABEL_FEATURE')}
                type="text"
                error={errors.feature !== ''}
                helperText={errors.feature}
                onChange={(event) => {this.changeField('feature', event.target.value)}}
                value={actRecord.feature}
                className={classes.formControl}
                style={{ flex: '1 0 30%' }}
              />

              <TextField
                id="maxClients"
                label={getString('ACTIVATION_CODE_LABEL_MAX_CLIENTS')}
                type="number"
                error={errors.maxClients !== ''}
                helperText={errors.maxClients}
                onChange={(event) => {this.changeField('maxClients', event.target.value)}}
                value={actRecord.maxClients}
                className={classes.formControl}
                style={{ flex: '1 0 30%' }}
              />

              {validityFields.length > 0
                ? <RenderValidity classes={classes} actRecord={actRecord} errors={errors} fixedValues={fixedValues}
                                  changeField={this.changeField} fields={validityFields}/>
                : <span/>
              }

              {personalizationFields.length > 0
                ? <RenderPersonalization classes={classes} actRecord={actRecord} errors={errors}
                                         fixedValues={fixedValues}
                                         changeField={this.changeField} fields={personalizationFields}/>
                : <span/>
              }

              {mailFields.length > 0
                ? <RenderMailHandling classes={classes} actRecord={actRecord} errors={errors} fixedValues={fixedValues}
                                      changeField={this.changeField} fields={mailFields} setErrors={setErrors}/>
                : <span/>
              }

              {additionalFields.length > 0
                ? <RenderAdditionalData classes={classes} actRecord={actRecord} errors={errors}
                                        fixedValues={fixedValues}
                                        changeField={this.changeField} fields={additionalFields}
                                        setErrors={setErrors}/>
                : <span/>
              }

              {actionFields.length > 0
                ? <RenderActionParams classes={classes} actRecord={actRecord} errors={errors}
                                        fixedValues={{}}
                                        changeField={this.changeField} fields={actionFields}
                                        setErrors={setErrors}/>
                : <span/>
              }
            </MuiPickersUtilsProvider>
          </DialogContent>

          <DialogActions className={classes.footLine}>
            {!hideDates && <RecordDates dateCreated={actRecord.dateCreated} lastUpdated={actRecord.lastUpdated}
                                        showSeconds={showSeconds}/>}
            {hideDates && <div>&nbsp;</div>}

            <div>
              <CancelButton onClick={closeFunc}/>
              {!showMode && <SubmitButton title={action} disabled={isSubmitting || hasErrors}/>}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

ActivationCodeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  saveFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired
}

ActivationCodeDialog.contextType = AuthContext

export default withStyles(styles)(ActivationCodeDialog)