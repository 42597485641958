import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { showAlert } from 'eqmod-react-alert'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import { AuthContext } from '../library/pageComponents/AuthContext'
import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'
import SingleSuggest from './fields/autosuggest/SingleSuggest'
import { campaignService, notificationTemplateService } from '../../services/dataService'

import getString, { dateLocale } from '../../config/strings'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  statusField: {
    margin: theme.spacing(1),
    flex: '1 0 30%'
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    paddingBottom: 48
  }
})

function timeout (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

class JobDialog extends PureComponent {

  constructor (props) {
    super(props)

    const record = props.record

    this.state = {
      actRecord: Object.assign({}, record),
      isSubmitting: false,
      campaigns: [],
      notifications: [],
      errors: {
        _notification: '',
        _campaign: '',
        actionDate: ''
      }
    }
  }

  componentDidMount () {
    const self = this
    this.getNotifications().then(data => self.setState({ notifications: data }))
    this.getCampaigns().then(data => self.setState({ campaigns: data }))
  }

  async getNotifications () {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        return []
      }

      return await notificationTemplateService.getUnlimited(newAuth)
    } catch (error) {
      console.error('ERROR', 'JobsDialog.getNotifications', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return []
    }
  }

  async getCampaigns () {
    try {
      const { refreshAuth } = this.context
      const newAuth = await refreshAuth()
      if (newAuth === null) {
        return []
      }

      return await campaignService.getUnlimited(newAuth)
    } catch (error) {
      console.error('ERROR', 'JobsDialog.getCampaigns', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return []
    }
  }

  checkField (fieldName, value, error) {
    switch (fieldName) {
      case 'actionDate':
        if ( typeof value === 'undefined' || value === null) {
          error = getString('JOB_ERROR_ACTION_DATE_EXPECTED')
        } else if (typeof value === 'object') {
          if (value._isAMomentObject || value instanceof Date) {
            let date = value instanceof Date ? value : value.toDate()
            if (!isNaN(date.getTime())) {
              value = date
            } else {
              error = getString('JOB_ERROR_INVALID_DATE')
            }
          }
        }
        break

      case '_notification':
        if (typeof value === 'object') {
          if (value === null || !value.id) {
            error = getString('JOB_ERROR_NOTIFICATION_EXPECTED')
          }
        }
        break

      case '_campaign':
        if (typeof value === 'object') {
          if (value === null || !value.id) {
            error = getString('JOB_ERROR_CAMPAIGN_EXPECTED')
          }
        }
        break

      default:
    }

    return { sanitizedValue: value, error: error }
  }

  changeField (fieldName, value) {
    const { actRecord, errors } = this.state
    const newRecord = Object.assign({}, actRecord)
    const newErrors = Object.assign({}, errors)
    const changeSet = {}

    const checkResult = this.checkField(fieldName, value, '')
    if (newRecord[fieldName] !== checkResult.sanitizedValue) {
      newRecord[fieldName] = checkResult.sanitizedValue
      changeSet.actRecord = newRecord
    }
    if (newErrors[fieldName] !== checkResult.error) {
      newErrors[fieldName] = checkResult.error
      changeSet.errors = newErrors
    }

    if (!checkResult.error && checkResult.otherFields) {
      changeSet.actRecord = Object.assign(newRecord, checkResult.otherFields)
    }

    if (Object.keys(changeSet).length > 0) this.setState(changeSet)
  }

  handleSave = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { isSubmitting } = this.state
    if (!isSubmitting) {
      this.setState({ isSubmitting: true }, () => {this.internalHandleSave()})
    }
  }

  checkAll () {
    const { actRecord, errors } = this.state
    const newErrors = Object.assign({}, errors)
    const newRecord = Object.assign({}, actRecord)
    let hasErrors = false
    const fieldNames = Object.keys(newErrors)
    for (const fieldName of fieldNames) {
      let fieldValue = newRecord[fieldName]
      if (typeof fieldValue === 'string') {
        fieldValue = fieldValue.replace(/(^\s+|\s+$)/g, '')
        newRecord[fieldName] = fieldValue
      }
      let checkResult = this.checkField(fieldName, newRecord[fieldName], '')
      newErrors[fieldName] = checkResult.error
      if (checkResult.error !== '') hasErrors = true
    }
    return [hasErrors, newErrors, newRecord]
  }

  async internalHandleSave () {
    await timeout(200)

    // check all fields again
    const [hasErrors, newErrors, newRecord] = this.checkAll()

    if (!hasErrors) {
      const { saveFunc, closeFunc } = this.props
      newRecord.campaignId = newRecord._campaign.id
      newRecord.notificationId = newRecord._notification.id
      let sendResult = await saveFunc(newRecord)
      if (!sendResult) {
        this.setState({ isSubmitting: false })
      } else {
        closeFunc()
      }
    } else {
      console.log('ERROR', 'JobDialog.internalHandleSave', newErrors)
      this.setState({ errors: newErrors, isSubmitting: false })
    }
  }

  render () {
    const { classes, action, title, closeFunc } = this.props
    const { isSubmitting, actRecord, errors, campaigns, notifications } = this.state

    const readOnly = (actRecord.jobStatus === 'COMPLETE')
    const [hasErrors, ,] = this.checkAll()

    let myMoment = moment()
    myMoment.locale(dateLocale())

    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogBackDrop open={isSubmitting}/>

        <form onSubmit={this.handleSave}>
          <DialogTitle id="form-dialog-title" className={classes.headLine}>{title}</DialogTitle>

          <DialogContent className={classes.content} dividers={true}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SingleSuggest
                id="_campaign"
                required={true}
                disabled={readOnly}
                error={errors._campaign !== ''}
                helperText={errors._campaign}
                label={getString('JOB_HEADER_CAMPAIGN')}
                placeholder={getString('JOB_SUGGEST_HELPER')}
                records={campaigns}
                value={actRecord._campaign}
                onChange={(newValue) => {this.changeField('_campaign', newValue)}}
              />

              <SingleSuggest
                id="_notification"
                required={true}
                disabled={readOnly}
                error={errors._notification !== ''}
                helperText={errors._notification}
                label={getString('JOB_HEADER_TEMPLATE')}
                placeholder={getString('JOB_SUGGEST_HELPER')}
                records={notifications}
                value={actRecord._notification}
                onChange={(newValue) => {this.changeField('_notification', newValue)}}
              />

              <div className={classes.formControl} style={{ flex: '1 0 63%' }}>
                <KeyboardDateTimePicker
                  format={myMoment.localeData().longDateFormat('L') + ' ' + myMoment.localeData().longDateFormat('LT')}
                  id="actionDate"
                  label={getString('JOB_HEADER_DATE')}
                  value={actRecord.actionDate}
                  onChange={(date) => {this.changeField('actionDate', date)}}
                  disabled={readOnly}
                  error={errors.actionDate !== ''}
                  helperText={errors.actionDate}
                  ampm={false}
                  disablePast={true}
                  fullWidth
                />
              </div>

              <TextField
                id="jobStatus"
                label={getString('JOB_HEADER_STATUS')}
                type="text"
                disabled={true}
                value={actRecord.jobStatus}
                className={classes.statusField}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>

          <DialogActions className={classes.footLine}>
            <CancelButton onClick={closeFunc}/>
            {!readOnly && <SubmitButton title={action} disabled={hasErrors}/>}
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

JobDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  saveFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired
}

JobDialog.contextType = AuthContext

export default withStyles(styles)(JobDialog)