import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { KeyboardDatePicker } from '@material-ui/pickers'

import DialogBackDrop from '../library/dialogComponents/DialogBackDrop'
import CancelButton from '../library/dialogComponents/CancelButton'
import SubmitButton from '../library/dialogComponents/SubmitButton'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

import getString, { target } from '../../config/strings'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  headLine: {
    backgroundColor: '#f5f5f5',
    padding: '8px 24px'
  },
  footLine: {
    padding: '8px 24px'
  }
})

function timeout (ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

class DnsEntryDialog extends PureComponent {

  constructor (props) {
    super(props)

    let workRecord = Object.assign(this.props.record)
    workRecord.name = workRecord.name ? workRecord.name.split(/\./)[0] : ''

    this.state = {
      actRecord: workRecord,
      handleSave: false,
      errors: {
        name: '',
        start_time: ''
      }
    }
  }

  checkField (fieldName, value) {
    let error = ''

    switch (fieldName) {
      case 'name':
        value = value.replace(/^\s+/, '')
        if (value === '') {
          error = getString('ERROR_SUBDOMAIN_EMPTY')
        } else if (!value.match(/^[A-Za-z][A-Za-z0-9-]+$/)) {
          error = getString('ERROR_INVALID_SUBDOMAIN')
        }
        break

      case 'start_time':
        if (typeof value === 'object' && value !== null) {
          if (value._isAMomentObject || value instanceof Date) {
            let date = value instanceof Date ? value : value.toDate()
            if (!isNaN(date.getTime())) {
              value = date
            } else {
              error = getString('ERROR_ENTRY_NOVALIDDATE')
            }
          }
        }
        break

      default:
    }
    return { sanitizedValue: value, error: error }
  }

  changeField (fieldName, value) {
    let newRecord = Object.assign({}, this.state.actRecord)
    let errors = Object.assign({}, this.state.errors)
    let changeSet = {}

    let checkResult = this.checkField(fieldName, value)
    if (newRecord[fieldName] !== checkResult.sanitizedValue) {
      newRecord[fieldName] = checkResult.sanitizedValue
      changeSet.actRecord = newRecord
    }
    if (errors[fieldName] !== checkResult.error) {
      errors[fieldName] = checkResult.error
      changeSet.errors = errors
    }

    if (Object.keys(changeSet).length > 0) this.setState(changeSet)
  }

  handleSave (event) {
    event.preventDefault()

    if (!this.state.handleSave) {
      this.setState({ handleSave: true }, () => {this.internalHandleSave()})
    }
  }

  async internalHandleSave () {
    await timeout(200)

    // check all checkable fields again
    let errors = Object.assign({}, this.state.errors)
    let hasErrors = false
    let fieldNames = Object.keys(this.state.errors)
    for (let i = 0, l = fieldNames.length; i < l; i++) {
      let fieldName = fieldNames[i]
      let checkResult = this.checkField(fieldName, this.state.actRecord[fieldName])
      errors[fieldName] = checkResult.error
      if (checkResult.error !== '') hasErrors = true
    }

    if (!hasErrors) {
      let saveRecord = Object.assign({}, this.state.actRecord)
      saveRecord.name = saveRecord.name + '.homeofficenavigator.de'
      console.log('internalHandleSave ', JSON.stringify(saveRecord))
      await this.props.saveFunc(saveRecord)
    } else {
      this.setState({ errors: errors, handleSave: false })
    }
  }

  render () {
    const { classes } = this.props

    const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage

    let myMoment = moment()
    myMoment.locale(momentLocaleString)

    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true}>
        <DialogBackDrop open={this.state.handleSave}/>

        <form onSubmit={(event) => {this.handleSave(event)}}>
          <DialogTitle id="form-dialog-title" className={classes.headLine}>{this.props.title}</DialogTitle>

          <DialogContent style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'stretch' }}
                         dividers={true}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <TextField
                id="name"
                label={getString('DNS_LABEL_SUBDOMAIN_NAME')}
                required={true}
                type="text"
                error={this.state.errors.name !== ''}
                helperText={this.state.errors.name}
                onChange={(event) => {this.changeField('name', event.target.value)}}
                value={this.state.actRecord.name}
                style={{ flex: '1 0 98%' }}
                className={classes.formControl}
              />

              <div className={classes.formControl} style={{ flex: '1 0 98%' }}>
                <KeyboardDatePicker
                  format={myMoment.localeData().longDateFormat('L')}
                  id="start_time"
                  label={getString('DNS_LABEL_STARTTIME')}
                  value={this.state.actRecord.start_time || null}
                  onChange={(date) => {this.changeField('start_time', date)}}
                  error={this.state.errors.start_time !== ''}
                  helperText={this.state.errors.start_time}
                  fullWidth
                />
              </div>
            </MuiPickersUtilsProvider>

          </DialogContent>

          <DialogActions className={classes.footLine}>
            <CancelButton onClick={this.props.closeFunc} />
            <SubmitButton title={this.props.action} disabled={false} />
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

DnsEntryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  saveFunc: PropTypes.func.isRequired,
  closeFunc: PropTypes.func.isRequired
}

export default withStyles(styles)(DnsEntryDialog)
