import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import getString, { target } from '../../config/strings'

import { showAlert } from 'eqmod-react-alert'
import WaitForLoad from '../library/pageComponents/WaitForLoad'
import FilterField from '../library/pageComponents/FilterField'
import TableEnhanced from '../library/tableComponents/TableEnhanced'
import PushSendDialog from '../dialogs/PushSendDialog'
import { ViewButton } from '../library/tableComponents/EditButton'
import enhanceSyncButton from '../library/tableComponents/ReloadButton'

import { sendPushService, channelService } from '../../services/dataService'
import ApiService from '../../services/APIService'
import { refreshAuth, deferredReload, addSubscription } from './helpers/pageHelper'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import { dialogConfig, validateContentTagFromProps } from '../utils/helperFunctions'

const formatDate = (string) => {
  const momentLocaleString = target.choosenLanguage === 'en' ? 'en-gb' : target.choosenLanguage
  return string ? moment(new Date(string)).locale(momentLocaleString).format('L LT') : ''
}

const enhanceEditButton = (editFunc) => {
  return React.memo((props) => {
    return (<ViewButton {...Object.assign({}, props, { editFunc: editFunc })} />)
  })
}

const headCells = [
  { id: '0', align: 'center', disablePadding: true, type: 'render' },
  { id: 'title', canBeSorted: true, label: getString('PUSH_HEADLINE_TITLE') },
  { id: 'message', canBeSorted: false, label: getString('PUSH_HEADLINE_MESSAGE') },
  { id: 'sentDate', canBeSorted: true, label: getString('PUSH_HEADLINE_SENT') },
  { id: 'clients', align: 'right', canBeSorted: false, label: getString('PUSH_HEADLINE_RECEIVERS') },
  { id: 'errors', align: 'right', canBeSorted: false, label: getString('PUSH_HEADLINE_ERRORS') },
]

const bodyCells = [
  { id: '0', align: 'center', disablePadding: true, type: 'render' },
  { id: 'title', type: 'data', value: 'title' },
  {
    id: 'subject',
    type: 'message',
    value: (record) => (record['message'] ? (
      record['message'].length > 100 ?
        (record['message'].substring(0, 97) + '...')
        : record['message']

    ) : '')
  },
  { id: 'sentDate', type: 'data', value: (record) => formatDate(record['sentDate']) },
  { id: 'clients', numeric: true, type: 'data', value: 'clients' },
  { id: 'errors', numeric: true, type: 'data', value: 'errors' }
]

const labelSelectors = {
  zeroFiltered: 'PUSH_ZERO_FILTERED',
  zero: 'PUSH_ZERO',
}

function sortRecords (records, orderDescription) {

  const sortfunc = orderDescription.direction === 'asc'
    ? (a, b) => { return a[orderDescription.column] < b[orderDescription.column] ? -1 : (a[orderDescription.column] > b[orderDescription.column] ? 1 : 0)}
    : (b, a) => { return a[orderDescription.column] < b[orderDescription.column] ? -1 : (a[orderDescription.column] > b[orderDescription.column] ? 1 : 0)}

  records.sort(sortfunc)
}

class PushSendPage extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      records: [],
      dialogOpen: false,
      filter: '',
      page: 0,
      rowsPerPage: 10,
      count: 0,
      isLoading: false,
      order: { column: 'sentDate', direction: 'desc' }
    }

    this.head = React.createRef()
    this.deferredTimeout = null
    this.subscription = null
    this.isCancelled = false

    headCells[0].value = enhanceSyncButton(() => {this.reload(this.state.page, this.state.rowsPerPage, this.state.order.column, this.state.order.direction)})
    bodyCells[0].value = enhanceEditButton((index) => {this.openDialog(index)})
  }

  componentDidMount () {
    this.reload(this.state.page, this.state.rowsPerPage, this.state.order.column, this.state.order.direction)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      deferredReload(this)
    }

    const { tabActions } = this.props
    if (tabActions) {
      setTimeout(() => {tabActions.updateIndicator()}, 0)
    }
  }

  getTiles = async () => {
    let data = {}
    try {
      const newAuth = await this.props.refreshAuth()
      if (newAuth === null) return data
      if (this.props.auth && this.props.auth['access_token'] !== newAuth['access_token']) return data

      const contentTag = validateContentTagFromProps(this.props)

      const tiles = await ApiService.getTiles(newAuth, contentTag, true)
      data = (tiles && tiles['mapping']) ? tiles['mapping'] : {}

      return data

    } catch (error) {
      console.error('ERROR', 'PushSendPage.getTiles', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  getChannels = async () => {
    let channels = []
    try {
      const newAuth = await this.props.refreshAuth()
      if (newAuth === null) return channels
      if (this.props.auth && this.props.auth['access_token'] !== newAuth['access_token']) return channels

      channels = await channelService.getAll(newAuth)
      return channels
    } catch (error) {
      console.error('ERROR', 'PushSendPage.getChannels', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return channels
    }
  }

  async reload (page, rowsPerPage, sortColumn, orderDirection) {
    const { filter } = this.state
    this.setState({ isLoading: true })

    addSubscription(this)

    try {
      const newAuth = await refreshAuth(this.props)
      if (newAuth === null) {
        this.setState({ loading: false })
        return
      }

      if (this.isCancelled) return

      let count = await sendPushService.countAll(newAuth, { filter: filter })
      if (this.isCancelled) return

      let start = page * rowsPerPage
      if (start > count) {
        start = 0
        page = 0
      }

      if (this.isCancelled) return

      let data = []
      if (count > 0) {
        data = await sendPushService.getAll(newAuth, {
          offset: start,
          max: rowsPerPage,
          filter: filter,
          sort: sortColumn,
          order: orderDirection
        })
        if (this.isCancelled) return
      }

      this.setState({
        records: data,
        page: page,
        rowsPerPage: rowsPerPage,
        order: { column: sortColumn, direction: orderDirection },
        count: count,
        isLoading: false
      })

    } catch (error) {
      this.subscription = null
      if (this.isCancelled) return

      this.setState({ isLoading: false })
      console.error('ERROR', 'PushSendPage.reload', error)
      if (!error.message.startsWith('isCancelled'))
        showAlert(getString(error.message), getString('ERROR_HEADLINE'))
    }
  }

  async openDialog (index) {
    // TODO initMethod for empty record
    const records = this.state.records

    let emptyRecord = {
      title: '',
      message: '',
      data: null,
      ttl: null,
      appFilter: '',
      requestCodes: '',
      oldApp: false,
      yep2020: false,
      fkt2020: false,
      newApp: false,
      publicUsers: false,
      employees: false,
      clients: 0,
      errors: 0
    }

    if (index !== -1 && records[index]) {
      // copy record
      let record = Object.assign({}, records[index])
      // ensure all fields are initialized
      Object.keys(emptyRecord).forEach(fieldName => {
        if (record[fieldName] === null) record[fieldName] = emptyRecord[fieldName]
      })
      this.setState({ dialogOpen: true, actRecord: record, actIndex: index })
    } else {
      // init with empty Record
      this.setState({ dialogOpen: true, actRecord: emptyRecord, actIndex: index })
    }
  }

  closeDialog = () => {
    this.setState({ dialogOpen: false })
  }

  async saveDialog (record) {
    let self = this

    try {
      const newAuth = await this.props.refreshAuth()
      if (newAuth === null) return false
      if (this.props.auth && this.props.auth['access_token'] !== newAuth['access_token']) return false

      if (this.state.actIndex === -1) {
        let data = await sendPushService.save(newAuth, record)
        let records = self.state.records.slice()
        records.push(data)
        sortRecords(records, self.state.order)
        self.setState({ records: records, dialogOpen: false, count: records.length })
        return true
      }

    } catch (error) {
      console.error('ERROR', 'save push message', error)
      showAlert(getString(error.message), getString('ERROR_HEADLINE'))
      return false
    }
  }

  handleFilterChange = value => {
    this.setState({ filter: value })
  }

  renderHeader () {
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={6} justifyContent='center' alignItems='center'>
          <div>
            <Button variant="contained" color="primary" onClick={() => {this.openDialog(-1)}}>
              <AddIcon/>
              {getString('PUSH_SEND_BUTTON')}
            </Button>
          </div>
        </Grid>

        <Grid item container xs={12} sm={6} justifyContent='center' alignItems='center' ref={this.head}>
          <FilterField value={this.state.filter} handleFilterChange={this.handleFilterChange}/>
        </Grid>
      </React.Fragment>
    )
  }

  renderBody () {
    const { auth } = this.props
    return (
      <Grid item xs={12}>
        <TableEnhanced records={this.state.records}
                       reloadFunc={(page, rowsPerPage, sortColumn, orderDirection) => {this.reload(page, rowsPerPage, sortColumn, orderDirection)}}
                       page={this.state.page}
                       rowsPerPage={this.state.rowsPerPage}
                       sortColumn={this.state.order.column}
                       orderDirection={this.state.order.direction}
                       count={this.state.count}
                       isFiltered={this.state.filter !== ''}
                       headCells={headCells}
                       bodyCells={bodyCells}
                       labelSelectors={labelSelectors}/>
        {this.state.dialogOpen &&
        <PushSendDialog
          title={getString('PUSH_SEND_BUTTON')}
          action={getString('PUSH_SAVE_BUTTON')}
          closeFunc={this.closeDialog}
          sendFunc={(record) => {return this.saveDialog(record)}}
          record={this.state.actRecord}
          getChannels={this.getChannels}
          getTiles={this.getTiles}
          dialogConfig={dialogConfig(auth,'sendPush')}
        />}
      </Grid>
    )
  }

  render () {
    return (
      <Grid container spacing={3} style={{ width: '100%', margin: 0, paddingBottom: 16 }}>
        {this.renderHeader()}

        {this.state.isLoading &&
        <WaitForLoad top={this.head.current ? this.head.current.getBoundingClientRect().bottom : 0}/>}
        {this.renderBody()}
      </Grid>
    )
  }
}

PushSendPage.propTypes = {
  auth: PropTypes.object,
  refreshAuth: PropTypes.func.isRequired,
  tabActions: PropTypes.object
}

export default PushSendPage
